.shot-post-panel {
  display: flex;
  justify-content: flex-start; }
  .shot-post-panel .shot-post {
    background: #3945f9;
    border-radius: 4px;
    color: #efefef;
    padding: 10px 20px;
    margin-bottom: 20px;
    border: none;
    outline: none;
    cursor: pointer; }

.bks-shot {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(79, 125, 183, 0.08); }
  .bks-shot:hover .hover-panel {
    transform: translateY(40px); }
  .bks-shot .hover-panel {
    position: absolute;
    transition: 0.1s all;
    top: -40px;
    width: 100%;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    z-index: 39;
    padding: 0 10px; }
    .bks-shot .hover-panel .icon-box {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px; }
      .bks-shot .hover-panel .icon-box .fixed-icon {
        font-size: 14px;
        margin: 0 5px;
        color: gold; }
      .bks-shot .hover-panel .icon-box .recommend-icon {
        font-size: 14px;
        margin: 0 5px; }
  .bks-shot .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: white;
    cursor: pointer; }
    .bks-shot .card .bks-shot-flex0 {
      position: relative; }
      .bks-shot .card .bks-shot-flex0 .bks-shot-flex1 {
        width: 100%;
        height: 0;
        padding-bottom: 75%; }
        .bks-shot .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 {
          height: 100%;
          width: 100%;
          position: absolute; }
          .bks-shot .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .bks-shot .card .shot-title {
      padding: 10px;
      color: #333333;
      border-bottom: 1px solid #f5f5f5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-size: 3vw; }
      @media (max-width: 400px) {
        .bks-shot .card .shot-title {
          font-size: 12px; } }
      @media (min-width: 466.66667px) {
        .bks-shot .card .shot-title {
          font-size: 14px; } }
    .bks-shot .card .control-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 8px 10px; }
      .bks-shot .card .control-bar .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        cursor: pointer; }
        .bks-shot .card .control-bar .user .avatar {
          width: 20px;
          height: 20px; }
        .bks-shot .card .control-bar .user label {
          flex: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: PingFangSC-Medium, sans-serif;
          font-weight: 500;
          font-size: 11px;
          color: #222b45;
          margin-left: 8px;
          cursor: pointer; }
      .bks-shot .card .control-bar .icon-panel {
        display: flex;
        align-items: center; }
        .bks-shot .card .control-bar .icon-panel .control-item {
          margin-left: 16px;
          color: #c3c3c3;
          font-size: 12px;
          display: flex;
          align-items: center; }
          .bks-shot .card .control-bar .icon-panel .control-item .item-icon {
            margin-right: 3px; }
