.comp-weixin-pay-qrcode-modal .info-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px; }
  .comp-weixin-pay-qrcode-modal .info-body .title {
    font-size: 18px;
    color: #4dc24c;
    font-weight: 500; }
  .comp-weixin-pay-qrcode-modal .info-body .image-box {
    min-width: 200px;
    min-height: 200px;
    max-width: 250px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .comp-weixin-pay-qrcode-modal .info-body .image-box img {
      max-width: 250px; }
  .comp-weixin-pay-qrcode-modal .info-body .tip {
    font-size: 14px; }
