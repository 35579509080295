.bks-skeleton-shot-list-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  overflow: hidden; }
  .bks-skeleton-shot-list-card:hover .hover-panel {
    transform: translateY(40px); }
  .bks-skeleton-shot-list-card .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    cursor: pointer; }
    .bks-skeleton-shot-list-card .card .bks-shot-flex0 {
      position: relative; }
      .bks-skeleton-shot-list-card .card .bks-shot-flex0 .bks-shot-flex1 {
        width: 100%;
        height: 0;
        padding-bottom: 75%; }
        .bks-skeleton-shot-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 {
          height: 100%;
          width: 100%;
          position: absolute;
          background: rgba(195, 195, 195, 0.3);
          animation: skeletonAni 1.4s ease infinite; }
          .bks-skeleton-shot-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .bks-skeleton-shot-list-card .card .shot-title {
      height: 20px;
      width: 80%;
      margin: 10px 0;
      animation: skeletonAni 1.4s ease infinite; }
    .bks-skeleton-shot-list-card .card .control-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer; }
      .bks-skeleton-shot-list-card .card .control-bar .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer; }
        .bks-skeleton-shot-list-card .card .control-bar .user .avatar {
          width: 20px;
          height: 20px;
          background: rgba(195, 195, 195, 0.3);
          animation: skeletonAni 1.4s ease infinite; }
        .bks-skeleton-shot-list-card .card .control-bar .user label {
          flex: 1;
          border-radius: 2px;
          height: 20px; }
      .bks-skeleton-shot-list-card .card .control-bar .icon-panel {
        display: flex;
        align-items: center;
        background: rgba(195, 195, 195, 0.3);
        animation: skeletonAni 1.4s ease infinite;
        border-radius: 2px;
        flex: 0 0 20%;
        height: 20px;
        margin-left: 10px; }
        .bks-skeleton-shot-list-card .card .control-bar .icon-panel .control-item {
          margin-left: 16px;
          color: #c3c3c3;
          font-size: 12px;
          display: flex;
          align-items: center; }
          .bks-skeleton-shot-list-card .card .control-bar .icon-panel .control-item .item-icon {
            margin-right: 3px; }
