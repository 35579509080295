.comp-content-no-found {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px; }
  .comp-content-no-found .icon {
    font-size: 80px; }
  .comp-content-no-found .tip {
    color: #a6aebc;
    font-size: 18px; }
