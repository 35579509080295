.comp-list-footer {
  width: 100%;
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 56px; }
  .comp-list-footer .bottom-loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 15px;
    border-radius: 15px;
    background: #3945F9;
    min-width: 100px;
    color: #ffffff; }
    .comp-list-footer .bottom-loading .title {
      font-size: 12px; }
  .comp-list-footer .loaded-all {
    background: none;
    color: #a6aebc; }
