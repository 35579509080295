.loading {
  height: 200px; }

.index .old-web {
  position: absolute;
  top: 120px;
  left: 0px;
  padding: 10px 20px;
  border-radius: 0 100px 100px 0;
  color: #ffffff;
  background: #3945f9;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-left: none;
  display: flex;
  align-items: center;
  z-index: 100; }
  .index .old-web img {
    width: 30px;
    margin-right: 6px; }

.index .banner-box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08);
  background: #E5E7E9; }
  .index .banner-box .ant-carousel .carousel {
    background: none; }
    .index .banner-box .ant-carousel .carousel a {
      display: block !important; }
      .index .banner-box .ant-carousel .carousel a .background-box {
        position: relative; }
        .index .banner-box .ant-carousel .carousel a .background-box .banner-inner-panel {
          width: 100%;
          height: 0;
          padding-bottom: 31.24%; }
          .index .banner-box .ant-carousel .carousel a .background-box .banner-inner-panel img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .index .banner-box .ant-carousel .carousel .slick-dots button:before {
      display: none; }
  .index .banner-box .banner-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    position: relative;
    padding: 10px 0; }
    .index .banner-box .banner-bottom .carousel-indicator {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      margin-right: 20px; }
      .index .banner-box .banner-bottom .carousel-indicator .dotted {
        width: 6px;
        height: 6px;
        background: #3945F9;
        opacity: 0.2;
        margin: 0 4px;
        cursor: pointer; }
      .index .banner-box .banner-bottom .carousel-indicator .select {
        opacity: 1; }

.index .course-apps {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: white;
  margin-top: 16px;
  box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
  .index .course-apps .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 39px; }
    .index .course-apps .header .dotted-icon {
      margin-left: 12px; }
    .index .course-apps .header .title {
      font-weight: 600;
      font-size: 16px;
      color: #3c3c3c;
      letter-spacing: 0;
      margin-left: 10px; }
  .index .course-apps .list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%; }
    .index .course-apps .list .app-item {
      width: 33.33%;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      transition: 0.2s all; }
      .index .course-apps .list .app-item:nth-child(3) .img-box .img-container {
        border-right: none; }
      .index .course-apps .list .app-item:nth-child(6) .img-box .img-container {
        border-right: none; }
      .index .course-apps .list .app-item:hover {
        background: #ffffff;
        box-shadow: 0 0 10px 0 rgba(156, 163, 170, 0.49);
        border: none; }
        .index .course-apps .list .app-item:hover .name {
          opacity: 1 !important; }
        .index .course-apps .list .app-item:hover img {
          width: 40% !important;
          margin-top: 10px !important; }
      .index .course-apps .list .app-item .img-box {
        height: 0;
        width: 100%;
        padding-bottom: 82.5%; }
        .index .course-apps .list .app-item .img-box .img-container {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-top: 1px solid #ebebeb;
          border-right: 1px solid #ebebeb; }
          .index .course-apps .list .app-item .img-box .img-container img {
            width: 49%;
            transition: all 0.2s;
            margin-top: 30px; }
          @media screen and (max-width: 992px) {
            .index .course-apps .list .app-item .img-box .img-container img {
              margin-top: 0px !important; } }
          .index .course-apps .list .app-item .img-box .img-container .name {
            opacity: 0;
            font-size: 12px;
            color: #999999;
            letter-spacing: 0;
            margin-top: 4px;
            transition: all 0.3s ease; }

.index .list-header {
  margin-top: 30px;
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%; }
  @media (max-width: 400px) {
    .index .list-header {
      margin-top: 20px; } }
  @media (min-width: 1000px) {
    .index .list-header {
      margin-top: 50px; } }
  .index .list-header .category-search {
    width: 100%; }
    .index .list-header .category-search .ant-menu {
      background: none;
      border: none;
      font-size: 14px;
      line-height: 26px; }
      .index .list-header .category-search .ant-menu .ant-menu-item {
        padding: 0;
        margin-right: 20px;
        border-bottom: none; }
      .index .list-header .category-search .ant-menu .ant-menu-submenu {
        margin: 0 20px;
        border-bottom: none; }
        .index .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
          padding: 0; }
          .index .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper {
            display: flex;
            align-items: center; }
            .index .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper .selector-arrow {
              margin-left: 10px;
              margin-right: 0;
              font-size: 8px;
              color: rgba(0, 0, 0, 0.2);
              transition: 0.5s all; }
      .index .list-header .category-search .ant-menu .ant-menu-submenu-active .selector-arrow {
        color: #3945F9 !important; }
    .index .list-header .category-search .search-bar {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      height: 30px;
      height: 8vw;
      border-radius: 20px;
      background: white; }
      @media (max-width: 375px) {
        .index .list-header .category-search .search-bar {
          height: 30px; } }
      @media (min-width: 500px) {
        .index .list-header .category-search .search-bar {
          height: 40px; } }
      .index .list-header .category-search .search-bar input {
        height: 22px;
        font-size: 14px;
        font-size: 3vw;
        flex: 1;
        background: none;
        margin-left: 20px;
        outline: none;
        border: none; }
        @media (max-width: 466.66667px) {
          .index .list-header .category-search .search-bar input {
            font-size: 14px; } }
        @media (min-width: 533.33333px) {
          .index .list-header .category-search .search-bar input {
            font-size: 16px; } }
        .index .list-header .category-search .search-bar input::-webkit-input-placeholder {
          font-size: 14px;
          font-size: 3vw;
          color: #939cae;
          letter-spacing: 0; }
          @media (max-width: 466.66667px) {
            .index .list-header .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 14px; } }
          @media (min-width: 533.33333px) {
            .index .list-header .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 16px; } }
        .index .list-header .category-search .search-bar input:focus {
          outline: none;
          border: none; }
      .index .list-header .category-search .search-bar .search-icon {
        font-size: 20px;
        font-size: 14px;
        font-size: 4vw;
        color: #959eb0;
        margin: 0 20px;
        cursor: pointer; }
        @media (max-width: 400px) {
          .index .list-header .category-search .search-bar .search-icon {
            font-size: 16px; } }
        @media (min-width: 500px) {
          .index .list-header .category-search .search-bar .search-icon {
            font-size: 20px; } }
        .index .list-header .category-search .search-bar .search-icon:hover {
          color: #3945F9; }
      .index .list-header .category-search .search-bar .searching {
        color: #3945F9; }

.index .list-body {
  width: 100%;
  margin-top: 24px; }
  .index .list-body .list-top {
    margin-bottom: 16px;
    margin-top: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
    .index .list-body .list-top .list-title {
      font-size: 16px;
      font-weight: 500;
      color: #424242; }
    .index .list-body .list-top .list-link {
      font-size: 14px;
      cursor: pointer;
      color: #666666; }
      .index .list-body .list-top .list-link:hover {
        color: #3945f9; }
