.comp-shots .list-header {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%; }
  .comp-shots .list-header .category-search {
    width: 100%; }
    .comp-shots .list-header .category-search .ant-select-selection {
      background: #f7f9fc; }
    .comp-shots .list-header .category-search .shots-category {
      display: flex;
      justify-content: center;
      align-items: center; }
      .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
        margin: 0 10px;
        margin-left: 10px;
        margin-left: 1vw;
        margin-right: 10px;
        margin-right: 1vw;
        background: transparent;
        border-radius: 4px;
        border: none;
        outline: none;
        color: #898989; }
        @media (max-width: 400px) {
          .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-left: 4px; } }
        @media (min-width: 1000px) {
          .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-left: 10px; } }
        @media (max-width: 400px) {
          .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-right: 4px; } }
        @media (min-width: 1000px) {
          .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-right: 10px; } }
        .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper:not(:first-child)::before {
          display: none; }
      .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper-checked {
        background: #dcdfff;
        color: #3945f9;
        box-shadow: none; }
    .comp-shots .list-header .category-search .shots-search {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
    .comp-shots .list-header .category-search .ant-menu {
      background: none;
      border: none;
      font-size: 14px;
      line-height: 26px; }
      .comp-shots .list-header .category-search .ant-menu .ant-menu-item {
        padding: 0;
        margin-right: 20px;
        border-bottom: none; }
      .comp-shots .list-header .category-search .ant-menu .ant-menu-submenu {
        margin: 0 20px;
        border-bottom: none; }
        .comp-shots .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
          padding: 0; }
          .comp-shots .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper {
            display: flex;
            align-items: center; }
            .comp-shots .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper .selector-arrow {
              margin-left: 10px;
              margin-right: 0;
              font-size: 8px;
              color: rgba(0, 0, 0, 0.2);
              transition: 0.5s all; }
      .comp-shots .list-header .category-search .ant-menu .ant-menu-submenu-active .selector-arrow {
        color: #3945F9 !important; }
    .comp-shots .list-header .category-search .search-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 30px;
      height: 8vw;
      border-radius: 20px;
      background: white;
      border: 1px solid #efefef;
      position: relative; }
      @media (max-width: 375px) {
        .comp-shots .list-header .category-search .search-bar {
          height: 30px; } }
      @media (min-width: 500px) {
        .comp-shots .list-header .category-search .search-bar {
          height: 40px; } }
      .comp-shots .list-header .category-search .search-bar input {
        padding: 0;
        width: 0px;
        transition: 0.3s all ease;
        font-size: 14px;
        font-size: 3vw;
        flex: 1;
        padding-left: 6px;
        padding-right: 32px;
        padding-right: 6vw;
        margin-left: 0;
        background: none;
        outline: none;
        border: none; }
        @media (max-width: 466.66667px) {
          .comp-shots .list-header .category-search .search-bar input {
            font-size: 14px; } }
        @media (min-width: 533.33333px) {
          .comp-shots .list-header .category-search .search-bar input {
            font-size: 16px; } }
        @media (max-width: 400px) {
          .comp-shots .list-header .category-search .search-bar input {
            padding-right: 24px; } }
        @media (min-width: 533.33333px) {
          .comp-shots .list-header .category-search .search-bar input {
            padding-right: 32px; } }
        .comp-shots .list-header .category-search .search-bar input::-webkit-input-placeholder {
          font-size: 14px;
          font-size: 3vw;
          color: #939cae;
          letter-spacing: 0; }
          @media (max-width: 466.66667px) {
            .comp-shots .list-header .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 14px; } }
          @media (min-width: 533.33333px) {
            .comp-shots .list-header .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 16px; } }
        .comp-shots .list-header .category-search .search-bar input:focus {
          outline: none;
          border: none; }
      .comp-shots .list-header .category-search .search-bar .hover-search {
        width: 200px;
        width: 200px;
        width: 40vw;
        padding-left: 16px; }
        @media (max-width: 400px) {
          .comp-shots .list-header .category-search .search-bar .hover-search {
            width: 160px; } }
        @media (min-width: 575px) {
          .comp-shots .list-header .category-search .search-bar .hover-search {
            width: 230px; } }
      .comp-shots .list-header .category-search .search-bar .search-icon {
        font-size: 14px;
        font-size: 3vw;
        color: #959eb0;
        position: absolute;
        right: 10px;
        right: 1vw;
        cursor: pointer; }
        @media (max-width: 466.66667px) {
          .comp-shots .list-header .category-search .search-bar .search-icon {
            font-size: 14px; } }
        @media (min-width: 533.33333px) {
          .comp-shots .list-header .category-search .search-bar .search-icon {
            font-size: 16px; } }
        @media (max-width: 700px) {
          .comp-shots .list-header .category-search .search-bar .search-icon {
            right: 7px; } }
        @media (min-width: 1000px) {
          .comp-shots .list-header .category-search .search-bar .search-icon {
            right: 10px; } }
        .comp-shots .list-header .category-search .search-bar .search-icon:hover {
          color: #3945F9; }
      .comp-shots .list-header .category-search .search-bar .searching {
        color: #3945F9; }
    .comp-shots .list-header .category-search .post-btn {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      background: #3945f9;
      border-radius: 40px;
      border: none;
      color: #ffffff;
      outline: none;
      cursor: pointer;
      margin-left: 20px;
      height: 30px;
      height: 8vw; }
      @media (max-width: 375px) {
        .comp-shots .list-header .category-search .post-btn {
          height: 30px; } }
      @media (min-width: 500px) {
        .comp-shots .list-header .category-search .post-btn {
          height: 40px; } }

.comp-shots .list-body {
  width: 100%;
  margin-top: 24px; }
