.course-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%;
  position: relative; }
  .course-detail #lesson-edit-player-container video {
    background: black; }
  .course-detail #lesson-edit-player-container .prism-progress-played {
    background: #3945F9; }
  .course-detail .course-nav {
    width: 100%;
    background: #ffffff;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08);
    flex: none; }
    .course-detail .course-nav .course-nav-inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      height: 54px;
      justify-content: space-between; }
      .course-detail .course-nav .course-nav-inner .flex-l {
        display: flex;
        flex-direction: row;
        justify-content: flex-start; }
        .course-detail .course-nav .course-nav-inner .flex-l .collection-icon {
          margin-left: 20px;
          font-size: 22px;
          cursor: pointer; }
      .course-detail .course-nav .course-nav-inner .course-purchase {
        display: flex;
        align-items: center; }
        .course-detail .course-nav .course-nav-inner .course-purchase .course-price {
          color: #fc4d4d;
          margin-right: 20px;
          font-size: 18px; }
        .course-detail .course-nav .course-nav-inner .course-purchase .course-purchase-btn {
          padding: 8px 50px;
          background: #fc4d4d;
          border-radius: 100px;
          color: #ffffff;
          cursor: pointer; }
  .course-detail .index-content {
    max-width: 1360px;
    margin: 0 10px;
    margin-top: 18px;
    margin-top: 2vw;
    width: calc(100% - 20px); }
    @media (max-width: 500px) {
      .course-detail .index-content {
        margin-top: 10px; } }
    @media (min-width: 900px) {
      .course-detail .index-content {
        margin-top: 18px; } }
    .course-detail .index-content .card-marginbottom {
      margin-bottom: 16px;
      margin-bottom: 2vw; }
      @media (max-width: 400px) {
        .course-detail .index-content .card-marginbottom {
          margin-bottom: 8px; } }
      @media (min-width: 800px) {
        .course-detail .index-content .card-marginbottom {
          margin-bottom: 16px; } }
    .course-detail .index-content .course-panel {
      background: #ffffff;
      border: 1px solid #ebebeb;
      box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08);
      margin-bottom: 16px;
      margin-bottom: 2vw; }
      @media (max-width: 400px) {
        .course-detail .index-content .course-panel {
          margin-bottom: 8px; } }
      @media (min-width: 800px) {
        .course-detail .index-content .course-panel {
          margin-bottom: 16px; } }
      .course-detail .index-content .course-panel .course-player-container {
        position: relative; }
        .course-detail .index-content .course-panel .course-player-container .course-video {
          max-width: 100%;
          padding-bottom: 56.24%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: relative; }
          .course-detail .index-content .course-panel .course-player-container .course-video .video-play {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center; }
            .course-detail .index-content .course-panel .course-player-container .course-video .video-play img {
              width: 70px;
              cursor: pointer;
              opacity: 0.5;
              transition: 0.2s all; }
              .course-detail .index-content .course-panel .course-player-container .course-video .video-play img:hover {
                opacity: 0.8; }
        .course-detail .index-content .course-panel .course-player-container .continue-alert {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          z-index: 1001; }
          .course-detail .index-content .course-panel .course-player-container .continue-alert .title {
            font-size: 15px;
            color: white; }
          .course-detail .index-content .course-panel .course-player-container .continue-alert .flex-b {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px; }
            .course-detail .index-content .course-panel .course-player-container .continue-alert .flex-b button {
              margin: 0 10px;
              box-sizing: content-box; }
            .course-detail .index-content .course-panel .course-player-container .continue-alert .flex-b .replay-btn {
              border: 1px solid #ffffff; }
      .course-detail .index-content .course-panel .course-lesson-title {
        background: #ffffff;
        padding: 16px;
        padding: 4vw;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        @media (max-width: 400px) {
          .course-detail .index-content .course-panel .course-lesson-title {
            padding: 16px; } }
        @media (min-width: 500px) {
          .course-detail .index-content .course-panel .course-lesson-title {
            padding: 20px; } }
        .course-detail .index-content .course-panel .course-lesson-title .lesson-title {
          font-size: 16px;
          font-size: 3vw;
          color: #232323;
          text-align: justify; }
          @media (max-width: 466.66667px) {
            .course-detail .index-content .course-panel .course-lesson-title .lesson-title {
              font-size: 14px; } }
          @media (min-width: 533.33333px) {
            .course-detail .index-content .course-panel .course-lesson-title .lesson-title {
              font-size: 16px; } }
        .course-detail .index-content .course-panel .course-lesson-title .course-lesson-file {
          border: #3e4af9 1px solid;
          color: #3e4af9;
          background: #f3f4ff;
          padding: 9px 20px;
          border-radius: 100px;
          cursor: pointer;
          display: flex;
          align-items: center; }
          .course-detail .index-content .course-panel .course-lesson-title .course-lesson-file img {
            margin-right: 6px; }
      .course-detail .index-content .course-panel .ant-tabs-nav {
        padding-left: 40px; }
        .course-detail .index-content .course-panel .ant-tabs-nav .ant-tabs-tab {
          font-size: 16px;
          padding: 16px 0; }
        .course-detail .index-content .course-panel .ant-tabs-nav .ant-tabs-ink-bar {
          height: 3px; }
      .course-detail .index-content .course-panel .ant-tabs-tabpane {
        padding: 30px;
        padding: 3vw; }
        @media (max-width: 333.33333px) {
          .course-detail .index-content .course-panel .ant-tabs-tabpane {
            padding: 10px; } }
        @media (min-width: 1000px) {
          .course-detail .index-content .course-panel .ant-tabs-tabpane {
            padding: 30px; } }
    .course-detail .index-content .course-brief {
      display: flex;
      align-items: center;
      font-size: 12px; }
      .course-detail .index-content .course-brief .course-status {
        color: #519a31;
        margin-right: 8px; }
      .course-detail .index-content .course-brief .course-process {
        color: #232323; }
        .course-detail .index-content .course-brief .course-process span {
          color: #3e4af9;
          font-size: 14px;
          font-weight: bold; }
    .course-detail .index-content .course-tag {
      margin-top: 10px;
      font-size: 12px;
      color: #a6aebc;
      display: flex;
      flex-wrap: wrap; }
      .course-detail .index-content .course-tag span {
        margin-right: 8px; }
    .course-detail .index-content .course-teacher-panel {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .course-detail .index-content .course-teacher-panel .course-teacher {
        display: flex;
        align-items: center; }
        .course-detail .index-content .course-teacher-panel .course-teacher .course-teacher-avatar {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          background: #f3f4ff;
          margin-right: 12px; }
        .course-detail .index-content .course-teacher-panel .course-teacher .course-teacher-name {
          color: #232323;
          font-size: 16px;
          font-weight: bold; }
      .course-detail .index-content .course-teacher-panel .teacher-subscribe {
        padding: 2px 7px;
        display: flex;
        align-items: center;
        background: #404040;
        color: #ffffff;
        border-radius: 4px;
        font-size: 12px;
        cursor: pointer; }
    .course-detail .index-content .lesson-list li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px; }
      .course-detail .index-content .lesson-list li .lesson-title {
        color: #404040;
        font-size: 14px;
        cursor: pointer;
        display: flex; }
      .course-detail .index-content .lesson-list li .lesson-selected {
        color: #3945F9; }
      .course-detail .index-content .lesson-list li .lesson-status {
        flex: none;
        margin-left: 10px; }
        .course-detail .index-content .lesson-list li .lesson-status .lesson-free {
          padding: 0px 4px;
          font-size: 12px;
          color: #4dc24c;
          border: 1px #4dc24c solid;
          background: #e0f6e0;
          border-radius: 4px; }
        .course-detail .index-content .lesson-list li .lesson-status .lesson-charge {
          color: #b6bcc8; }
    .course-detail .index-content .related-list li {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 14px;
      cursor: pointer; }
      .course-detail .index-content .related-list li .related-cover {
        max-width: 120px;
        width: 100%;
        margin-right: 14px; }
        .course-detail .index-content .related-list li .related-cover .related-cover-inner {
          width: 100%;
          padding-bottom: 56.25%;
          background: #aaaaaa; }
      .course-detail .index-content .related-list li .related-title {
        color: #404040; }

@media screen and (max-width: 576px) {
  .course-purchase {
    background: #ffffff;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    padding: 10px;
    justify-content: flex-end;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); } }
