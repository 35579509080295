.focused-banner {
  position: relative; }
  .focused-banner .focused-banner-padding {
    width: 100%;
    height: 0;
    padding-bottom: 37.5%; }
  .focused-banner .focused-banner-container {
    width: 100%;
    height: 100%;
    position: absolute; }
    .focused-banner .focused-banner-container .carousel {
      background: none;
      box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
      .focused-banner .focused-banner-container .carousel img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer; }
