@import url(https://fonts.googleapis.com/css?family=Noto+Serif+SC:200,300,400,500,600,700,900&display=swap&subset=chinese-simplified);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-drawer-content-wrapper .ant-drawer-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0; }

.ant-drawer-content-wrapper .nav-items {
  display: flex;
  flex-direction: column; }
  .ant-drawer-content-wrapper .nav-items a {
    font-size: 14px;
    margin-bottom: 12px;
    color: #ffffff; }
    .ant-drawer-content-wrapper .nav-items a:last-child {
      margin-bottom: 0; }

.bks-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 77px;
  background: #3945F9;
  width: 100%;
  flex: none;
  overflow: hidden;
  position: relative; }
  .bks-nav .index-header {
    background: #3945F9;
    width: 100%;
    height: 312px;
    height: 300px;
    height: 50vw;
    position: absolute;
    top: 0;
    z-index: 0;
    overflow: hidden; }
    @media (max-width: 380px) {
      .bks-nav .index-header {
        height: 190px; } }
    @media (min-width: 624px) {
      .bks-nav .index-header {
        height: 312px; } }
    .bks-nav .index-header img {
      position: absolute; }
      .bks-nav .index-header img:nth-child(1) {
        right: -70px;
        top: -150px;
        opacity: 0.3; }
      .bks-nav .index-header img:nth-child(2) {
        left: 50%;
        bottom: 0px;
        margin-left: -790px; }
      .bks-nav .index-header img:nth-child(3) {
        left: 50%;
        top: -100px;
        margin-left: 300px; }
      .bks-nav .index-header img:nth-child(4) {
        left: 50%;
        top: 0;
        margin-left: -100px;
        -webkit-animation: bgani 15s linear infinite;
                animation: bgani 15s linear infinite;
        opacity: 0.2; }
      .bks-nav .index-header img:nth-child(5) {
        left: -20px;
        top: -80px;
        opacity: 0.3; }

@-webkit-keyframes bgani {
  0% {
    transform: translate(-100px, -200px); }
  100% {
    transform: translate(200px, 200px) rotate(360deg); } }

@keyframes bgani {
  0% {
    transform: translate(-100px, -200px); }
  100% {
    transform: translate(200px, 200px) rotate(360deg); } }
  .bks-nav .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: 1360px;
    margin: 0 20px;
    z-index: 1; }
    .bks-nav .container .logo {
      width: 57px; }
    .bks-nav .container .nav-items a {
      color: rgba(255, 255, 255, 0.7);
      font-size: 30px;
      font-size: 3vw;
      margin-left: 30px;
      margin-left: 5vw; }
      @media (max-width: 466.66667px) {
        .bks-nav .container .nav-items a {
          font-size: 14px; } }
      @media (min-width: 533.33333px) {
        .bks-nav .container .nav-items a {
          font-size: 16px; } }
      @media (max-width: 400px) {
        .bks-nav .container .nav-items a {
          margin-left: 20px; } }
      @media (min-width: 1000px) {
        .bks-nav .container .nav-items a {
          margin-left: 50px; } }
      .bks-nav .container .nav-items a:hover {
        color: white; }
    .bks-nav .container .flex-r {
      display: flex;
      align-items: center; }
      .bks-nav .container .flex-r .notice-box {
        cursor: pointer;
        margin-right: 20px; }
        .bks-nav .container .flex-r .notice-box .notice-icon {
          font-size: 25px;
          color: rgba(255, 255, 255, 0.71); }
      .bks-nav .container .flex-r .action-btn {
        background: rgba(255, 255, 255, 0.1);
        border: none;
        font-size: 12px;
        color: #ffffff;
        letter-spacing: 0;
        margin-left: 20px;
        margin-left: 2vw;
        padding: 4px 8px;
        height: auto;
        border-radius: 0; }
        @media (max-width: 500px) {
          .bks-nav .container .flex-r .action-btn {
            margin-left: 10px; } }
        @media (min-width: 1300px) {
          .bks-nav .container .flex-r .action-btn {
            margin-left: 26px; } }
        .bks-nav .container .flex-r .action-btn:hover {
          background: rgba(255, 255, 255, 0.2); }

.message-overlay-box {
  width: 90%;
  max-width: 280px;
  min-height: 200px; }
  .message-overlay-box .message-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: scroll;
    cursor: pointer;
    max-height: 250px; }
    .message-overlay-box .message-list .list-empty {
      min-height: 200px; }
      .message-overlay-box .message-list .list-empty .tip {
        font-size: 14px; }
    .message-overlay-box .message-list .message-item {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      border-bottom: 1px solid rgba(216, 216, 216, 0.5);
      flex: none; }
      .message-overlay-box .message-list .message-item .message-title {
        flex-wrap: wrap;
        padding: 8px;
        font-size: 12px;
        color: #232323;
        text-align: justify; }
        .message-overlay-box .message-list .message-item .message-title .nickname, .message-overlay-box .message-list .message-item .message-title .title {
          font-weight: bold;
          color: #232323;
          display: inline; }

.bks-login-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 21px 47px;
  width: 100%; }
  .bks-login-register .header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #EDF1F7;
    height: 38px;
    position: relative; }
    .bks-login-register .header label {
      font-family: PingFang-SC-Regular, sans-serif;
      font-size: 14px;
      color: #59B6FF;
      margin-right: 20px; }
    .bks-login-register .header .guide-line {
      background: #59B6FF;
      height: 4px;
      border-radius: 2px;
      width: 44px;
      position: absolute;
      bottom: -2px;
      left: 0;
      transition: all 0.2s ease-out; }
  .bks-login-register .body {
    display: flex;
    flex-direction: column;
    align-items: stretch; }
  .bks-login-register .third-auth {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 15px; }
    .bks-login-register .third-auth .third-link-item {
      margin: 0 18px; }
      .bks-login-register .third-auth .third-link-item img {
        width: 40px;
        height: 40px;
        border-radius: 20px; }
  .bks-login-register .error-tip {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #FF5F4C;
    margin-top: 20px; }
    .bks-login-register .error-tip label {
      transition: all 0.3s ease-out;
      height: 35px; }

.form-body {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .form-body .form-input {
    height: 48px;
    font-family: PingFang-SC-Regular, sans-serif;
    font-size: 14px;
    color: #222B45;
    line-height: 24px; }
  .form-body .input-margin {
    margin-top: 30px; }
  .form-body .form-input::-webkit-input-placeholder {
    font-family: PingFang-SC-Regular, sans-serif;
    font-size: 14px;
    color: #8F9BB3;
    line-height: 20px; }
  .form-body .form-input:-ms-input-placeholder {
    font-family: PingFang-SC-Regular, sans-serif;
    font-size: 14px;
    color: #8F9BB3;
    line-height: 20px; }
  .form-body .form-input::-ms-input-placeholder {
    font-family: PingFang-SC-Regular, sans-serif;
    font-size: 14px;
    color: #8F9BB3;
    line-height: 20px; }
  .form-body .form-input::placeholder {
    font-family: PingFang-SC-Regular, sans-serif;
    font-size: 14px;
    color: #8F9BB3;
    line-height: 20px; }
  .form-body .form-button {
    height: 40px;
    font-family: PingFang-SC-Regular, sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    line-height: 16px;
    width: 100%;
    background: #59B6FF; }
  .form-body .form-item {
    margin-bottom: 0; }
  .form-body .forget-pwd {
    font-family: PingFang-SC-Regular, sans-serif;
    font-size: 12px;
    color: #8F9BB3;
    margin-top: 20px;
    align-self: flex-end; }
  .form-body .send-code-btn {
    background: #EDF1F7;
    border: none;
    font-family: PingFang-SC-Regular;
    font-size: 12px;
    color: #2E3A59;
    text-align: center;
    line-height: 16px; }
  .form-body .agrees-radio {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .form-body .agrees-radio span {
      font-family: PingFang-SC-Regular, sans-serif;
      font-size: 12px;
      color: #8F9BB3; }
      .form-body .agrees-radio span a {
        color: #222B45; }
  .form-body .go-login {
    margin-top: 18px;
    text-align: center;
    font-family: PingFang-SC-Regular, sans-serif;
    font-size: 12px;
    color: #8F9BB3; }


.comp-list-empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px; }
  .comp-list-empty .icon {
    font-size: 80px; }
  .comp-list-empty .tip {
    color: #a6aebc;
    font-size: 16px; }

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%;
  position: relative; }
  .layout .ant-alert {
    width: 100%; }
  .layout .loading {
    height: 200px; }
  .layout .nav {
    overflow: hidden; }
  .layout .high {
    overflow: visible;
    overflow: initial; }
  .layout .layout-content {
    max-width: 1360px;
    margin: 0 10px;
    margin-top: 20px;
    z-index: 2;
    width: calc(100% - 20px); }

.loading {
  height: 200px; }

.index .old-web {
  position: absolute;
  top: 120px;
  left: 0px;
  padding: 10px 20px;
  border-radius: 0 100px 100px 0;
  color: #ffffff;
  background: #3945f9;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-left: none;
  display: flex;
  align-items: center;
  z-index: 100; }
  .index .old-web img {
    width: 30px;
    margin-right: 6px; }

.index .banner-box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08);
  background: #E5E7E9; }
  .index .banner-box .ant-carousel .carousel {
    background: none; }
    .index .banner-box .ant-carousel .carousel a {
      display: block !important; }
      .index .banner-box .ant-carousel .carousel a .background-box {
        position: relative; }
        .index .banner-box .ant-carousel .carousel a .background-box .banner-inner-panel {
          width: 100%;
          height: 0;
          padding-bottom: 31.24%; }
          .index .banner-box .ant-carousel .carousel a .background-box .banner-inner-panel img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .index .banner-box .ant-carousel .carousel .slick-dots button:before {
      display: none; }
  .index .banner-box .banner-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    position: relative;
    padding: 10px 0; }
    .index .banner-box .banner-bottom .carousel-indicator {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      margin-right: 20px; }
      .index .banner-box .banner-bottom .carousel-indicator .dotted {
        width: 6px;
        height: 6px;
        background: #3945F9;
        opacity: 0.2;
        margin: 0 4px;
        cursor: pointer; }
      .index .banner-box .banner-bottom .carousel-indicator .select {
        opacity: 1; }

.index .course-apps {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: white;
  margin-top: 16px;
  box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
  .index .course-apps .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 39px; }
    .index .course-apps .header .dotted-icon {
      margin-left: 12px; }
    .index .course-apps .header .title {
      font-weight: 600;
      font-size: 16px;
      color: #3c3c3c;
      letter-spacing: 0;
      margin-left: 10px; }
  .index .course-apps .list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%; }
    .index .course-apps .list .app-item {
      width: 33.33%;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      transition: 0.2s all; }
      .index .course-apps .list .app-item:nth-child(3) .img-box .img-container {
        border-right: none; }
      .index .course-apps .list .app-item:nth-child(6) .img-box .img-container {
        border-right: none; }
      .index .course-apps .list .app-item:hover {
        background: #ffffff;
        box-shadow: 0 0 10px 0 rgba(156, 163, 170, 0.49);
        border: none; }
        .index .course-apps .list .app-item:hover .name {
          opacity: 1 !important; }
        .index .course-apps .list .app-item:hover img {
          width: 40% !important;
          margin-top: 10px !important; }
      .index .course-apps .list .app-item .img-box {
        height: 0;
        width: 100%;
        padding-bottom: 82.5%; }
        .index .course-apps .list .app-item .img-box .img-container {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-top: 1px solid #ebebeb;
          border-right: 1px solid #ebebeb; }
          .index .course-apps .list .app-item .img-box .img-container img {
            width: 49%;
            transition: all 0.2s;
            margin-top: 30px; }
          @media screen and (max-width: 992px) {
            .index .course-apps .list .app-item .img-box .img-container img {
              margin-top: 0px !important; } }
          .index .course-apps .list .app-item .img-box .img-container .name {
            opacity: 0;
            font-size: 12px;
            color: #999999;
            letter-spacing: 0;
            margin-top: 4px;
            transition: all 0.3s ease; }

.index .list-header {
  margin-top: 30px;
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%; }
  @media (max-width: 400px) {
    .index .list-header {
      margin-top: 20px; } }
  @media (min-width: 1000px) {
    .index .list-header {
      margin-top: 50px; } }
  .index .list-header .category-search {
    width: 100%; }
    .index .list-header .category-search .ant-menu {
      background: none;
      border: none;
      font-size: 14px;
      line-height: 26px; }
      .index .list-header .category-search .ant-menu .ant-menu-item {
        padding: 0;
        margin-right: 20px;
        border-bottom: none; }
      .index .list-header .category-search .ant-menu .ant-menu-submenu {
        margin: 0 20px;
        border-bottom: none; }
        .index .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
          padding: 0; }
          .index .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper {
            display: flex;
            align-items: center; }
            .index .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper .selector-arrow {
              margin-left: 10px;
              margin-right: 0;
              font-size: 8px;
              color: rgba(0, 0, 0, 0.2);
              transition: 0.5s all; }
      .index .list-header .category-search .ant-menu .ant-menu-submenu-active .selector-arrow {
        color: #3945F9 !important; }
    .index .list-header .category-search .search-bar {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      height: 30px;
      height: 8vw;
      border-radius: 20px;
      background: white; }
      @media (max-width: 375px) {
        .index .list-header .category-search .search-bar {
          height: 30px; } }
      @media (min-width: 500px) {
        .index .list-header .category-search .search-bar {
          height: 40px; } }
      .index .list-header .category-search .search-bar input {
        height: 22px;
        font-size: 14px;
        font-size: 3vw;
        flex: 1 1;
        background: none;
        margin-left: 20px;
        outline: none;
        border: none; }
        @media (max-width: 466.66667px) {
          .index .list-header .category-search .search-bar input {
            font-size: 14px; } }
        @media (min-width: 533.33333px) {
          .index .list-header .category-search .search-bar input {
            font-size: 16px; } }
        .index .list-header .category-search .search-bar input::-webkit-input-placeholder {
          font-size: 14px;
          font-size: 3vw;
          color: #939cae;
          letter-spacing: 0; }
          @media (max-width: 466.66667px) {
            .index .list-header .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 14px; } }
          @media (min-width: 533.33333px) {
            .index .list-header .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 16px; } }
        .index .list-header .category-search .search-bar input:focus {
          outline: none;
          border: none; }
      .index .list-header .category-search .search-bar .search-icon {
        font-size: 20px;
        font-size: 14px;
        font-size: 4vw;
        color: #959eb0;
        margin: 0 20px;
        cursor: pointer; }
        @media (max-width: 400px) {
          .index .list-header .category-search .search-bar .search-icon {
            font-size: 16px; } }
        @media (min-width: 500px) {
          .index .list-header .category-search .search-bar .search-icon {
            font-size: 20px; } }
        .index .list-header .category-search .search-bar .search-icon:hover {
          color: #3945F9; }
      .index .list-header .category-search .search-bar .searching {
        color: #3945F9; }

.index .list-body {
  width: 100%;
  margin-top: 24px; }
  .index .list-body .list-top {
    margin-bottom: 16px;
    margin-top: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
    .index .list-body .list-top .list-title {
      font-size: 16px;
      font-weight: 500;
      color: #424242; }
    .index .list-body .list-top .list-link {
      font-size: 14px;
      cursor: pointer;
      color: #666666; }
      .index .list-body .list-top .list-link:hover {
        color: #3945f9; }

.comp-card-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 12px; }
  .comp-card-header .comp-dotted {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 9px;
    margin-top: 9px;
    margin-top: 2vw; }
    @media (max-width: 400px) {
      .comp-card-header .comp-dotted {
        margin-top: 8px; } }
    @media (min-width: 450px) {
      .comp-card-header .comp-dotted {
        margin-top: 9px; } }
    .comp-card-header .comp-dotted .dotted1 {
      width: 10px;
      height: 6px;
      background: #3945F9; }
    .comp-card-header .comp-dotted .dotted2 {
      width: 10px;
      height: 6px;
      background: #01d5f0; }
  .comp-card-header .title {
    font-size: 16px;
    font-size: 16px;
    font-size: 3vw;
    color: #3c3c3c;
    font-weight: bold;
    letter-spacing: 0;
    margin-left: 12px;
    margin-bottom: 0;
    flex: 1 1;
    display: -webkit-box; }
    @media (max-width: 466.66667px) {
      .comp-card-header .title {
        font-size: 14px; } }
    @media (min-width: 533.33333px) {
      .comp-card-header .title {
        font-size: 16px; } }
    .comp-card-header .title h1 {
      margin-left: 0;
      font-size: 16px;
      font-size: 16px;
      font-size: 3vw;
      color: #3c3c3c;
      font-weight: bold;
      letter-spacing: 0;
      margin-bottom: 0; }
      @media (max-width: 466.66667px) {
        .comp-card-header .title h1 {
          font-size: 14px; } }
      @media (min-width: 533.33333px) {
        .comp-card-header .title h1 {
          font-size: 16px; } }

.focused-banner {
  position: relative; }
  .focused-banner .focused-banner-padding {
    width: 100%;
    height: 0;
    padding-bottom: 37.5%; }
  .focused-banner .focused-banner-container {
    width: 100%;
    height: 100%;
    position: absolute; }
    .focused-banner .focused-banner-container .carousel {
      background: none;
      box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
      .focused-banner .focused-banner-container .carousel img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer; }

.comp-all-course-list-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 4px 8px 0 rgba(79, 125, 183, 0.08);
  transition: 0.3s all; }
  .comp-all-course-list-card:hover {
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
    .comp-all-course-list-card:hover .bks-shot-flex2 {
      opacity: 0.9; }
  .comp-all-course-list-card .course-time {
    font-weight: bold;
    color: #ffffff;
    border-radius: 2px;
    position: absolute;
    right: 4px;
    top: 4px;
    background: rgba(0, 4, 59, 0.7);
    padding: 4px 8px;
    z-index: 4;
    font-size: 12px; }
  .comp-all-course-list-card .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: white;
    cursor: pointer; }
    .comp-all-course-list-card .card .bks-shot-flex0 {
      position: relative;
      overflow: hidden;
      margin: 16px 16px 0 16px;
      border-radius: 2px; }
      .comp-all-course-list-card .card .bks-shot-flex0 .course-status {
        position: absolute;
        left: 4px;
        top: 4px;
        background: rgba(255, 147, 0, 0.9);
        color: #ffffff;
        border-radius: 2px;
        padding: 4px 8px;
        z-index: 3;
        font-size: 12px; }
      .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 {
        width: 100%;
        height: 0;
        padding-bottom: 40%; }
        .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 {
          height: 100%;
          width: 100%;
          position: absolute;
          transform: scale(1);
          transition: 0.3s all; }
          .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 .cover-shadow {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(57, 69, 249, 0.1);
            z-index: 1; }
            .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 .cover-shadow .title {
              display: block;
              font-weight: 500;
              font-size: 16px;
              font-size: 3vw;
              color: #ffffff;
              display: flex;
              align-items: center;
              letter-spacing: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              z-index: 2; }
              @media (max-width: 400px) {
                .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 .cover-shadow .title {
                  font-size: 12px; } }
              @media (min-width: 533.33333px) {
                .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 .cover-shadow .title {
                  font-size: 16px; } }
          .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .comp-all-course-list-card .card .control-bar {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      cursor: pointer; }
      .comp-all-course-list-card .card .control-bar .course-card-top {
        padding: 18px;
        padding: 16px;
        padding: 2vw; }
        @media (max-width: 500px) {
          .comp-all-course-list-card .card .control-bar .course-card-top {
            padding: 10px; } }
        @media (min-width: 900px) {
          .comp-all-course-list-card .card .control-bar .course-card-top {
            padding: 18px; } }
        .comp-all-course-list-card .card .control-bar .course-card-top .title {
          display: block;
          font-weight: 500;
          font-size: 16px;
          font-size: 3vw;
          color: #3c3c3c;
          letter-spacing: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
          @media (max-width: 400px) {
            .comp-all-course-list-card .card .control-bar .course-card-top .title {
              font-size: 12px; } }
          @media (min-width: 533.33333px) {
            .comp-all-course-list-card .card .control-bar .course-card-top .title {
              font-size: 16px; } }
        .comp-all-course-list-card .card .control-bar .course-card-top .flex-m {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-top: 12px;
          margin-top: 1vw;
          height: 20px;
          height: 3vw; }
          @media (max-width: 600px) {
            .comp-all-course-list-card .card .control-bar .course-card-top .flex-m {
              margin-top: 6px; } }
          @media (min-width: 1200px) {
            .comp-all-course-list-card .card .control-bar .course-card-top .flex-m {
              margin-top: 12px; } }
          @media (max-width: 533.33333px) {
            .comp-all-course-list-card .card .control-bar .course-card-top .flex-m {
              height: 16px; } }
          @media (min-width: 666.66667px) {
            .comp-all-course-list-card .card .control-bar .course-card-top .flex-m {
              height: 20px; } }
          .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
            font-size: 12px;
            font-size: 2vw; }
            @media (max-width: 500px) {
              .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
                font-size: 10px; } }
            @media (min-width: 600px) {
              .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
                font-size: 12px; } }
          .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .live-status {
            font-size: 12px;
            color: #ff5252; }
          .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end; }
            .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
              width: 20px;
              width: 3vw;
              height: 20px;
              height: 3vw;
              margin-right: 7px;
              overflow: hidden; }
              @media (max-width: 533.33333px) {
                .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  width: 16px; } }
              @media (min-width: 666.66667px) {
                .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  width: 20px; } }
              @media (max-width: 533.33333px) {
                .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  height: 16px; } }
              @media (min-width: 666.66667px) {
                .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  height: 20px; } }
              .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel .app-icon {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block; }
        .comp-all-course-list-card .card .control-bar .course-card-top .flex-m-b {
          margin-top: 32px;
          display: flex;
          align-items: center; }
          .comp-all-course-list-card .card .control-bar .course-card-top .flex-m-b .course-duration {
            font-size: 12px;
            color: #7d7d7d; }
      .comp-all-course-list-card .card .control-bar .flex-b {
        height: 45px;
        height: 8vw;
        padding-left: 16px;
        padding-left: 2vw;
        padding-right: 16px;
        padding-right: 2vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #f4f4f4;
        font-size: 12px; }
        @media (max-width: 375px) {
          .comp-all-course-list-card .card .control-bar .flex-b {
            height: 30px; } }
        @media (min-width: 750px) {
          .comp-all-course-list-card .card .control-bar .flex-b {
            height: 60px; } }
        @media (max-width: 500px) {
          .comp-all-course-list-card .card .control-bar .flex-b {
            padding-left: 10px; } }
        @media (min-width: 900px) {
          .comp-all-course-list-card .card .control-bar .flex-b {
            padding-left: 18px; } }
        @media (max-width: 500px) {
          .comp-all-course-list-card .card .control-bar .flex-b {
            padding-right: 10px; } }
        @media (min-width: 900px) {
          .comp-all-course-list-card .card .control-bar .flex-b {
            padding-right: 18px; } }
        .comp-all-course-list-card .card .control-bar .flex-b .price {
          color: #3c3c3c;
          font-size: 16px;
          font-weight: bold;
          font-size: 16px;
          font-size: 3vw; }
          @media (max-width: 466.66667px) {
            .comp-all-course-list-card .card .control-bar .flex-b .price {
              font-size: 14px; } }
          @media (min-width: 533.33333px) {
            .comp-all-course-list-card .card .control-bar .flex-b .price {
              font-size: 16px; } }
        .comp-all-course-list-card .card .control-bar .flex-b .count {
          color: #7d7d7d;
          font-size: 14px;
          font-size: 3vw; }
          @media (max-width: 400px) {
            .comp-all-course-list-card .card .control-bar .flex-b .count {
              font-size: 12px; } }
          @media (min-width: 466.66667px) {
            .comp-all-course-list-card .card .control-bar .flex-b .count {
              font-size: 14px; } }
        .comp-all-course-list-card .card .control-bar .flex-b .learner-number {
          font-size: 12px;
          color: #939cae;
          letter-spacing: 0;
          flex: none; }
        .comp-all-course-list-card .card .control-bar .flex-b .isfree {
          color: #5ece6d;
          background-color: rgba(94, 206, 109, 0.1);
          font-size: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 2px 4px;
          letter-spacing: 0; }
        .comp-all-course-list-card .card .control-bar .flex-b .course-status-count {
          font-size: 12px; }

.authors {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
  .authors .avatar {
    width: 20px;
    width: 3vw;
    height: 20px;
    height: 3vw;
    border-radius: 11px; }
    @media (max-width: 533.33333px) {
      .authors .avatar {
        width: 16px; } }
    @media (min-width: 733.33333px) {
      .authors .avatar {
        width: 22px; } }
    @media (max-width: 533.33333px) {
      .authors .avatar {
        height: 16px; } }
    @media (min-width: 733.33333px) {
      .authors .avatar {
        height: 22px; } }
  .authors label {
    font-weight: 500;
    font-size: 12px;
    color: #7d7d7d;
    letter-spacing: 0;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80px;
    max-width: 20vw; }
    @media (max-width: 400px) {
      .authors label {
        max-width: 80px; } }
    @media (min-width: 1000px) {
      .authors label {
        max-width: 200px; } }

.dropdown .ant-dropdown-menu-item {
  border-bottom: 1px solid #f5f5f5; }
  .dropdown .ant-dropdown-menu-item:last-child {
    border: none; }

.bks-skeleton-shot-list-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  overflow: hidden; }
  .bks-skeleton-shot-list-card:hover .hover-panel {
    transform: translateY(40px); }
  .bks-skeleton-shot-list-card .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    cursor: pointer; }
    .bks-skeleton-shot-list-card .card .bks-shot-flex0 {
      position: relative; }
      .bks-skeleton-shot-list-card .card .bks-shot-flex0 .bks-shot-flex1 {
        width: 100%;
        height: 0;
        padding-bottom: 75%; }
        .bks-skeleton-shot-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 {
          height: 100%;
          width: 100%;
          position: absolute;
          background: rgba(195, 195, 195, 0.3);
          -webkit-animation: skeletonAni 1.4s ease infinite;
                  animation: skeletonAni 1.4s ease infinite; }
          .bks-skeleton-shot-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .bks-skeleton-shot-list-card .card .shot-title {
      height: 20px;
      width: 80%;
      margin: 10px 0;
      -webkit-animation: skeletonAni 1.4s ease infinite;
              animation: skeletonAni 1.4s ease infinite; }
    .bks-skeleton-shot-list-card .card .control-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer; }
      .bks-skeleton-shot-list-card .card .control-bar .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer; }
        .bks-skeleton-shot-list-card .card .control-bar .user .avatar {
          width: 20px;
          height: 20px;
          background: rgba(195, 195, 195, 0.3);
          -webkit-animation: skeletonAni 1.4s ease infinite;
                  animation: skeletonAni 1.4s ease infinite; }
        .bks-skeleton-shot-list-card .card .control-bar .user label {
          flex: 1 1;
          border-radius: 2px;
          height: 20px; }
      .bks-skeleton-shot-list-card .card .control-bar .icon-panel {
        display: flex;
        align-items: center;
        background: rgba(195, 195, 195, 0.3);
        -webkit-animation: skeletonAni 1.4s ease infinite;
                animation: skeletonAni 1.4s ease infinite;
        border-radius: 2px;
        flex: 0 0 20%;
        height: 20px;
        margin-left: 10px; }
        .bks-skeleton-shot-list-card .card .control-bar .icon-panel .control-item {
          margin-left: 16px;
          color: #c3c3c3;
          font-size: 12px;
          display: flex;
          align-items: center; }
          .bks-skeleton-shot-list-card .card .control-bar .icon-panel .control-item .item-icon {
            margin-right: 3px; }

.skeleton-course-list-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: 0.3s all; }
  .skeleton-course-list-card .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    cursor: pointer; }
    .skeleton-course-list-card .card .bks-shot-flex0 {
      position: relative;
      overflow: hidden;
      margin: 16px 16px 0 16px;
      border-radius: 2px; }
      .skeleton-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 {
        width: 100%;
        height: 0;
        padding-bottom: 40%; }
        .skeleton-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 {
          height: 100%;
          width: 100%;
          position: absolute;
          transform: scale(1);
          transition: 0.3s all;
          -webkit-animation: skeletonAni 1.4s ease infinite;
                  animation: skeletonAni 1.4s ease infinite; }
          .skeleton-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .skeleton-course-list-card .card .control-bar {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      cursor: pointer; }
      .skeleton-course-list-card .card .control-bar .course-card-top {
        padding: 18px;
        padding: 16px;
        padding: 2vw; }
        @media (max-width: 500px) {
          .skeleton-course-list-card .card .control-bar .course-card-top {
            padding: 10px; } }
        @media (min-width: 900px) {
          .skeleton-course-list-card .card .control-bar .course-card-top {
            padding: 18px; } }
        .skeleton-course-list-card .card .control-bar .course-card-top .title {
          display: block;
          font-weight: 500;
          font-size: 16px;
          font-size: 3vw;
          color: #3c3c3c;
          letter-spacing: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 20px;
          width: 100%;
          -webkit-animation: skeletonAni 1.4s ease infinite;
                  animation: skeletonAni 1.4s ease infinite; }
          @media (max-width: 400px) {
            .skeleton-course-list-card .card .control-bar .course-card-top .title {
              font-size: 12px; } }
          @media (min-width: 533.33333px) {
            .skeleton-course-list-card .card .control-bar .course-card-top .title {
              font-size: 16px; } }
        .skeleton-course-list-card .card .control-bar .course-card-top .flex-m {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-top: 12px;
          margin-top: 1vw;
          height: 20px;
          height: 3vw; }
          @media (max-width: 600px) {
            .skeleton-course-list-card .card .control-bar .course-card-top .flex-m {
              margin-top: 6px; } }
          @media (min-width: 1200px) {
            .skeleton-course-list-card .card .control-bar .course-card-top .flex-m {
              margin-top: 12px; } }
          @media (max-width: 533.33333px) {
            .skeleton-course-list-card .card .control-bar .course-card-top .flex-m {
              height: 16px; } }
          @media (min-width: 666.66667px) {
            .skeleton-course-list-card .card .control-bar .course-card-top .flex-m {
              height: 20px; } }
          .skeleton-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
            font-size: 12px;
            font-size: 2vw; }
            @media (max-width: 500px) {
              .skeleton-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
                font-size: 10px; } }
            @media (min-width: 600px) {
              .skeleton-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
                font-size: 12px; } }
          .skeleton-course-list-card .card .control-bar .course-card-top .flex-m .live-status {
            font-size: 12px;
            color: #ff5252; }
          .skeleton-course-list-card .card .control-bar .course-card-top .flex-m .apps {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            height: 20px;
            background: rgba(195, 195, 195, 0.3);
            -webkit-animation: skeletonAni 1.4s ease infinite;
                    animation: skeletonAni 1.4s ease infinite;
            width: 35%; }
        .skeleton-course-list-card .card .control-bar .course-card-top .flex-m-b {
          margin-top: 32px;
          display: flex;
          align-items: center; }
          .skeleton-course-list-card .card .control-bar .course-card-top .flex-m-b .course-duration {
            font-size: 12px;
            color: #7d7d7d;
            flex: 1 1;
            height: 20px;
            background: rgba(195, 195, 195, 0.3);
            -webkit-animation: skeletonAni 1.4s ease infinite;
                    animation: skeletonAni 1.4s ease infinite; }
      .skeleton-course-list-card .card .control-bar .flex-b {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #f4f4f4;
        font-size: 12px;
        flex: 1 1;
        height: 20px;
        background: rgba(195, 195, 195, 0.3); }

.authors {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
  .authors .avatar {
    width: 20px;
    width: 3vw;
    height: 20px;
    height: 3vw;
    border-radius: 11px;
    background: rgba(195, 195, 195, 0.3);
    -webkit-animation: skeletonAni 1.4s ease infinite;
            animation: skeletonAni 1.4s ease infinite; }
    @media (max-width: 533.33333px) {
      .authors .avatar {
        width: 16px; } }
    @media (min-width: 733.33333px) {
      .authors .avatar {
        width: 22px; } }
    @media (max-width: 533.33333px) {
      .authors .avatar {
        height: 16px; } }
    @media (min-width: 733.33333px) {
      .authors .avatar {
        height: 22px; } }
  .authors label {
    font-weight: 500;
    font-size: 12px;
    color: #7d7d7d;
    letter-spacing: 0;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.dropdown .ant-dropdown-menu-item {
  border-bottom: 1px solid #f5f5f5; }
  .dropdown .ant-dropdown-menu-item:last-child {
    border: none; }

.shot-post-panel {
  display: flex;
  justify-content: flex-start; }
  .shot-post-panel .shot-post {
    background: #3945f9;
    border-radius: 4px;
    color: #efefef;
    padding: 10px 20px;
    margin-bottom: 20px;
    border: none;
    outline: none;
    cursor: pointer; }

.bks-shot {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(79, 125, 183, 0.08); }
  .bks-shot:hover .hover-panel {
    transform: translateY(40px); }
  .bks-shot .hover-panel {
    position: absolute;
    transition: 0.1s all;
    top: -40px;
    width: 100%;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    z-index: 39;
    padding: 0 10px; }
    .bks-shot .hover-panel .icon-box {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px; }
      .bks-shot .hover-panel .icon-box .fixed-icon {
        font-size: 14px;
        margin: 0 5px;
        color: gold; }
      .bks-shot .hover-panel .icon-box .recommend-icon {
        font-size: 14px;
        margin: 0 5px; }
  .bks-shot .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: white;
    cursor: pointer; }
    .bks-shot .card .bks-shot-flex0 {
      position: relative; }
      .bks-shot .card .bks-shot-flex0 .bks-shot-flex1 {
        width: 100%;
        height: 0;
        padding-bottom: 75%; }
        .bks-shot .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 {
          height: 100%;
          width: 100%;
          position: absolute; }
          .bks-shot .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .bks-shot .card .shot-title {
      padding: 10px;
      color: #333333;
      border-bottom: 1px solid #f5f5f5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-size: 3vw; }
      @media (max-width: 400px) {
        .bks-shot .card .shot-title {
          font-size: 12px; } }
      @media (min-width: 466.66667px) {
        .bks-shot .card .shot-title {
          font-size: 14px; } }
    .bks-shot .card .control-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 8px 10px; }
      .bks-shot .card .control-bar .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        cursor: pointer; }
        .bks-shot .card .control-bar .user .avatar {
          width: 20px;
          height: 20px; }
        .bks-shot .card .control-bar .user label {
          flex: 1 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: PingFangSC-Medium, sans-serif;
          font-weight: 500;
          font-size: 11px;
          color: #222b45;
          margin-left: 8px;
          cursor: pointer; }
      .bks-shot .card .control-bar .icon-panel {
        display: flex;
        align-items: center; }
        .bks-shot .card .control-bar .icon-panel .control-item {
          margin-left: 16px;
          color: #c3c3c3;
          font-size: 12px;
          display: flex;
          align-items: center; }
          .bks-shot .card .control-bar .icon-panel .control-item .item-icon {
            margin-right: 3px; }

.shots .list-header {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%; }
  .shots .list-header .category-search {
    width: 100%; }
    .shots .list-header .category-search .ant-select-selection {
      background: #f7f9fc; }
    .shots .list-header .category-search .shots-category {
      display: flex;
      justify-content: center;
      align-items: center; }
      .shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
        margin: 0 10px;
        margin-left: 10px;
        margin-left: 1vw;
        margin-right: 10px;
        margin-right: 1vw;
        background: transparent;
        border-radius: 4px;
        border: none;
        outline: none;
        color: #898989; }
        @media (max-width: 400px) {
          .shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-left: 4px; } }
        @media (min-width: 1000px) {
          .shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-left: 10px; } }
        @media (max-width: 400px) {
          .shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-right: 4px; } }
        @media (min-width: 1000px) {
          .shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-right: 10px; } }
        .shots .list-header .category-search .shots-category .ant-radio-button-wrapper:not(:first-child)::before {
          display: none; }
      .shots .list-header .category-search .shots-category .ant-radio-button-wrapper-checked {
        background: #dcdfff;
        color: #3945f9;
        box-shadow: none; }
    .shots .list-header .category-search .ant-menu {
      background: none;
      border: none;
      font-size: 14px;
      line-height: 26px; }
      .shots .list-header .category-search .ant-menu .ant-menu-item {
        padding: 0;
        margin-right: 20px;
        border-bottom: none; }
      .shots .list-header .category-search .ant-menu .ant-menu-submenu {
        margin: 0 20px;
        border-bottom: none; }
        .shots .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
          padding: 0; }
          .shots .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper {
            display: flex;
            align-items: center; }
            .shots .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper .selector-arrow {
              margin-left: 10px;
              margin-right: 0;
              font-size: 8px;
              color: rgba(0, 0, 0, 0.2);
              transition: 0.5s all; }
      .shots .list-header .category-search .ant-menu .ant-menu-submenu-active .selector-arrow {
        color: #3945F9 !important; }

.shots .list-body {
  width: 100%;
  margin-top: 24px; }

.comp-oval-loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 38px;
  min-width: 38px;
  width: 100%; }

.comp-shots .list-header {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%; }
  .comp-shots .list-header .category-search {
    width: 100%; }
    .comp-shots .list-header .category-search .ant-select-selection {
      background: #f7f9fc; }
    .comp-shots .list-header .category-search .shots-category {
      display: flex;
      justify-content: center;
      align-items: center; }
      .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
        margin: 0 10px;
        margin-left: 10px;
        margin-left: 1vw;
        margin-right: 10px;
        margin-right: 1vw;
        background: transparent;
        border-radius: 4px;
        border: none;
        outline: none;
        color: #898989; }
        @media (max-width: 400px) {
          .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-left: 4px; } }
        @media (min-width: 1000px) {
          .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-left: 10px; } }
        @media (max-width: 400px) {
          .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-right: 4px; } }
        @media (min-width: 1000px) {
          .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-right: 10px; } }
        .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper:not(:first-child)::before {
          display: none; }
      .comp-shots .list-header .category-search .shots-category .ant-radio-button-wrapper-checked {
        background: #dcdfff;
        color: #3945f9;
        box-shadow: none; }
    .comp-shots .list-header .category-search .shots-search {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
    .comp-shots .list-header .category-search .ant-menu {
      background: none;
      border: none;
      font-size: 14px;
      line-height: 26px; }
      .comp-shots .list-header .category-search .ant-menu .ant-menu-item {
        padding: 0;
        margin-right: 20px;
        border-bottom: none; }
      .comp-shots .list-header .category-search .ant-menu .ant-menu-submenu {
        margin: 0 20px;
        border-bottom: none; }
        .comp-shots .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
          padding: 0; }
          .comp-shots .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper {
            display: flex;
            align-items: center; }
            .comp-shots .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper .selector-arrow {
              margin-left: 10px;
              margin-right: 0;
              font-size: 8px;
              color: rgba(0, 0, 0, 0.2);
              transition: 0.5s all; }
      .comp-shots .list-header .category-search .ant-menu .ant-menu-submenu-active .selector-arrow {
        color: #3945F9 !important; }
    .comp-shots .list-header .category-search .search-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 30px;
      height: 8vw;
      border-radius: 20px;
      background: white;
      border: 1px solid #efefef;
      position: relative; }
      @media (max-width: 375px) {
        .comp-shots .list-header .category-search .search-bar {
          height: 30px; } }
      @media (min-width: 500px) {
        .comp-shots .list-header .category-search .search-bar {
          height: 40px; } }
      .comp-shots .list-header .category-search .search-bar input {
        padding: 0;
        width: 0px;
        transition: 0.3s all ease;
        font-size: 14px;
        font-size: 3vw;
        flex: 1 1;
        padding-left: 6px;
        padding-right: 32px;
        padding-right: 6vw;
        margin-left: 0;
        background: none;
        outline: none;
        border: none; }
        @media (max-width: 466.66667px) {
          .comp-shots .list-header .category-search .search-bar input {
            font-size: 14px; } }
        @media (min-width: 533.33333px) {
          .comp-shots .list-header .category-search .search-bar input {
            font-size: 16px; } }
        @media (max-width: 400px) {
          .comp-shots .list-header .category-search .search-bar input {
            padding-right: 24px; } }
        @media (min-width: 533.33333px) {
          .comp-shots .list-header .category-search .search-bar input {
            padding-right: 32px; } }
        .comp-shots .list-header .category-search .search-bar input::-webkit-input-placeholder {
          font-size: 14px;
          font-size: 3vw;
          color: #939cae;
          letter-spacing: 0; }
          @media (max-width: 466.66667px) {
            .comp-shots .list-header .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 14px; } }
          @media (min-width: 533.33333px) {
            .comp-shots .list-header .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 16px; } }
        .comp-shots .list-header .category-search .search-bar input:focus {
          outline: none;
          border: none; }
      .comp-shots .list-header .category-search .search-bar .hover-search {
        width: 200px;
        width: 200px;
        width: 40vw;
        padding-left: 16px; }
        @media (max-width: 400px) {
          .comp-shots .list-header .category-search .search-bar .hover-search {
            width: 160px; } }
        @media (min-width: 575px) {
          .comp-shots .list-header .category-search .search-bar .hover-search {
            width: 230px; } }
      .comp-shots .list-header .category-search .search-bar .search-icon {
        font-size: 14px;
        font-size: 3vw;
        color: #959eb0;
        position: absolute;
        right: 10px;
        right: 1vw;
        cursor: pointer; }
        @media (max-width: 466.66667px) {
          .comp-shots .list-header .category-search .search-bar .search-icon {
            font-size: 14px; } }
        @media (min-width: 533.33333px) {
          .comp-shots .list-header .category-search .search-bar .search-icon {
            font-size: 16px; } }
        @media (max-width: 700px) {
          .comp-shots .list-header .category-search .search-bar .search-icon {
            right: 7px; } }
        @media (min-width: 1000px) {
          .comp-shots .list-header .category-search .search-bar .search-icon {
            right: 10px; } }
        .comp-shots .list-header .category-search .search-bar .search-icon:hover {
          color: #3945F9; }
      .comp-shots .list-header .category-search .search-bar .searching {
        color: #3945F9; }
    .comp-shots .list-header .category-search .post-btn {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      background: #3945f9;
      border-radius: 40px;
      border: none;
      color: #ffffff;
      outline: none;
      cursor: pointer;
      margin-left: 20px;
      height: 30px;
      height: 8vw; }
      @media (max-width: 375px) {
        .comp-shots .list-header .category-search .post-btn {
          height: 30px; } }
      @media (min-width: 500px) {
        .comp-shots .list-header .category-search .post-btn {
          height: 40px; } }

.comp-shots .list-body {
  width: 100%;
  margin-top: 24px; }

.comp-dot-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  .comp-dot-loading .box {
    display: inline-block;
    float: left;
    position: relative;
    transition: all .2s ease; }
  .comp-dot-loading .loader10 {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    -webkit-animation: loader10m 2s ease-in-out infinite;
    animation: loader10m 2s ease-in-out infinite; }
    .comp-dot-loading .loader10:before {
      content: "";
      position: absolute;
      top: 0px;
      left: -15px;
      height: 8px;
      width: 8px;
      border-radius: 8px;
      -webkit-animation: loader10g 2s ease-in-out infinite;
      animation: loader10g 2s ease-in-out infinite; }
    .comp-dot-loading .loader10:after {
      content: "";
      position: absolute;
      top: 0px;
      left: 15px;
      height: 8px;
      width: 8px;
      border-radius: 10px;
      -webkit-animation: loader10d 2s ease-in-out infinite;
      animation: loader10d 2s ease-in-out infinite; }

@-webkit-keyframes loader10g {
  0% {
    background-color: rgba(47, 84, 235, 0.2); }
  25% {
    background-color: #2f54eb; }
  50% {
    background-color: rgba(47, 84, 235, 0.2); }
  75% {
    background-color: rgba(47, 84, 235, 0.2); }
  100% {
    background-color: rgba(47, 84, 235, 0.2); } }

@keyframes loader10g {
  0% {
    background-color: rgba(47, 84, 235, 0.2); }
  25% {
    background-color: #2f54eb; }
  50% {
    background-color: rgba(47, 84, 235, 0.2); }
  75% {
    background-color: rgba(47, 84, 235, 0.2); }
  100% {
    background-color: rgba(47, 84, 235, 0.2); } }

@-webkit-keyframes loader10m {
  0% {
    background-color: rgba(47, 84, 235, 0.2); }
  25% {
    background-color: rgba(47, 84, 235, 0.2); }
  50% {
    background-color: #2f54eb; }
  75% {
    background-color: rgba(47, 84, 235, 0.2); }
  100% {
    background-color: rgba(47, 84, 235, 0.2); } }

@keyframes loader10m {
  0% {
    background-color: rgba(47, 84, 235, 0.2); }
  25% {
    background-color: rgba(47, 84, 235, 0.2); }
  50% {
    background-color: #2f54eb; }
  75% {
    background-color: rgba(47, 84, 235, 0.2); }
  100% {
    background-color: rgba(47, 84, 235, 0.2); } }

@-webkit-keyframes loader10d {
  0% {
    background-color: rgba(47, 84, 235, 0.2); }
  25% {
    background-color: rgba(47, 84, 235, 0.2); }
  50% {
    background-color: rgba(47, 84, 235, 0.2); }
  75% {
    background-color: #2f54eb; }
  100% {
    background-color: rgba(47, 84, 235, 0.2); } }

@keyframes loader10d {
  0% {
    background-color: rgba(47, 84, 235, 0.2); }
  25% {
    background-color: rgba(47, 84, 235, 0.2); }
  50% {
    background-color: rgba(47, 84, 235, 0.2); }
  75% {
    background-color: #2f54eb; }
  100% {
    background-color: rgba(47, 84, 235, 0.2); } }

.comp-list-footer {
  width: 100%;
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 56px; }
  .comp-list-footer .bottom-loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 15px;
    border-radius: 15px;
    background: #3945F9;
    min-width: 100px;
    color: #ffffff; }
    .comp-list-footer .bottom-loading .title {
      font-size: 12px; }
  .comp-list-footer .loaded-all {
    background: none;
    color: #a6aebc; }

.shot-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f9fc;
  width: 100%;
  position: relative; }
  .shot-page .content {
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    padding: 3vw;
    position: relative; }
    @media (max-width: 0px) {
      .shot-page .content {
        padding: 0px; } }
    @media (min-width: 666.66667px) {
      .shot-page .content {
        padding: 20px; } }
    .shot-page .content .side-affix {
      position: absolute;
      right: -50px;
      top: 100px; }
      .shot-page .content .side-affix .side-control-panel .side-control-btn-outter {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-bottom: 25px; }
        .shot-page .content .side-affix .side-control-panel .side-control-btn-outter .side-control-btn {
          width: 50px;
          height: 50px;
          margin-bottom: 8px;
          border-radius: 50%;
          border: 1px solid #e8e8e8;
          background-color: #ffffff;
          color: #666666;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer; }
          .shot-page .content .side-affix .side-control-panel .side-control-btn-outter .side-control-btn .icon {
            font-size: 18px; }
        .shot-page .content .side-affix .side-control-panel .side-control-btn-outter .side-control-text {
          font-size: 12px;
          color: #a4a4a4; }
    .shot-page .content .shot-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border-radius: 4px;
      overflow: hidden; }
      .shot-page .content .shot-container .shot {
        width: 100%; }
      .shot-page .content .shot-container .control-bar {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 17px;
        padding: 3vw;
        background: white; }
        @media (max-width: 400px) {
          .shot-page .content .shot-container .control-bar {
            padding: 12px; } }
        @media (min-width: 566.66667px) {
          .shot-page .content .shot-container .control-bar {
            padding: 17px; } }
        .shot-page .content .shot-container .control-bar .avatar {
          width: 50px;
          height: 50px;
          flex: none; }
        .shot-page .content .shot-container .control-bar .flex {
          display: flex;
          flex-flow: wrap;
          align-items: center;
          justify-content: space-between;
          flex: auto; }
          .shot-page .content .shot-container .control-bar .flex .flex-m {
            margin: 0 15px; }
            .shot-page .content .shot-container .control-bar .flex .flex-m .title {
              font-size: 24px;
              font-size: 24px;
              font-size: 3vw;
              font-weight: 500;
              color: #222b45; }
              @media (max-width: 466.66667px) {
                .shot-page .content .shot-container .control-bar .flex .flex-m .title {
                  font-size: 14px; } }
              @media (min-width: 800px) {
                .shot-page .content .shot-container .control-bar .flex .flex-m .title {
                  font-size: 24px; } }
            .shot-page .content .shot-container .control-bar .flex .flex-m .flex-m-b {
              font-size: 14px;
              font-size: 3vw;
              color: #8f9bb3; }
              @media (max-width: 400px) {
                .shot-page .content .shot-container .control-bar .flex .flex-m .flex-m-b {
                  font-size: 12px; } }
              @media (min-width: 466.66667px) {
                .shot-page .content .shot-container .control-bar .flex .flex-m .flex-m-b {
                  font-size: 14px; } }
              .shot-page .content .shot-container .control-bar .flex .flex-m .flex-m-b span {
                color: #222b45; }
          .shot-page .content .shot-container .control-bar .flex .flex-r {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end; }
            .shot-page .content .shot-container .control-bar .flex .flex-r .ant-anchor-ink {
              display: none; }
            .shot-page .content .shot-container .control-bar .flex .flex-r .ant-anchor-link {
              padding-left: 12px; }
              .shot-page .content .shot-container .control-bar .flex .flex-r .ant-anchor-link .control-btn {
                margin-left: 0; }
            .shot-page .content .shot-container .control-bar .flex .flex-r .control-btn {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              border: 1px solid #e4e9f2;
              font-size: 14px;
              color: #9395b0;
              text-align: center;
              line-height: 14px;
              height: 40px;
              border-radius: 4px;
              padding: 14px 14px;
              cursor: pointer;
              margin-left: 10px; }
              .shot-page .content .shot-container .control-bar .flex .flex-r .control-btn .collection-icon {
                font-size: 20px; }
    .shot-page .content .comment-list {
      background: #ffffff;
      padding: 30px;
      padding: 5vw; }
      @media (max-width: 400px) {
        .shot-page .content .comment-list {
          padding: 20px; } }
      @media (min-width: 600px) {
        .shot-page .content .comment-list {
          padding: 30px; } }

.course-list-modal {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start; }
  .course-list-modal .modal-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start; }
    .course-list-modal .modal-content .result-count {
      font-size: 15px;
      color: #c8d1df;
      margin-top: 20px;
      text-align: center; }
    .course-list-modal .modal-content .confirm-btn {
      width: 200px;
      margin-top: 20px;
      align-self: center; }
    .course-list-modal .modal-content .list {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      height: 500px;
      overflow-y: scroll;
      margin-top: 20px; }
      .course-list-modal .modal-content .list .list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid #dfe8f6;
        cursor: pointer; }
        .course-list-modal .modal-content .list .list-item .checkbox {
          font-size: 14px;
          margin: 0 15px 0 5px; }
        .course-list-modal .modal-content .list .list-item .preview {
          width: 80px;
          height: 60px; }
        .course-list-modal .modal-content .list .list-item .flex-r {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex: 1 1;
          margin-left: 15px; }
          .course-list-modal .modal-content .list .list-item .flex-r .title {
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1 1; }
          .course-list-modal .modal-content .list .list-item .flex-r .teacher {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end; }
            .course-list-modal .modal-content .list .list-item .flex-r .teacher .avatar {
              width: 40px;
              height: 40px;
              margin-left: 10px; }

.bks-work-textarea {
  display: flex; }
  .bks-work-textarea .avatar {
    width: 32px;
    width: 8vw;
    height: 32px;
    height: 8vw;
    margin-right: 30px;
    margin-right: 3vw;
    flex: none; }
    @media (max-width: 400px) {
      .bks-work-textarea .avatar {
        width: 32px; } }
    @media (min-width: 600px) {
      .bks-work-textarea .avatar {
        width: 48px; } }
    @media (max-width: 400px) {
      .bks-work-textarea .avatar {
        height: 32px; } }
    @media (min-width: 600px) {
      .bks-work-textarea .avatar {
        height: 48px; } }
    @media (max-width: 333.33333px) {
      .bks-work-textarea .avatar {
        margin-right: 10px; } }
    @media (min-width: 1000px) {
      .bks-work-textarea .avatar {
        margin-right: 30px; } }
  .bks-work-textarea .comment-textarea {
    width: 100%; }
    .bks-work-textarea .comment-textarea textarea {
      width: 100%;
      background: #f3f5f9;
      border-radius: 4px;
      border: none;
      padding: 12px 18px;
      font-size: 14px;
      font-size: 3vw; }
      @media (max-width: 433.33333px) {
        .bks-work-textarea .comment-textarea textarea {
          font-size: 13px; } }
      @media (min-width: 466.66667px) {
        .bks-work-textarea .comment-textarea textarea {
          font-size: 14px; } }
      .bks-work-textarea .comment-textarea textarea::-webkit-input-placeholder {
        color: #b6bcc8; }
      .bks-work-textarea .comment-textarea textarea:-ms-input-placeholder {
        color: #b6bcc8; }
      .bks-work-textarea .comment-textarea textarea::-ms-input-placeholder {
        color: #b6bcc8; }
      .bks-work-textarea .comment-textarea textarea::placeholder {
        color: #b6bcc8; }
    .bks-work-textarea .comment-textarea .textarea-tool {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #939cae;
      margin-top: 10px;
      font-size: 12px; }
      .bks-work-textarea .comment-textarea .textarea-tool .iconxiaolian {
        margin-right: 4px;
        font-size: 14px; }
      .bks-work-textarea .comment-textarea .textarea-tool button {
        background: #3e4af9;
        border-radius: 100px;
        border: none;
        padding: 9px 36px;
        font-size: 14px;
        color: #ffffff;
        margin-left: 20px;
        outline: none; }

.bks-work-comment {
  display: flex;
  flex-direction: row;
  padding-top: 30px; }
  .bks-work-comment:first-of-type {
    border-top: 1px solid #ebebeb !important; }
  .bks-work-comment:last-child .flex-r {
    border-bottom: none; }
  .bks-work-comment .avatar {
    width: 32px;
    width: 8vw;
    height: 32px;
    height: 8vw;
    margin-right: 30px;
    margin-right: 3vw;
    flex: none; }
    @media (max-width: 400px) {
      .bks-work-comment .avatar {
        width: 32px; } }
    @media (min-width: 600px) {
      .bks-work-comment .avatar {
        width: 48px; } }
    @media (max-width: 400px) {
      .bks-work-comment .avatar {
        height: 32px; } }
    @media (min-width: 600px) {
      .bks-work-comment .avatar {
        height: 48px; } }
    @media (max-width: 333.33333px) {
      .bks-work-comment .avatar {
        margin-right: 10px; } }
    @media (min-width: 1000px) {
      .bks-work-comment .avatar {
        margin-right: 30px; } }
  .bks-work-comment .flex-r {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    border-bottom: 1px solid #ebebeb; }
    .bks-work-comment .flex-r .nickname {
      font-weight: 500;
      font-size: 18px;
      font-size: 3vw;
      color: #222b45; }
      @media (max-width: 466.66667px) {
        .bks-work-comment .flex-r .nickname {
          font-size: 14px; } }
      @media (min-width: 600px) {
        .bks-work-comment .flex-r .nickname {
          font-size: 18px; } }
    .bks-work-comment .flex-r .com-cnt {
      font-size: 15px;
      font-size: 3vw;
      color: #222b45;
      text-align: justify;
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      @media (max-width: 433.33333px) {
        .bks-work-comment .flex-r .com-cnt {
          font-size: 13px; } }
      @media (min-width: 500px) {
        .bks-work-comment .flex-r .com-cnt {
          font-size: 15px; } }
      .bks-work-comment .flex-r .com-cnt .top {
        border: 1px solid #3e4af9;
        color: #3e4af9;
        padding: 1px 3px;
        border-radius: 2px;
        font-size: 10px;
        margin-right: 6px; }
    .bks-work-comment .flex-r .bottom-panel {
      font-size: 12px;
      color: #8f9bb3;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-top: 15px;
      padding-bottom: 15px; }
      .bks-work-comment .flex-r .bottom-panel .like {
        margin-right: 22px; }
        .bks-work-comment .flex-r .bottom-panel .like .iconlike2 {
          font-size: 16px;
          margin-right: 2px; }
      .bks-work-comment .flex-r .bottom-panel .publish-time {
        margin-right: 22px; }
      .bks-work-comment .flex-r .bottom-panel .reply {
        cursor: pointer; }

.comment-textarea {
  width: 100%;
  padding-bottom: 15px; }
  .comment-textarea textarea {
    width: 100%;
    background: #f3f5f9;
    border-radius: 4px;
    border: none;
    padding: 12px 18px;
    font-size: 14px;
    font-size: 3vw; }
    @media (max-width: 433.33333px) {
      .comment-textarea textarea {
        font-size: 13px; } }
    @media (min-width: 466.66667px) {
      .comment-textarea textarea {
        font-size: 14px; } }
    .comment-textarea textarea::-webkit-input-placeholder {
      color: #b6bcc8; }
    .comment-textarea textarea:-ms-input-placeholder {
      color: #b6bcc8; }
    .comment-textarea textarea::-ms-input-placeholder {
      color: #b6bcc8; }
    .comment-textarea textarea::placeholder {
      color: #b6bcc8; }
  .comment-textarea .textarea-tool {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #939cae;
    margin-top: 10px;
    font-size: 12px; }
    .comment-textarea .textarea-tool .expression {
      cursor: pointer; }
      .comment-textarea .textarea-tool .expression .iconxiaolian {
        margin-right: 4px;
        font-size: 14px; }
    .comment-textarea .textarea-tool button {
      background: #3e4af9;
      border-radius: 100px;
      border: none;
      padding: 9px 36px;
      font-size: 14px;
      color: #ffffff;
      margin-left: 20px;
      cursor: pointer; }
  .comment-textarea .emoji-mart-scroll section:nth-child(2) {
    display: none; }
  .comment-textarea .emoji-mart-preview {
    display: none; }
  .comment-textarea .emoji-mart-search {
    display: none; }
  .comment-textarea .emoji-mart-bar {
    display: none; }

.comp-content-no-found {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px; }
  .comp-content-no-found .icon {
    font-size: 80px; }
  .comp-content-no-found .tip {
    color: #a6aebc;
    font-size: 18px; }

.page-shot-post {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%;
  position: relative; }
  .page-shot-post .post-content {
    max-width: 1360px;
    margin: 0 10px; }
  .page-shot-post .control-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    background: white; }
    .page-shot-post .control-bar .control-bar-content {
      flex: 1 1;
      max-width: 1360px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
      .page-shot-post .control-bar .control-bar-content .flex-l {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start; }
      .page-shot-post .control-bar .control-bar-content .flex-r {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        flex: 1 1; }
  .page-shot-post .no-found {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
    align-items: center; }
    .page-shot-post .no-found p {
      font-size: 20px; }
    .page-shot-post .no-found button {
      margin-top: 30px; }
  .page-shot-post h3 {
    color: #8F9BB3; }
  .page-shot-post .input {
    height: 50px;
    font-size: 18px;
    color: #404040; }
  .page-shot-post .textarea {
    font-size: 14px;
    padding: 10px;
    min-height: 120px; }
  .page-shot-post .form-item {
    display: flex;
    flex-direction: column;
    padding: 10px; }
    .page-shot-post .form-item .uploader {
      width: 100%; }
      .page-shot-post .form-item .uploader .ant-upload-btn {
        padding-bottom: 0;
        padding-top: 0; }
      .page-shot-post .form-item .uploader .uploader-container {
        width: 100%;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative; }
        .page-shot-post .form-item .uploader .uploader-container .progress {
          position: absolute; }
        .page-shot-post .form-item .uploader .uploader-container .tip {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%; }
        .page-shot-post .form-item .uploader .uploader-container .shot-preview {
          width: 100%;
          min-height: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .page-shot-post .form-item .uploader .uploader-container .shot-preview img {
            width: 100%;
            height: 100%; }
    .page-shot-post .form-item .category-selector {
      height: 50px;
      font-size: 18px; }
      .page-shot-post .form-item .category-selector input {
        height: 100%; }
  .page-shot-post .editor {
    background: white;
    padding: 15px;
    height: 800px; }
  .page-shot-post .ban-comment {
    margin-top: 15px; }

@charset "UTF-8";
@font-face {
  font-family: DINPro-Bold;
  src: url("/font/DINPro-Bold.otf"); }

#root {
  width: 100%;
  height: 100%; }

#root > div:nth-child(2) {
  min-height: 100%;
  margin-bottom: -174px;
  padding-bottom: 200px; }

html,
body {
  height: 100%; }

body {
  background-color: #f7f9fc !important;
  font-family: -apple-system, SF UI Text, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;
  font-family: "Noto Sans SC", sans-serif; }

ul, li, ol {
  margin: 0;
  padding: 0;
  list-style: none; }

.podcast-modal .qr-code {
  width: 100%; }

.podcast-modal .qr-text {
  color: #888888;
  text-align: center;
  margin-top: 10px; }

/* 富文本全局样式 */
.rich-text {
  font-size: 15px;
  color: #222b45;
  line-height: 25px;
  background: #ffffff;
  display: flex;
  flex-flow: column;
  padding: 30px; }
  .rich-text p {
    margin: 0; }
  .rich-text img {
    max-width: calc(100% + 80px);
    margin: 0 -40px; }

.ant-menu-item {
  margin-bottom: 0px !important; }
  .ant-menu-item:last-child {
    margin-bottom: 8px !important; }

.wrapper {
  max-width: 1380px;
  margin: 0px auto;
  padding: 0px 10px;
  width: 100%;
  height: 100%; }

.flex-group {
  display: flex;
  align-items: center; }

.xs-show,
.md-show,
.lg-show {
  display: none !important; }

@media screen and (max-width: 992px) {
  .lg-hidden,
  .xs-show,
  .md-hidden {
    display: none !important; }
  .lg-show {
    display: block !important; } }

@media screen and (max-width: 768px) {
  .md-hidden,
  .xs-show {
    display: none !important; }
  .md-show {
    display: block !important; } }

@media screen and (max-width: 576px) {
  .xs-hidden {
    display: none !important; }
  .xs-show {
    display: block !important; } }

@-webkit-keyframes skeletonAni {
  0% {
    background: linear-gradient(100deg, #e5e6e9 25%, #eeeeee 37%, #e5e6e9 63%);
    background-size: 400% 100%;
    background-position: 100% 50%; }
  100% {
    background: linear-gradient(100deg, #e5e6e9 25%, #eeeeee 37%, #e5e6e9 63%);
    background-size: 400% 100%;
    background-position: 0 50%; } }

@keyframes skeletonAni {
  0% {
    background: linear-gradient(100deg, #e5e6e9 25%, #eeeeee 37%, #e5e6e9 63%);
    background-size: 400% 100%;
    background-position: 100% 50%; }
  100% {
    background: linear-gradient(100deg, #e5e6e9 25%, #eeeeee 37%, #e5e6e9 63%);
    background-size: 400% 100%;
    background-position: 0 50%; } }

.course-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%;
  position: relative;
  scroll-behavior: smooth; }
  .course-detail #lesson-edit-player-container video {
    background: black; }
  .course-detail #lesson-edit-player-container .prism-progress-played {
    background: #3945F9; }
  .course-detail .course-nav {
    width: 100%;
    background: #ffffff;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08);
    flex: none; }
    .course-detail .course-nav .course-nav-inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      height: 54px;
      justify-content: space-between; }
      .course-detail .course-nav .course-nav-inner .flex-l {
        display: flex;
        flex-direction: row;
        justify-content: flex-start; }
      .course-detail .course-nav .course-nav-inner .course-purchase {
        display: flex;
        align-items: center; }
        .course-detail .course-nav .course-nav-inner .course-purchase .course-price {
          color: #fc4d4d;
          margin-right: 20px;
          font-size: 18px; }
        .course-detail .course-nav .course-nav-inner .course-purchase .course-purchase-btn {
          padding: 8px 50px;
          background: #fc4d4d;
          border-radius: 100px;
          color: #ffffff;
          cursor: pointer; }
  .course-detail .card-marginbottom {
    margin-bottom: 16px;
    margin-bottom: 2vw; }
    @media (max-width: 400px) {
      .course-detail .card-marginbottom {
        margin-bottom: 8px; } }
    @media (min-width: 800px) {
      .course-detail .card-marginbottom {
        margin-bottom: 16px; } }
    .course-detail .card-marginbottom .flex-base {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .course-detail .card-marginbottom .flex-base .collection-icon {
        font-size: 18px;
        cursor: pointer; }
  .course-detail .course-panel {
    background: #ffffff;
    border: 1px solid #f2f2f2;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08);
    margin-bottom: 16px;
    margin-bottom: 2vw; }
    @media (max-width: 400px) {
      .course-detail .course-panel {
        margin-bottom: 8px; } }
    @media (min-width: 800px) {
      .course-detail .course-panel {
        margin-bottom: 16px; } }
    .course-detail .course-panel .course-player-container {
      position: relative; }
      .course-detail .course-panel .course-player-container .course-video {
        max-width: 100%;
        padding-bottom: 56.24%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative; }
        .course-detail .course-panel .course-player-container .course-video .video-play {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center; }
          .course-detail .course-panel .course-player-container .course-video .video-play img {
            width: 70px;
            cursor: pointer;
            opacity: 0.5;
            transition: 0.2s all; }
            .course-detail .course-panel .course-player-container .course-video .video-play img:hover {
              opacity: 0.8; }
      .course-detail .course-panel .course-player-container .continue-alert {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1001; }
        .course-detail .course-panel .course-player-container .continue-alert .title {
          font-size: 15px;
          color: white; }
        .course-detail .course-panel .course-player-container .continue-alert .flex-b {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 20px; }
          .course-detail .course-panel .course-player-container .continue-alert .flex-b button {
            margin: 0 10px;
            box-sizing: content-box; }
          .course-detail .course-panel .course-player-container .continue-alert .flex-b .replay-btn {
            border: 1px solid #ffffff; }
    .course-detail .course-panel .course-lesson-title {
      background: #ffffff;
      padding: 16px;
      padding: 4vw;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 400px) {
        .course-detail .course-panel .course-lesson-title {
          padding: 16px; } }
      @media (min-width: 500px) {
        .course-detail .course-panel .course-lesson-title {
          padding: 20px; } }
      .course-detail .course-panel .course-lesson-title .lesson-title {
        font-size: 16px;
        font-size: 3vw;
        color: #232323;
        text-align: justify; }
        @media (max-width: 466.66667px) {
          .course-detail .course-panel .course-lesson-title .lesson-title {
            font-size: 14px; } }
        @media (min-width: 533.33333px) {
          .course-detail .course-panel .course-lesson-title .lesson-title {
            font-size: 16px; } }
      .course-detail .course-panel .course-lesson-title .course-lesson-file {
        border: #3e4af9 1px solid;
        color: #3e4af9;
        background: #f3f4ff;
        padding: 9px 20px;
        border-radius: 100px;
        cursor: pointer;
        display: flex;
        align-items: center; }
        .course-detail .course-panel .course-lesson-title .course-lesson-file img {
          margin-right: 6px; }
    .course-detail .course-panel .ant-tabs-nav {
      padding-left: 40px; }
      .course-detail .course-panel .ant-tabs-nav .ant-tabs-tab {
        font-size: 16px;
        padding: 16px 0; }
      .course-detail .course-panel .ant-tabs-nav .ant-tabs-ink-bar {
        height: 3px; }
    .course-detail .course-panel .ant-tabs-tabpane {
      padding: 30px;
      padding: 3vw;
      padding-top: 6px;
      padding-top: 1vw; }
      @media (max-width: 333.33333px) {
        .course-detail .course-panel .ant-tabs-tabpane {
          padding: 10px; } }
      @media (min-width: 1000px) {
        .course-detail .course-panel .ant-tabs-tabpane {
          padding: 30px; } }
      @media (max-width: 0px) {
        .course-detail .course-panel .ant-tabs-tabpane {
          padding-top: 0px; } }
      @media (min-width: 600px) {
        .course-detail .course-panel .ant-tabs-tabpane {
          padding-top: 6px; } }
  .course-detail .course-brief {
    display: flex;
    align-items: center;
    font-size: 12px; }
    .course-detail .course-brief .course-status {
      color: #519a31;
      margin-right: 8px; }
    .course-detail .course-brief .course-process {
      color: #232323; }
      .course-detail .course-brief .course-process span {
        color: #3e4af9;
        font-size: 14px;
        font-weight: bold; }
  .course-detail .course-tag {
    margin-top: 10px;
    font-size: 12px;
    color: #a6aebc;
    display: flex;
    flex-wrap: wrap; }
    .course-detail .course-tag span {
      margin-right: 8px; }
  .course-detail .course-teacher-panel {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .course-detail .course-teacher-panel .course-teacher {
      display: flex;
      align-items: center; }
      .course-detail .course-teacher-panel .course-teacher .course-teacher-avatar {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: #f3f4ff;
        margin-right: 12px; }
      .course-detail .course-teacher-panel .course-teacher .course-teacher-name {
        color: #232323;
        font-size: 16px;
        font-weight: bold; }
    .course-detail .course-teacher-panel .teacher-subscribe {
      padding: 2px 7px;
      display: flex;
      align-items: center;
      background: #404040;
      color: #ffffff;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer; }
  .course-detail .lesson-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px; }
    .course-detail .lesson-list li .lesson-title {
      color: #404040;
      font-size: 14px;
      cursor: pointer;
      display: flex; }
    .course-detail .lesson-list li .lesson-selected {
      color: #3945F9; }
    .course-detail .lesson-list li .lesson-status {
      flex: none;
      margin-left: 10px; }
      .course-detail .lesson-list li .lesson-status .lesson-free {
        padding: 0px 4px;
        font-size: 12px;
        color: #4dc24c;
        border: 1px #4dc24c solid;
        background: #e0f6e0;
        border-radius: 4px; }
      .course-detail .lesson-list li .lesson-status .lesson-charge {
        color: #b6bcc8; }
  .course-detail .related-list li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 14px;
    cursor: pointer; }
    .course-detail .related-list li .related-cover {
      max-width: 120px;
      width: 100%;
      margin-right: 14px; }
      .course-detail .related-list li .related-cover .related-cover-inner {
        width: 100%;
        padding-bottom: 56.25%;
        background: #aaaaaa; }
    .course-detail .related-list li .related-title {
      color: #404040; }

.course-group-panel {
  background: #ffffff;
  padding: 10px 12px 12px 12px;
  margin-bottom: 12px; }
  .course-group-panel .course-group-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px; }
  .course-group-panel .course-group {
    display: flex;
    align-items: center; }
    .course-group-panel .course-group .course-group-card {
      width: 200px;
      transition: 0.3s all;
      margin-right: 10px; }
      .course-group-panel .course-group .course-group-card .course-group-card-inner {
        position: relative;
        padding-bottom: 40%;
        border-radius: 2px;
        overflow: hidden; }
        .course-group-panel .course-group .course-group-card .course-group-card-inner img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .course-group-panel .course-group .course-group-card .course-group-card-title {
        font-size: 12px;
        padding-top: 4px;
        color: #666666; }

.live-course-panel {
  margin-bottom: 16px; }
  .live-course-panel .live-course-cover {
    position: relative;
    max-width: 100%;
    padding-bottom: 40%; }
    .live-course-panel .live-course-cover img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute; }
  .live-course-panel .live-course-info {
    background: #ffffff;
    height: 100%;
    border: 1px solid #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .live-course-panel .live-course-info .top-padding {
      padding: 20px;
      flex: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .live-course-panel .live-course-info .top-padding .course-info-detail .title {
        font-size: 20px;
        font-weight: bold;
        color: #3c3c3c; }
      .live-course-panel .live-course-info .top-padding .course-info-detail .date {
        font-size: 14px;
        font-weight: 500;
        margin-top: 4px; }
      .live-course-panel .live-course-info .top-padding .course-info-detail .description {
        color: #b2b2b2;
        font-size: 14px;
        margin-top: 10px; }
      .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 12px;
        margin-top: 1vw;
        height: 20px;
        height: 3vw; }
        @media (max-width: 600px) {
          .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m {
            margin-top: 6px; } }
        @media (min-width: 1200px) {
          .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m {
            margin-top: 12px; } }
        @media (max-width: 533.33333px) {
          .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m {
            height: 16px; } }
        @media (min-width: 666.66667px) {
          .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m {
            height: 20px; } }
        .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .level-tag {
          font-size: 12px;
          font-size: 2vw; }
          @media (max-width: 500px) {
            .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .level-tag {
              font-size: 10px; } }
          @media (min-width: 600px) {
            .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .level-tag {
              font-size: 12px; } }
        .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .live-status {
          font-size: 12px;
          color: #ff5252; }
        .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end; }
          .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps .app-icon-panel {
            width: 20px;
            width: 3vw;
            height: 20px;
            height: 3vw;
            margin-right: 7px;
            overflow: hidden; }
            @media (max-width: 533.33333px) {
              .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps .app-icon-panel {
                width: 16px; } }
            @media (min-width: 666.66667px) {
              .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps .app-icon-panel {
                width: 20px; } }
            @media (max-width: 533.33333px) {
              .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps .app-icon-panel {
                height: 16px; } }
            @media (min-width: 666.66667px) {
              .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps .app-icon-panel {
                height: 20px; } }
            .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps .app-icon-panel .app-icon {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block; }
    .live-course-panel .live-course-info .price-detail {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      background: #3945f9;
      cursor: pointer; }
      .live-course-panel .live-course-info .price-detail .price {
        color: #ffffff;
        font-size: 30px;
        font-weight: bold;
        display: flex;
        align-items: flex-end; }
        .live-course-panel .live-course-info .price-detail .price .price-symbol {
          font-size: 20px;
          padding-bottom: 3px; }
        .live-course-panel .live-course-info .price-detail .price .original-price {
          color: rgba(255, 255, 255, 0.4);
          font-size: 18px;
          text-decoration: line-through;
          padding-bottom: 3px; }
      .live-course-panel .live-course-info .price-detail .apply-btn {
        color: #ffffff;
        font-size: 18px;
        font-weight: bold; }

@media screen and (max-width: 576px) {
  .course-purchase {
    background: #ffffff;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    padding: 10px;
    justify-content: flex-end;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); } }

.comp-card {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
  .comp-card .card-header {
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #edeff0; }
  .comp-card .card-body {
    padding: 20px;
    padding: 16px;
    padding: 4vw; }
    @media (max-width: 400px) {
      .comp-card .card-body {
        padding: 16px; } }
    @media (min-width: 500px) {
      .comp-card .card-body {
        padding: 20px; } }

.comp-weixin-pay-qrcode-modal .info-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px; }
  .comp-weixin-pay-qrcode-modal .info-body .title {
    font-size: 18px;
    color: #4dc24c;
    font-weight: 500; }
  .comp-weixin-pay-qrcode-modal .info-body .image-box {
    min-width: 200px;
    min-height: 200px;
    max-width: 250px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .comp-weixin-pay-qrcode-modal .info-body .image-box img {
      max-width: 250px; }
  .comp-weixin-pay-qrcode-modal .info-body .tip {
    font-size: 14px; }

.account {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%; }
  .account .account-panel {
    margin-top: 80px;
    max-width: 1000px;
    width: 100%;
    background: #ffffff;
    position: relative; }
    .account .account-panel .account-top {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      position: absolute;
      top: -25px;
      z-index: 10;
      width: 100%;
      padding-left: 20px;
      padding-left: 3vw;
      padding-right: 20px;
      padding-right: 3vw; }
      @media (max-width: 400px) {
        .account .account-panel .account-top {
          padding-left: 12px; } }
      @media (min-width: 1133.33333px) {
        .account .account-panel .account-top {
          padding-left: 34px; } }
      @media (max-width: 400px) {
        .account .account-panel .account-top {
          padding-right: 12px; } }
      @media (min-width: 1133.33333px) {
        .account .account-panel .account-top {
          padding-right: 34px; } }
      .account .account-panel .account-top .account-info {
        display: flex;
        align-items: flex-end; }
        .account .account-panel .account-top .account-info .avatar {
          width: 100px;
          height: 100px;
          background: #ffffff;
          border: 5px solid #ffffff;
          box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08);
          position: relative;
          cursor: pointer; }
          .account .account-panel .account-top .account-info .avatar .hover-box {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: absolute; }
          .account .account-panel .account-top .account-info .avatar img {
            height: 100%;
            width: 100%;
            object-fit: cover; }
        .account .account-panel .account-top .account-info .name-panel {
          margin-left: 14px;
          color: #232323; }
          .account .account-panel .account-top .account-info .name-panel .nickname {
            font-size: 20px;
            display: flex;
            flex-direction: row;
            align-items: center; }
            .account .account-panel .account-top .account-info .name-panel .nickname .edit-icon {
              font-size: 14px;
              color: #90939c;
              margin-left: 15px;
              cursor: pointer; }
          .account .account-panel .account-top .account-info .name-panel .label {
            font-size: 12px;
            margin-top: 4px;
            margin-bottom: 10px; }
      .account .account-panel .account-top .account-safe {
        display: flex;
        align-items: center;
        background: #f3f4ff;
        padding: 9px 18px;
        border-radius: 50px;
        margin-bottom: 16px; }
        .account .account-panel .account-top .account-safe .account-txt {
          color: #3e4af9;
          font-size: 14px;
          margin-left: 6px; }
    .account .account-panel .account-content .tab-list-pagination {
      margin: 20px;
      align-self: end; }
    .account .account-panel .account-content .tab-pane {
      min-height: 300px;
      position: relative; }
      .account .account-panel .account-content .tab-pane .message-list {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%; }
    .account .account-panel .account-content .list {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: 20px 0; }
    .account .account-panel .account-content .ant-tabs-bar {
      margin-bottom: 28px;
      height: 145px;
      background: url(/bg/email-pattern.png);
      display: flex;
      align-items: flex-end; }
      .account .account-panel .account-content .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll {
        padding: 0 34px;
        font-size: 16px;
        color: #232323; }
    .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane {
      padding-left: 20px;
      padding-left: 3vw;
      padding-right: 20px;
      padding-right: 3vw; }
      @media (max-width: 400px) {
        .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane {
          padding-left: 12px; } }
      @media (min-width: 1133.33333px) {
        .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane {
          padding-left: 34px; } }
      @media (max-width: 400px) {
        .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane {
          padding-right: 12px; } }
      @media (min-width: 1133.33333px) {
        .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane {
          padding-right: 34px; } }
      .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper {
        padding: 0;
        margin-left: 10px;
        margin-left: 1vw;
        margin-right: 10px;
        margin-right: 1vw;
        background: transparent;
        border-radius: 4px;
        border: none;
        outline: none;
        color: #898989; }
        @media (max-width: 400px) {
          .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper {
            margin-left: 4px; } }
        @media (min-width: 1000px) {
          .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper {
            margin-left: 10px; } }
        @media (max-width: 400px) {
          .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper {
            margin-right: 4px; } }
        @media (min-width: 1000px) {
          .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper {
            margin-right: 10px; } }
        .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper:not(:first-child)::before {
          display: none; }
      .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper-checked {
        color: #3945f9;
        box-shadow: none; }

.edit-nickname-modal .edit-nickname {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  font-size: 20px; }
  .edit-nickname-modal .edit-nickname .input-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }
    .edit-nickname-modal .edit-nickname .input-box input {
      height: 40px;
      flex: 1 1; }
  .edit-nickname-modal .edit-nickname .input-status {
    font-size: 15px; }
  .edit-nickname-modal .edit-nickname .valid-tip {
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px; }
    .edit-nickname-modal .edit-nickname .valid-tip .tip-text {
      margin-left: 10px; }
  .edit-nickname-modal .edit-nickname .footer-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px; }
    .edit-nickname-modal .edit-nickname .footer-btns .edit-btn {
      margin-left: 15px; }

.account-course {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 28px; }
  .account-course .account-course-cover {
    width: 100px;
    width: 20vw; }
    @media (max-width: 500px) {
      .account-course .account-course-cover {
        width: 100px; } }
    @media (min-width: 800px) {
      .account-course .account-course-cover {
        width: 160px; } }
    .account-course .account-course-cover .account-course-cover-inner {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      background: #aaaaaa; }
      .account-course .account-course-cover .account-course-cover-inner img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .account-course .account-course-txt {
    width: 620px;
    width: 50vw;
    padding-left: 14px;
    padding-left: 3vw;
    font-size: 14px;
    font-size: 3vw; }
    @media (max-width: 360px) {
      .account-course .account-course-txt {
        width: 180px; } }
    @media (min-width: 1240px) {
      .account-course .account-course-txt {
        width: 620px; } }
    @media (max-width: 400px) {
      .account-course .account-course-txt {
        padding-left: 12px; } }
    @media (min-width: 666.66667px) {
      .account-course .account-course-txt {
        padding-left: 20px; } }
    @media (max-width: 400px) {
      .account-course .account-course-txt {
        font-size: 12px; } }
    @media (min-width: 466.66667px) {
      .account-course .account-course-txt {
        font-size: 14px; } }
    .account-course .account-course-txt .account-course-title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-size: 3vw;
      margin-bottom: 14px;
      margin-bottom: 1vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      @media (max-width: 466.66667px) {
        .account-course .account-course-txt .account-course-title {
          font-size: 14px; } }
      @media (min-width: 533.33333px) {
        .account-course .account-course-txt .account-course-title {
          font-size: 16px; } }
      @media (max-width: 400px) {
        .account-course .account-course-txt .account-course-title {
          margin-bottom: 4px; } }
      @media (min-width: 2000px) {
        .account-course .account-course-txt .account-course-title {
          margin-bottom: 20px; } }
    .account-course .account-course-txt .account-course-info {
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 4px; }
      .account-course .account-course-txt .account-course-info span {
        color: #3945f9; }
    .account-course .account-course-txt .account-course-time {
      color: #939CAE; }
  .account-course .account-course-btn-panel {
    display: flex;
    justify-content: flex-end;
    flex: auto; }
    .account-course .account-course-btn-panel .account-course-btn {
      background: #3E4AF9;
      border: none;
      padding: 9px 20px;
      border-radius: 50px;
      color: #ffffff;
      cursor: pointer;
      display: flex;
      align-items: center; }

.comp-course-list-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 4px 8px 0 rgba(79, 125, 183, 0.08);
  transition: 0.3s all; }
  .comp-course-list-card:hover {
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
    .comp-course-list-card:hover .bks-shot-flex2 {
      opacity: 0.9; }
  .comp-course-list-card .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: white;
    cursor: pointer; }
    .comp-course-list-card .card .bks-shot-flex0 {
      position: relative;
      overflow: hidden; }
      .comp-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 {
        width: 100%;
        height: 0;
        padding-bottom: 55.7%; }
        .comp-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 {
          height: 100%;
          width: 100%;
          position: absolute;
          transform: scale(1);
          transition: 0.3s all; }
          .comp-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .comp-course-list-card .card .control-bar {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      cursor: pointer; }
      .comp-course-list-card .card .control-bar .course-card-top {
        padding: 18px;
        padding: 16px;
        padding: 2vw; }
        @media (max-width: 500px) {
          .comp-course-list-card .card .control-bar .course-card-top {
            padding: 10px; } }
        @media (min-width: 900px) {
          .comp-course-list-card .card .control-bar .course-card-top {
            padding: 18px; } }
        .comp-course-list-card .card .control-bar .course-card-top .title {
          display: block;
          font-weight: 500;
          font-size: 16px;
          font-size: 3vw;
          color: #3c3c3c;
          letter-spacing: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
          @media (max-width: 400px) {
            .comp-course-list-card .card .control-bar .course-card-top .title {
              font-size: 12px; } }
          @media (min-width: 533.33333px) {
            .comp-course-list-card .card .control-bar .course-card-top .title {
              font-size: 16px; } }
        .comp-course-list-card .card .control-bar .course-card-top .flex-m {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;
          margin-top: 1vw;
          height: 20px;
          height: 3vw; }
          @media (max-width: 600px) {
            .comp-course-list-card .card .control-bar .course-card-top .flex-m {
              margin-top: 6px; } }
          @media (min-width: 1200px) {
            .comp-course-list-card .card .control-bar .course-card-top .flex-m {
              margin-top: 12px; } }
          @media (max-width: 533.33333px) {
            .comp-course-list-card .card .control-bar .course-card-top .flex-m {
              height: 16px; } }
          @media (min-width: 666.66667px) {
            .comp-course-list-card .card .control-bar .course-card-top .flex-m {
              height: 20px; } }
          .comp-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
            font-size: 12px;
            font-size: 2vw; }
            @media (max-width: 500px) {
              .comp-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
                font-size: 10px; } }
            @media (min-width: 600px) {
              .comp-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
                font-size: 12px; } }
          .comp-course-list-card .card .control-bar .course-card-top .flex-m .live-status {
            font-size: 12px;
            color: #ff5252; }
          .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end; }
            .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
              width: 20px;
              width: 3vw;
              height: 20px;
              height: 3vw;
              margin-right: 7px;
              overflow: hidden; }
              @media (max-width: 533.33333px) {
                .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  width: 16px; } }
              @media (min-width: 666.66667px) {
                .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  width: 20px; } }
              @media (max-width: 533.33333px) {
                .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  height: 16px; } }
              @media (min-width: 666.66667px) {
                .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  height: 20px; } }
              .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel .app-icon {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block; }
      .comp-course-list-card .card .control-bar .flex-b {
        height: 45px;
        height: 8vw;
        padding-left: 16px;
        padding-left: 2vw;
        padding-right: 16px;
        padding-right: 2vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #f4f4f4; }
        @media (max-width: 375px) {
          .comp-course-list-card .card .control-bar .flex-b {
            height: 30px; } }
        @media (min-width: 750px) {
          .comp-course-list-card .card .control-bar .flex-b {
            height: 60px; } }
        @media (max-width: 500px) {
          .comp-course-list-card .card .control-bar .flex-b {
            padding-left: 10px; } }
        @media (min-width: 900px) {
          .comp-course-list-card .card .control-bar .flex-b {
            padding-left: 18px; } }
        @media (max-width: 500px) {
          .comp-course-list-card .card .control-bar .flex-b {
            padding-right: 10px; } }
        @media (min-width: 900px) {
          .comp-course-list-card .card .control-bar .flex-b {
            padding-right: 18px; } }
        .comp-course-list-card .card .control-bar .flex-b .leaner-number {
          font-size: 12px;
          color: #939cae;
          letter-spacing: 0;
          flex: none; }
        .comp-course-list-card .card .control-bar .flex-b .isfree {
          color: #5ece6d;
          background-color: rgba(94, 206, 109, 0.1);
          font-size: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 2px 4px;
          letter-spacing: 0; }

.authors {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
  .authors .avatar {
    width: 20px;
    width: 3vw;
    height: 20px;
    height: 3vw;
    border-radius: 11px; }
    @media (max-width: 533.33333px) {
      .authors .avatar {
        width: 16px; } }
    @media (min-width: 733.33333px) {
      .authors .avatar {
        width: 22px; } }
    @media (max-width: 533.33333px) {
      .authors .avatar {
        height: 16px; } }
    @media (min-width: 733.33333px) {
      .authors .avatar {
        height: 22px; } }
  .authors label {
    font-weight: 500;
    font-size: 12px;
    color: #7d7d7d;
    letter-spacing: 0;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80px;
    max-width: 20vw; }
    @media (max-width: 400px) {
      .authors label {
        max-width: 80px; } }
    @media (min-width: 1000px) {
      .authors label {
        max-width: 200px; } }

.dropdown .ant-dropdown-menu-item {
  border-bottom: 1px solid #f5f5f5; }
  .dropdown .ant-dropdown-menu-item:last-child {
    border: none; }

.comp-message-list-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 0 0 0; }
  .comp-message-list-item .avatar {
    width: 48px;
    height: 48px; }
  .comp-message-list-item .comment-textarea {
    margin-top: 20px; }
  .comp-message-list-item .message-body {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin-left: 30px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(216, 216, 216, 0.51); }
    .comp-message-list-item .message-body .message-title {
      font-size: 14px;
      color: #232323;
      text-align: justify; }
      .comp-message-list-item .message-body .message-title .nickname, .comp-message-list-item .message-body .message-title .title {
        font-weight: bold;
        color: #232323; }
    .comp-message-list-item .message-body .message-content {
      font-size: 14px;
      color: #232323;
      text-align: justify;
      line-height: 14px;
      padding-top: 14px; }
    .comp-message-list-item .message-body .flex-b {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-top: 14px; }
      .comp-message-list-item .message-body .flex-b .date {
        font-size: 12px;
        color: #939CAE;
        text-align: justify;
        line-height: 12px; }
      .comp-message-list-item .message-body .flex-b .control-options {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-left: 77px; }
        .comp-message-list-item .message-body .flex-b .control-options .comment-likes {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start; }
          .comp-message-list-item .message-body .flex-b .control-options .comment-likes .like-icon {
            font-size: 14px;
            color: #939CAE;
            cursor: pointer; }
          .comp-message-list-item .message-body .flex-b .control-options .comment-likes .count {
            margin-left: 4px;
            font-size: 12px;
            color: #939CAE;
            text-align: justify;
            line-height: 12px; }
        .comp-message-list-item .message-body .flex-b .control-options .reply-btn {
          margin-left: 24px;
          font-size: 12px;
          color: #939CAE;
          text-align: justify;
          line-height: 12px;
          cursor: pointer; }

.app-course-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%;
  position: relative; }
  .app-course-list .course-nav {
    width: 100%;
    background: #ffffff;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
    .app-course-list .course-nav .course-nav-inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      justify-content: flex-start;
      flex-direction: row;
      padding: 10px 0;
      min-height: 50px; }
      .app-course-list .course-nav .course-nav-inner .flex-l {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap; }
        .app-course-list .course-nav .course-nav-inner .flex-l .app-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          background: rgba(243, 243, 243, 0.08);
          border-radius: 4px;
          font-weight: 500;
          font-size: 14px;
          color: #a2a2a2;
          padding: 5px 11px;
          margin: 6px 22px 6px 0px;
          cursor: pointer; }
          .app-course-list .course-nav .course-nav-inner .flex-l .app-item img {
            width: 18px;
            height: 18px;
            margin-right: 8px; }
        .app-course-list .course-nav .course-nav-inner .flex-l .selected {
          background: rgba(0, 16, 255, 0.08);
          border-radius: 4px; }
  .app-course-list .index-content {
    max-width: 1360px;
    margin: 0 10px;
    min-height: 300px;
    margin-top: 18px;
    margin-top: 2vw;
    width: calc(100% - 20px); }
    @media (max-width: 500px) {
      .app-course-list .index-content {
        margin-top: 10px; } }
    @media (min-width: 900px) {
      .app-course-list .index-content {
        margin-top: 18px; } }
    .app-course-list .index-content .card-marginbottom {
      margin-bottom: 16px;
      margin-bottom: 2vw; }
      @media (max-width: 400px) {
        .app-course-list .index-content .card-marginbottom {
          margin-bottom: 8px; } }
      @media (min-width: 800px) {
        .app-course-list .index-content .card-marginbottom {
          margin-bottom: 16px; } }
  .app-course-list .list-footer {
    width: 100%;
    margin-top: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 56px; }
    .app-course-list .list-footer .bottom-loading {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 30px;
      padding: 0 15px;
      border-radius: 15px;
      background: #3945F9;
      min-width: 100px;
      color: #ffffff; }
      .app-course-list .list-footer .bottom-loading .title {
        font-size: 12px; }
    .app-course-list .list-footer .loaded-all {
      background: none;
      color: #a6aebc; }

.teacher-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%;
  height: 100%; }
  .teacher-home .account-panel {
    margin-top: 80px;
    max-width: 1000px;
    width: 100%;
    background: #ffffff;
    position: relative; }
    .teacher-home .account-panel .account-top {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      position: absolute;
      top: -25px;
      z-index: 10;
      width: 100%;
      padding-left: 20px;
      padding-left: 3vw;
      padding-right: 20px;
      padding-right: 3vw; }
      @media (max-width: 400px) {
        .teacher-home .account-panel .account-top {
          padding-left: 12px; } }
      @media (min-width: 1133.33333px) {
        .teacher-home .account-panel .account-top {
          padding-left: 34px; } }
      @media (max-width: 400px) {
        .teacher-home .account-panel .account-top {
          padding-right: 12px; } }
      @media (min-width: 1133.33333px) {
        .teacher-home .account-panel .account-top {
          padding-right: 34px; } }
      .teacher-home .account-panel .account-top .account-info {
        display: flex;
        align-items: flex-end; }
        .teacher-home .account-panel .account-top .account-info .avatar {
          width: 100px;
          height: 100px;
          background: #ffffff;
          border: 5px solid #ffffff;
          box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08);
          position: relative; }
          .teacher-home .account-panel .account-top .account-info .avatar .hover-box {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: absolute; }
          .teacher-home .account-panel .account-top .account-info .avatar img {
            height: 100%;
            width: 100%;
            object-fit: cover; }
        .teacher-home .account-panel .account-top .account-info .name-panel {
          margin-left: 14px;
          color: #232323; }
          .teacher-home .account-panel .account-top .account-info .name-panel .nickname {
            font-size: 20px;
            display: flex;
            flex-direction: row;
            align-items: center; }
            .teacher-home .account-panel .account-top .account-info .name-panel .nickname .edit-icon {
              font-size: 14px;
              color: #90939C;
              margin-left: 15px; }
          .teacher-home .account-panel .account-top .account-info .name-panel .label {
            font-size: 12px;
            margin-top: 4px;
            margin-bottom: 10px; }
      .teacher-home .account-panel .account-top .account-safe {
        display: flex;
        align-items: center;
        background: #f3f4ff;
        padding: 9px 18px;
        border-radius: 50px;
        margin-bottom: 16px;
        cursor: pointer; }
        .teacher-home .account-panel .account-top .account-safe .account-txt {
          color: #3e4af9;
          font-size: 14px; }
    .teacher-home .account-panel .account-content {
      min-height: 300px;
      padding-bottom: 34px; }
      .teacher-home .account-panel .account-content .tab-pane {
        min-height: 300px;
        position: relative; }
        .teacher-home .account-panel .account-content .tab-pane .tab-list-pagination {
          position: absolute;
          bottom: 20px;
          right: 20px; }
      .teacher-home .account-panel .account-content .header {
        margin-bottom: 28px;
        height: 95px;
        background: url(/bg/email-pattern.png);
        width: 100%; }
      .teacher-home .account-panel .account-content .list {
        width: 100%;
        padding-left: 20px;
        padding-left: 3vw;
        padding-right: 20px;
        padding-right: 3vw;
        display: flex;
        flex-direction: column;
        align-items: stretch; }
        @media (max-width: 400px) {
          .teacher-home .account-panel .account-content .list {
            padding-left: 12px; } }
        @media (min-width: 1133.33333px) {
          .teacher-home .account-panel .account-content .list {
            padding-left: 34px; } }
        @media (max-width: 400px) {
          .teacher-home .account-panel .account-content .list {
            padding-right: 12px; } }
        @media (min-width: 1133.33333px) {
          .teacher-home .account-panel .account-content .list {
            padding-right: 34px; } }

.edit-nickname-modal .edit-nickname {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  font-size: 20px; }
  .edit-nickname-modal .edit-nickname .input-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }
    .edit-nickname-modal .edit-nickname .input-box input {
      height: 40px;
      flex: 1 1; }
  .edit-nickname-modal .edit-nickname .input-status {
    font-size: 15px; }
  .edit-nickname-modal .edit-nickname .valid-tip {
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px; }
    .edit-nickname-modal .edit-nickname .valid-tip .tip-text {
      margin-left: 10px; }
  .edit-nickname-modal .edit-nickname .footer-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px; }
    .edit-nickname-modal .edit-nickname .footer-btns .edit-btn {
      margin-left: 15px; }

.comp-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
  flex: none; }
  .comp-footer .footer-bg-item {
    position: absolute; }
    .comp-footer .footer-bg-item:nth-child(1) {
      right: 20px;
      top: -100px; }
    .comp-footer .footer-bg-item:nth-child(2) {
      left: 50%;
      bottom: -100px;
      margin-left: 300px; }
    .comp-footer .footer-bg-item:nth-child(3) {
      right: 50%;
      bottom: -60px;
      margin-right: 630px; }
  .comp-footer .container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    max-width: 1360px;
    margin: 0 20px; }
    .comp-footer .container .items {
      display: flex;
      margin-bottom: 30px;
      flex-wrap: wrap; }
      .comp-footer .container .items .nav-group {
        display: flex;
        flex-direction: column;
        margin-right: 40px; }
        .comp-footer .container .items .nav-group .group-title {
          font-size: 16px;
          color: #666666;
          font-weight: 500;
          margin-top: 30px;
          margin-bottom: 10px; }
        .comp-footer .container .items .nav-group .links {
          display: flex;
          flex-direction: column;
          margin-top: 10px; }
          .comp-footer .container .items .nav-group .links a {
            margin-bottom: 8px;
            color: #999999; }
      .comp-footer .container .items .link {
        cursor: pointer;
        font-size: 16px;
        color: #494949;
        margin-right: 22px; }
        .comp-footer .container .items .link:hover {
          color: #3945F9; }
    .comp-footer .container .cooperation-links {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      font-size: 14px;
      color: #A6AEBC;
      margin-right: 10px; }
      .comp-footer .container .cooperation-links a {
        color: #A6AEBC;
        margin-right: 10px; }
        .comp-footer .container .cooperation-links a:hover {
          color: #3945F9; }
    .comp-footer .container .copyright {
      font-size: 14px;
      color: #A6AEBC;
      margin-top: 8px; }
      .comp-footer .container .copyright a {
        color: #A6AEBC; }

.course-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%;
  position: relative; }
  .course-detail #lesson-edit-player-container video {
    background: black; }
  .course-detail #lesson-edit-player-container .prism-progress-played {
    background: #3945F9; }
  .course-detail .course-nav {
    width: 100%;
    background: #ffffff;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08);
    flex: none; }
    .course-detail .course-nav .course-nav-inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      height: 54px;
      justify-content: space-between; }
      .course-detail .course-nav .course-nav-inner .flex-l {
        display: flex;
        flex-direction: row;
        justify-content: flex-start; }
        .course-detail .course-nav .course-nav-inner .flex-l .collection-icon {
          margin-left: 20px;
          font-size: 22px;
          cursor: pointer; }
      .course-detail .course-nav .course-nav-inner .course-purchase {
        display: flex;
        align-items: center; }
        .course-detail .course-nav .course-nav-inner .course-purchase .course-price {
          color: #fc4d4d;
          margin-right: 20px;
          font-size: 18px; }
        .course-detail .course-nav .course-nav-inner .course-purchase .course-purchase-btn {
          padding: 8px 50px;
          background: #fc4d4d;
          border-radius: 100px;
          color: #ffffff;
          cursor: pointer; }
  .course-detail .index-content {
    max-width: 1360px;
    margin: 0 10px;
    margin-top: 18px;
    margin-top: 2vw;
    width: calc(100% - 20px); }
    @media (max-width: 500px) {
      .course-detail .index-content {
        margin-top: 10px; } }
    @media (min-width: 900px) {
      .course-detail .index-content {
        margin-top: 18px; } }
    .course-detail .index-content .card-marginbottom {
      margin-bottom: 16px;
      margin-bottom: 2vw; }
      @media (max-width: 400px) {
        .course-detail .index-content .card-marginbottom {
          margin-bottom: 8px; } }
      @media (min-width: 800px) {
        .course-detail .index-content .card-marginbottom {
          margin-bottom: 16px; } }
    .course-detail .index-content .course-panel {
      background: #ffffff;
      border: 1px solid #ebebeb;
      box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08);
      margin-bottom: 16px;
      margin-bottom: 2vw; }
      @media (max-width: 400px) {
        .course-detail .index-content .course-panel {
          margin-bottom: 8px; } }
      @media (min-width: 800px) {
        .course-detail .index-content .course-panel {
          margin-bottom: 16px; } }
      .course-detail .index-content .course-panel .course-player-container {
        position: relative; }
        .course-detail .index-content .course-panel .course-player-container .course-video {
          max-width: 100%;
          padding-bottom: 56.24%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          position: relative; }
          .course-detail .index-content .course-panel .course-player-container .course-video .video-play {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center; }
            .course-detail .index-content .course-panel .course-player-container .course-video .video-play img {
              width: 70px;
              cursor: pointer;
              opacity: 0.5;
              transition: 0.2s all; }
              .course-detail .index-content .course-panel .course-player-container .course-video .video-play img:hover {
                opacity: 0.8; }
        .course-detail .index-content .course-panel .course-player-container .continue-alert {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          z-index: 1001; }
          .course-detail .index-content .course-panel .course-player-container .continue-alert .title {
            font-size: 15px;
            color: white; }
          .course-detail .index-content .course-panel .course-player-container .continue-alert .flex-b {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px; }
            .course-detail .index-content .course-panel .course-player-container .continue-alert .flex-b button {
              margin: 0 10px;
              box-sizing: content-box; }
            .course-detail .index-content .course-panel .course-player-container .continue-alert .flex-b .replay-btn {
              border: 1px solid #ffffff; }
      .course-detail .index-content .course-panel .course-lesson-title {
        background: #ffffff;
        padding: 16px;
        padding: 4vw;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        @media (max-width: 400px) {
          .course-detail .index-content .course-panel .course-lesson-title {
            padding: 16px; } }
        @media (min-width: 500px) {
          .course-detail .index-content .course-panel .course-lesson-title {
            padding: 20px; } }
        .course-detail .index-content .course-panel .course-lesson-title .lesson-title {
          font-size: 16px;
          font-size: 3vw;
          color: #232323;
          text-align: justify; }
          @media (max-width: 466.66667px) {
            .course-detail .index-content .course-panel .course-lesson-title .lesson-title {
              font-size: 14px; } }
          @media (min-width: 533.33333px) {
            .course-detail .index-content .course-panel .course-lesson-title .lesson-title {
              font-size: 16px; } }
        .course-detail .index-content .course-panel .course-lesson-title .course-lesson-file {
          border: #3e4af9 1px solid;
          color: #3e4af9;
          background: #f3f4ff;
          padding: 9px 20px;
          border-radius: 100px;
          cursor: pointer;
          display: flex;
          align-items: center; }
          .course-detail .index-content .course-panel .course-lesson-title .course-lesson-file img {
            margin-right: 6px; }
      .course-detail .index-content .course-panel .ant-tabs-nav {
        padding-left: 40px; }
        .course-detail .index-content .course-panel .ant-tabs-nav .ant-tabs-tab {
          font-size: 16px;
          padding: 16px 0; }
        .course-detail .index-content .course-panel .ant-tabs-nav .ant-tabs-ink-bar {
          height: 3px; }
      .course-detail .index-content .course-panel .ant-tabs-tabpane {
        padding: 30px;
        padding: 3vw; }
        @media (max-width: 333.33333px) {
          .course-detail .index-content .course-panel .ant-tabs-tabpane {
            padding: 10px; } }
        @media (min-width: 1000px) {
          .course-detail .index-content .course-panel .ant-tabs-tabpane {
            padding: 30px; } }
    .course-detail .index-content .course-brief {
      display: flex;
      align-items: center;
      font-size: 12px; }
      .course-detail .index-content .course-brief .course-status {
        color: #519a31;
        margin-right: 8px; }
      .course-detail .index-content .course-brief .course-process {
        color: #232323; }
        .course-detail .index-content .course-brief .course-process span {
          color: #3e4af9;
          font-size: 14px;
          font-weight: bold; }
    .course-detail .index-content .course-tag {
      margin-top: 10px;
      font-size: 12px;
      color: #a6aebc;
      display: flex;
      flex-wrap: wrap; }
      .course-detail .index-content .course-tag span {
        margin-right: 8px; }
    .course-detail .index-content .course-teacher-panel {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .course-detail .index-content .course-teacher-panel .course-teacher {
        display: flex;
        align-items: center; }
        .course-detail .index-content .course-teacher-panel .course-teacher .course-teacher-avatar {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          background: #f3f4ff;
          margin-right: 12px; }
        .course-detail .index-content .course-teacher-panel .course-teacher .course-teacher-name {
          color: #232323;
          font-size: 16px;
          font-weight: bold; }
      .course-detail .index-content .course-teacher-panel .teacher-subscribe {
        padding: 2px 7px;
        display: flex;
        align-items: center;
        background: #404040;
        color: #ffffff;
        border-radius: 4px;
        font-size: 12px;
        cursor: pointer; }
    .course-detail .index-content .lesson-list li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px; }
      .course-detail .index-content .lesson-list li .lesson-title {
        color: #404040;
        font-size: 14px;
        cursor: pointer;
        display: flex; }
      .course-detail .index-content .lesson-list li .lesson-selected {
        color: #3945F9; }
      .course-detail .index-content .lesson-list li .lesson-status {
        flex: none;
        margin-left: 10px; }
        .course-detail .index-content .lesson-list li .lesson-status .lesson-free {
          padding: 0px 4px;
          font-size: 12px;
          color: #4dc24c;
          border: 1px #4dc24c solid;
          background: #e0f6e0;
          border-radius: 4px; }
        .course-detail .index-content .lesson-list li .lesson-status .lesson-charge {
          color: #b6bcc8; }
    .course-detail .index-content .related-list li {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 14px;
      cursor: pointer; }
      .course-detail .index-content .related-list li .related-cover {
        max-width: 120px;
        width: 100%;
        margin-right: 14px; }
        .course-detail .index-content .related-list li .related-cover .related-cover-inner {
          width: 100%;
          padding-bottom: 56.25%;
          background: #aaaaaa; }
      .course-detail .index-content .related-list li .related-title {
        color: #404040; }

@media screen and (max-width: 576px) {
  .course-purchase {
    background: #ffffff;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    padding: 10px;
    justify-content: flex-end;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); } }

.tool-panel {
  max-width: 1360px;
  width: calc(100% - 20px);
  margin: 30px auto; }
  .tool-panel .tool-main-title {
    font-size: 14px;
    color: #666666;
    margin: 50px 0 16px; }
  .tool-panel .tool-card {
    background: #ffffff;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0);
    cursor: pointer;
    transition: 0.3s all; }
    .tool-panel .tool-card:hover {
      box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
      .tool-panel .tool-card:hover .tool-card-bg {
        transform: scale(13); }
    .tool-panel .tool-card .tool-cover {
      width: 100%;
      height: 0;
      padding-bottom: 30%;
      position: relative;
      border-bottom: 1px solid #f2f2f2;
      overflow: hidden; }
      .tool-panel .tool-card .tool-cover .tool-card-bg {
        position: absolute;
        left: -100px;
        top: -100px;
        width: 50px;
        height: 50px;
        border-radius: 30px;
        background: #ffffff;
        transition: 0.8s all cubic-bezier(0.01, 0.54, 0.07, 1.01); }
      .tool-panel .tool-card .tool-cover .tool-cover-inner {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 14px;
        padding: 2vw; }
        @media (max-width: 500px) {
          .tool-panel .tool-card .tool-cover .tool-cover-inner {
            padding: 10px; } }
        @media (min-width: 800px) {
          .tool-panel .tool-card .tool-cover .tool-cover-inner {
            padding: 16px; } }
        .tool-panel .tool-card .tool-cover .tool-cover-inner img {
          height: 100%;
          object-fit: cover; }
    .tool-panel .tool-card .tool-text-panel {
      padding-left: 14px;
      padding-left: 2vw;
      padding-right: 14px;
      padding-right: 2vw;
      padding-top: 10px;
      padding-top: 2vw;
      padding-bottom: 10px;
      padding-bottom: 2vw; }
      @media (max-width: 500px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-left: 10px; } }
      @media (min-width: 800px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-left: 16px; } }
      @media (max-width: 500px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-right: 10px; } }
      @media (min-width: 800px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-right: 16px; } }
      @media (max-width: 400px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-top: 8px; } }
      @media (min-width: 500px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-top: 10px; } }
      @media (max-width: 400px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-bottom: 8px; } }
      @media (min-width: 500px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-bottom: 10px; } }
      .tool-panel .tool-card .tool-text-panel .tool-title {
        font-weight: 500;
        font-size: 18px;
        font-size: 3vw;
        color: #999999; }
        @media (max-width: 400px) {
          .tool-panel .tool-card .tool-text-panel .tool-title {
            font-size: 12px; } }
        @media (min-width: 466.66667px) {
          .tool-panel .tool-card .tool-text-panel .tool-title {
            font-size: 14px; } }
      .tool-panel .tool-card .tool-text-panel .tool-des {
        margin-top: 6px;
        font-size: 12px;
        font-size: 2vw; }
        @media (max-width: 500px) {
          .tool-panel .tool-card .tool-text-panel .tool-des {
            font-size: 10px; } }
        @media (min-width: 600px) {
          .tool-panel .tool-card .tool-text-panel .tool-des {
            font-size: 12px; } }


.live-course .banner-box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
  .live-course .banner-box .ant-carousel .carousel {
    background: none; }
    .live-course .banner-box .ant-carousel .carousel a {
      display: block !important; }
      .live-course .banner-box .ant-carousel .carousel a .background-box {
        position: relative; }
        .live-course .banner-box .ant-carousel .carousel a .background-box .banner-inner-panel {
          width: 100%;
          height: 0;
          padding-bottom: 31.24%; }
          .live-course .banner-box .ant-carousel .carousel a .background-box .banner-inner-panel img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .live-course .banner-box .ant-carousel .carousel .slick-dots button:before {
      display: none; }
  .live-course .banner-box .banner-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    position: relative;
    padding: 10px 0; }
    .live-course .banner-box .banner-bottom .carousel-indicator {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      margin-right: 20px; }
      .live-course .banner-box .banner-bottom .carousel-indicator .dotted {
        width: 6px;
        height: 6px;
        background: #3945F9;
        opacity: 0.2;
        margin: 0 4px;
        cursor: pointer; }
      .live-course .banner-box .banner-bottom .carousel-indicator .select {
        opacity: 1; }

.live-course .list-header {
  margin-top: 30px;
  margin-top: 5vw;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%; }
  @media (max-width: 400px) {
    .live-course .list-header {
      margin-top: 20px; } }
  @media (min-width: 1000px) {
    .live-course .list-header {
      margin-top: 50px; } }
  .live-course .list-header .category-search {
    width: 100%; }
    .live-course .list-header .category-search .ant-menu {
      background: none;
      border: none;
      font-size: 14px;
      line-height: 26px; }
      .live-course .list-header .category-search .ant-menu .ant-menu-item {
        padding: 0;
        margin-right: 20px;
        border-bottom: none; }
      .live-course .list-header .category-search .ant-menu .ant-menu-submenu {
        margin: 0 20px;
        border-bottom: none; }
        .live-course .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
          padding: 0; }
          .live-course .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper {
            display: flex;
            align-items: center; }
            .live-course .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper .selector-arrow {
              margin-left: 10px;
              margin-right: 0;
              font-size: 8px;
              color: rgba(0, 0, 0, 0.2);
              transition: 0.5s all; }
      .live-course .list-header .category-search .ant-menu .ant-menu-submenu-active .selector-arrow {
        color: #3945F9 !important; }
    .live-course .list-header .category-search .search-bar {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      height: 30px;
      height: 8vw;
      border-radius: 20px;
      background: white; }
      @media (max-width: 375px) {
        .live-course .list-header .category-search .search-bar {
          height: 30px; } }
      @media (min-width: 500px) {
        .live-course .list-header .category-search .search-bar {
          height: 40px; } }
      .live-course .list-header .category-search .search-bar input {
        height: 22px;
        font-size: 14px;
        font-size: 3vw;
        flex: 1 1;
        background: none;
        margin-left: 20px;
        outline: none;
        border: none; }
        @media (max-width: 466.66667px) {
          .live-course .list-header .category-search .search-bar input {
            font-size: 14px; } }
        @media (min-width: 533.33333px) {
          .live-course .list-header .category-search .search-bar input {
            font-size: 16px; } }
        .live-course .list-header .category-search .search-bar input::-webkit-input-placeholder {
          font-size: 14px;
          font-size: 3vw;
          color: #939cae;
          letter-spacing: 0; }
          @media (max-width: 466.66667px) {
            .live-course .list-header .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 14px; } }
          @media (min-width: 533.33333px) {
            .live-course .list-header .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 16px; } }
        .live-course .list-header .category-search .search-bar input:focus {
          outline: none;
          border: none; }
      .live-course .list-header .category-search .search-bar .search-icon {
        font-size: 20px;
        font-size: 14px;
        font-size: 4vw;
        color: #959eb0;
        margin: 0 20px;
        cursor: pointer; }
        @media (max-width: 400px) {
          .live-course .list-header .category-search .search-bar .search-icon {
            font-size: 16px; } }
        @media (min-width: 500px) {
          .live-course .list-header .category-search .search-bar .search-icon {
            font-size: 20px; } }
        .live-course .list-header .category-search .search-bar .search-icon:hover {
          color: #3945F9; }
      .live-course .list-header .category-search .search-bar .searching {
        color: #3945F9; }

.live-course .list-body {
  width: 100%;
  margin-top: 24px; }

.list-filter {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 16px;
  padding: 20px;
  padding: 1vw; }
  @media (max-width: 400px) {
    .list-filter {
      padding: 4px; } }
  @media (min-width: 2000px) {
    .list-filter {
      padding: 20px; } }
  .list-filter .filter-row {
    margin-bottom: 16px; }
    .list-filter .filter-row .filter-label {
      color: #666666;
      padding-bottom: 10px; }
    .list-filter .filter-row .filter-option {
      display: flex;
      flex-wrap: wrap; }
      .list-filter .filter-row .filter-option .filter-item {
        margin-right: 10px;
        margin-right: 1vw;
        margin-bottom: 10px;
        margin-bottom: 1vw;
        background: transparent;
        font-size: 12px;
        border-radius: 2px;
        border: 1px solid #eeeeee;
        outline: none;
        color: #898989;
        height: auto;
        line-height: normal;
        line-height: initial;
        padding: 4px 8px;
        display: flex;
        justify-content: center;
        align-items: center; }
        @media (max-width: 400px) {
          .list-filter .filter-row .filter-option .filter-item {
            margin-right: 4px; } }
        @media (min-width: 1000px) {
          .list-filter .filter-row .filter-option .filter-item {
            margin-right: 10px; } }
        @media (max-width: 400px) {
          .list-filter .filter-row .filter-option .filter-item {
            margin-bottom: 4px; } }
        @media (min-width: 1000px) {
          .list-filter .filter-row .filter-option .filter-item {
            margin-bottom: 10px; } }
        .list-filter .filter-row .filter-option .filter-item:not(:first-child)::before {
          display: none; }
      .list-filter .filter-row .filter-option .ant-radio-button-wrapper-checked {
        background: #f3f4ff;
        color: #3945f9;
        border: 1px solid #b5baff;
        box-shadow: none; }
    .list-filter .filter-row .software .filter-item {
      padding: 0;
      width: 40px;
      height: 40px; }
      .list-filter .filter-row .software .filter-item img {
        width: 20px;
        height: 20px; }

.all-course .list-body {
  width: 100%; }
  .all-course .list-body .free-option {
    align-self: center;
    height: 40px;
    display: flex;
    align-items: center; }
    .all-course .list-body .free-option .filter-option {
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      .all-course .list-body .free-option .filter-option .filter-item {
        background: transparent;
        font-size: 12px;
        border-radius: 2px;
        border: 1px solid #eeeeee;
        outline: none;
        color: #898989;
        height: auto;
        line-height: normal;
        line-height: initial;
        padding: 4px 8px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .all-course .list-body .free-option .filter-option .filter-item:not(:first-child)::before {
          display: none; }
      .all-course .list-body .free-option .filter-option .ant-radio-button-wrapper-checked {
        background: #f3f4ff;
        color: #3945f9;
        border: 1px solid #b5baff;
        box-shadow: none; }
  .all-course .list-body .search-col {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .all-course .list-body .category-search {
    width: 100%;
    margin-bottom: 10px; }
    .all-course .list-body .category-search .sort-panel {
      display: flex;
      align-items: center; }
      .all-course .list-body .category-search .sort-panel .filter-btn {
        display: flex;
        align-items: center;
        margin-right: 20px;
        margin-left: 6px;
        cursor: pointer; }
        .all-course .list-body .category-search .sort-panel .filter-btn .filter-icon {
          margin-left: 6px;
          color: #bbbbbb; }
      .all-course .list-body .category-search .sort-panel .sort-btn-active {
        color: #3945F9; }
      .all-course .list-body .category-search .sort-panel .sort-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(255, 255, 255, 0);
        border: none;
        margin-right: 12px;
        border-radius: 2px; }
        .all-course .list-body .category-search .sort-panel .sort-btn:active {
          color: #000;
          color: initial; }
        .all-course .list-body .category-search .sort-panel .sort-btn .sort-group {
          margin-left: 4px;
          display: flex;
          flex-flow: column;
          justify-content: center; }
          .all-course .list-body .category-search .sort-panel .sort-btn .sort-group .anticon-caret-up {
            margin-bottom: -4px; }
          .all-course .list-body .category-search .sort-panel .sort-btn .sort-group .icon-active {
            color: #3945F9; }
          .all-course .list-body .category-search .sort-panel .sort-btn .sort-group .arrow-icon {
            color: #bbbbbb; }
    .all-course .list-body .category-search .middle-blank {
      height: 10px; }
    .all-course .list-body .category-search .ant-menu {
      background: none;
      border: none;
      font-size: 14px;
      line-height: 26px; }
      .all-course .list-body .category-search .ant-menu .ant-menu-item {
        padding: 0;
        margin-right: 20px;
        border-bottom: none; }
      .all-course .list-body .category-search .ant-menu .ant-menu-submenu {
        margin: 0 20px;
        border-bottom: none; }
        .all-course .list-body .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
          padding: 0; }
          .all-course .list-body .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper {
            display: flex;
            align-items: center; }
            .all-course .list-body .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper .selector-arrow {
              margin-left: 10px;
              margin-right: 0;
              font-size: 8px;
              color: rgba(0, 0, 0, 0.2);
              transition: 0.5s all; }
      .all-course .list-body .category-search .ant-menu .ant-menu-submenu-active .selector-arrow {
        color: #3945F9 !important; }
    .all-course .list-body .category-search .search-bar {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 30px;
      height: 8vw;
      border-radius: 2px;
      background: white; }
      @media (max-width: 375px) {
        .all-course .list-body .category-search .search-bar {
          height: 30px; } }
      @media (min-width: 400px) {
        .all-course .list-body .category-search .search-bar {
          height: 32px; } }
      .all-course .list-body .category-search .search-bar input {
        height: 22px;
        font-size: 14px;
        font-size: 3vw;
        flex: 1 1;
        background: none;
        margin-left: 10px;
        outline: none;
        border: none; }
        @media (max-width: 400px) {
          .all-course .list-body .category-search .search-bar input {
            font-size: 12px; } }
        @media (min-width: 466.66667px) {
          .all-course .list-body .category-search .search-bar input {
            font-size: 14px; } }
        .all-course .list-body .category-search .search-bar input::-webkit-input-placeholder {
          font-size: 14px;
          font-size: 3vw;
          color: #939cae;
          letter-spacing: 0; }
          @media (max-width: 400px) {
            .all-course .list-body .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 12px; } }
          @media (min-width: 466.66667px) {
            .all-course .list-body .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 14px; } }
        .all-course .list-body .category-search .search-bar input:focus {
          outline: none;
          border: none; }
      .all-course .list-body .category-search .search-bar .search-icon {
        font-size: 14px;
        font-size: 4vw;
        color: #959eb0;
        margin: 0 10px;
        cursor: pointer; }
        @media (max-width: 350px) {
          .all-course .list-body .category-search .search-bar .search-icon {
            font-size: 14px; } }
        @media (min-width: 400px) {
          .all-course .list-body .category-search .search-bar .search-icon {
            font-size: 16px; } }
        .all-course .list-body .category-search .search-bar .search-icon:hover {
          color: #3945F9; }
      .all-course .list-body .category-search .search-bar .searching {
        color: #3945F9; }

.course-plan {
  display: flex;
  flex-direction: column; }
  .course-plan .apply-link {
    margin-left: 20px;
    color: #3c81f0; }

.comp-gantt-chart {
  margin-top: 50px;
  margin-bottom: 20px; }
  .comp-gantt-chart .table-row .row-titles {
    display: flex;
    flex-direction: column; }
    .comp-gantt-chart .table-row .row-titles .table-title {
      font-size: 14px;
      font-weight: bold;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      background: #f5f5f5; }
    .comp-gantt-chart .table-row .row-titles .title {
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      padding-right: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      border-left: 1px solid rgba(0, 0, 0, 0.05);
      background: #f5f5f5; }
      .comp-gantt-chart .table-row .row-titles .title span {
        text-align: right; }
  .comp-gantt-chart .table-row .table-grids {
    display: flex;
    flex-direction: column;
    overflow-x: scroll; }
    .comp-gantt-chart .table-row .table-grids .table-item-grid {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      position: relative;
      height: 50px; }
      .comp-gantt-chart .table-row .table-grids .table-item-grid .grid-box {
        background: white;
        height: 100%;
        flex: 1 1;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative; }
        .comp-gantt-chart .table-row .table-grids .table-item-grid .grid-box .grid-box-line {
          position: absolute;
          right: 0;
          height: 100%;
          width: 1px;
          background: rgba(0, 0, 0, 0.05); }
      .comp-gantt-chart .table-row .table-grids .table-item-grid .full-bar {
        position: absolute;
        height: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 2px;
        transition: 1s all ease;
        cursor: pointer; }
        .comp-gantt-chart .table-row .table-grids .table-item-grid .full-bar .start-date {
          color: white;
          position: absolute;
          left: 6px;
          font-size: 12px;
          width: 100px;
          text-align: start;
          opacity: 0;
          transition: 1s all ease 0.5s; }
        .comp-gantt-chart .table-row .table-grids .table-item-grid .full-bar .end-date {
          right: 6px;
          left: auto;
          text-align: end; }
      .comp-gantt-chart .table-row .table-grids .table-item-grid .full-bar-animate .start-date {
        opacity: 1; }
      .comp-gantt-chart .table-row .table-grids .table-item-grid .table-header-item {
        font-size: 14px;
        background: none;
        padding-left: 6px;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        border-top: 1px solid rgba(0, 0, 0, 0.05); }

.podcast-panel .podcast-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding-bottom: 23px;
  padding-top: 36px; }
  .podcast-panel .podcast-info .content-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 280px; }
    .podcast-panel .podcast-info .content-body .cover {
      width: 280px;
      height: 280px;
      object-fit: cover;
      box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.12);
      border-radius: 8px; }
    .podcast-panel .podcast-info .content-body .cover-title {
      font-family: "Noto Serif SC", serif;
      font-size: 36px;
      font-weight: 700;
      color: #313233;
      letter-spacing: 6px;
      padding-top: 30px; }
    .podcast-panel .podcast-info .content-body .desc {
      padding: 16px 0;
      font-family: "Noto Serif SC", serif;
      font-size: 14px;
      font-weight: 400;
      color: #414242;
      text-align: center;
      line-height: 28px; }
    .podcast-panel .podcast-info .content-body .inside-other-sources {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      border-top: 1px solid #f4f4f4;
      border-bottom: 1px solid #f4f4f4;
      padding: 16px 0; }
      .podcast-panel .podcast-info .content-body .inside-other-sources img {
        width: 40px;
        height: 40px; }
    .podcast-panel .podcast-info .content-body .outside-other-sources {
      border-bottom: 1px solid #f4f4f4;
      width: 100%;
      padding: 10px 0; }
      .podcast-panel .podcast-info .content-body .outside-other-sources .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 0; }
        .podcast-panel .podcast-info .content-body .outside-other-sources .item .icon {
          width: 28px;
          height: 28px; }
        .podcast-panel .podcast-info .content-body .outside-other-sources .item div {
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #333333;
          line-height: 18px;
          flex: 1 1;
          margin-left: 18px;
          margin-right: 18px; }
        .podcast-panel .podcast-info .content-body .outside-other-sources .item .arrow {
          width: 8px;
          height: 14px; }
  .podcast-panel .podcast-info .authors {
    display: flex;
    flex-direction: column;
    width: 320px; }
    .podcast-panel .podcast-info .authors .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #f6f6f6;
      border-radius: 4px;
      width: 100%;
      padding: 19px 20px;
      margin-top: 16px; }
      .podcast-panel .podcast-info .authors .item .avatar {
        width: 44px;
        height: 44px;
        border-radius: 50%; }
      .podcast-panel .podcast-info .authors .item .flex-r {
        display: flex;
        flex-direction: column;
        padding-left: 16px; }
        .podcast-panel .podcast-info .authors .item .flex-r .name {
          font-weight: bold;
          font-size: 18px;
          color: #2d2d2d;
          line-height: 18px; }
        .podcast-panel .podcast-info .authors .item .flex-r .wechat {
          margin-top: 8px;
          font-size: 14px;
          color: #999999;
          line-height: 14px; }

.podcast-panel .flex-r .podcast-audio-list-container {
  background-color: white;
  padding-top: 7vw;
  padding-bottom: 7vw;
  padding-left: 8vw;
  padding-right: 8vw; }
  @media (max-width: 428.57143px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-top: 30px; } }
  @media (min-width: 600px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-top: 42px; } }
  @media (max-width: 428.57143px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-bottom: 30px; } }
  @media (min-width: 600px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-bottom: 42px; } }
  @media (max-width: 400px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-left: 32px; } }
  @media (min-width: 775px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-left: 62px; } }
  @media (max-width: 400px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-right: 32px; } }
  @media (min-width: 775px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-right: 62px; } }
  .podcast-panel .flex-r .podcast-audio-list-container .tag-title {
    font-size: 26px;
    color: #3b3b3b;
    line-height: 26px;
    font-family: "Noto Serif SC", serif;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px; }
  .podcast-panel .flex-r .podcast-audio-list-container .highlight-tag {
    color: #3945F9; }
  .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: white;
    flex: 1 1; }
    .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 16px;
      margin-bottom: 20px;
      border-bottom: 1px solid #f4f4f4;
      width: 100%; }
      .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .sub-props {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%; }
        .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .sub-props .sub-props-left {
          display: flex;
          flex-wrap: wrap; }
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .sub-props .sub-props-left .text {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #999999;
            margin-right: 4px;
            display: flex;
            align-items: center; }
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .sub-props .sub-props-left .highlight-tag {
            color: #3945F9; }
      .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .podcast-mini {
        display: flex;
        align-items: center;
        margin-top: 12px;
        color: #666666;
        cursor: pointer; }
        .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .podcast-mini .icon {
          font-size: 18px;
          margin-right: 5px; }
        .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .podcast-mini .podcast-mini-text {
          margin-top: 2px;
          color: #888888; }
      .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .desc {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #848484;
        line-height: 28px; }
      .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: "Noto Serif SC", serif;
        font-weight: 700;
        font-size: 26px;
        font-size: 5vw;
        color: #3b3b3b;
        width: 100%;
        padding: 18px 0 16px; }
        @media (max-width: 440px) {
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item {
            font-size: 22px; } }
        @media (min-width: 520px) {
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item {
            font-size: 26px; } }
        .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item .title-props {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          width: 100%; }
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item .title-props .podcast-icon {
            width: 22px;
            height: 22px;
            margin-right: 15px;
            margin-top: 10px; }
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item .title-props .play-title {
            color: #3b3b3b;
            margin-right: 15px;
            flex: 1 1; }
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item .title-props .play-duration {
            text-align: center; }
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item .title-props .play-btn {
            width: 36px;
            height: 36px;
            margin-left: 16px;
            margin-top: 5px;
            cursor: pointer;
            color: #3945f9;
            font-size: 36px;
            display: flex; }

.podcast-panel .play-panel {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .podcast-panel .play-panel .play-wrapper {
    background-color: #313233;
    box-shadow: 0 10px 22px rgba(0, 0, 0, 0.42);
    border-radius: 10px;
    max-width: 1360px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px 32px;
    padding-left: 5vw;
    padding-right: 5vw;
    width: calc(100% - 20px);
    position: relative; }
    @media (max-width: 400px) {
      .podcast-panel .play-panel .play-wrapper {
        padding-left: 20px; } }
    @media (min-width: 640px) {
      .podcast-panel .play-panel .play-wrapper {
        padding-left: 32px; } }
    @media (max-width: 400px) {
      .podcast-panel .play-panel .play-wrapper {
        padding-right: 20px; } }
    @media (min-width: 640px) {
      .podcast-panel .play-panel .play-wrapper {
        padding-right: 32px; } }
    .podcast-panel .play-panel .play-wrapper .flex-t {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 16px; }
      .podcast-panel .play-panel .play-wrapper .flex-t .play-icon {
        display: flex;
        align-items: center;
        margin-right: 16px; }
        .podcast-panel .play-panel .play-wrapper .flex-t .play-icon .icon {
          font-size: 22px;
          padding: 0 10px; }
          .podcast-panel .play-panel .play-wrapper .flex-t .play-icon .icon:first-child {
            padding-left: 0; }
        .podcast-panel .play-panel .play-wrapper .flex-t .play-icon .anticon {
          color: #ffffff; }
      .podcast-panel .play-panel .play-wrapper .flex-t .play-title {
        font-weight: 500;
        font-family: "Noto Serif SC", serif;
        color: #ffffff;
        font-weight: 700;
        font-size: 4vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1 1; }
        @media (max-width: 400px) {
          .podcast-panel .play-panel .play-wrapper .flex-t .play-title {
            font-size: 16px; } }
        @media (min-width: 450px) {
          .podcast-panel .play-panel .play-wrapper .flex-t .play-title {
            font-size: 18px; } }
      @media screen and (max-width: 576px) {
        .podcast-panel .play-panel .play-wrapper .flex-t .play-title {
          order: -1;
          margin-bottom: 4px;
          flex-basis: 100%; } }
      .podcast-panel .play-panel .play-wrapper .flex-t .play-time {
        font-family: "DINPro-Bold";
        color: #ffffff;
        font-size: 18px;
        margin-left: 16px; }
    .podcast-panel .play-panel .play-wrapper .play-close {
      position: absolute;
      top: 8px;
      right: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
    .podcast-panel .play-panel .play-wrapper .play-progress {
      flex: 1 1 auto; }
      .podcast-panel .play-panel .play-wrapper .play-progress .play-bar {
        display: flex;
        align-items: center;
        width: 100%; }
        .podcast-panel .play-panel .play-wrapper .play-progress .play-bar .ant-slider-handle:focus {
          box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2); }
        .podcast-panel .play-panel .play-wrapper .play-progress .play-bar .ant-slider {
          flex: auto;
          margin: 0; }
          .podcast-panel .play-panel .play-wrapper .play-progress .play-bar .ant-slider .ant-slider-rail {
            background-color: #afafaf; }
          .podcast-panel .play-panel .play-wrapper .play-progress .play-bar .ant-slider .ant-slider-track {
            background-color: #ffffff; }
          .podcast-panel .play-panel .play-wrapper .play-progress .play-bar .ant-slider .ant-slider-handle {
            border: 4px solid #ffffff;
            background-color: #313233; }
  @media screen and (max-width: 576px) {
    .podcast-panel .play-panel .play-wrapper {
      margin-bottom: 45px; } }

.audio-detail-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: white;
  padding-top: 7vw;
  padding-bottom: 7vw;
  padding-left: 8vw;
  padding-right: 8vw; }
  @media (max-width: 428.57143px) {
    .audio-detail-page {
      padding-top: 30px; } }
  @media (min-width: 600px) {
    .audio-detail-page {
      padding-top: 42px; } }
  @media (max-width: 428.57143px) {
    .audio-detail-page {
      padding-bottom: 30px; } }
  @media (min-width: 600px) {
    .audio-detail-page {
      padding-bottom: 42px; } }
  @media (max-width: 400px) {
    .audio-detail-page {
      padding-left: 32px; } }
  @media (min-width: 775px) {
    .audio-detail-page {
      padding-left: 62px; } }
  @media (max-width: 400px) {
    .audio-detail-page {
      padding-right: 32px; } }
  @media (min-width: 775px) {
    .audio-detail-page {
      padding-right: 62px; } }
  .audio-detail-page .audio-content {
    display: flex;
    flex-direction: column;
    align-items: stretch; }
    .audio-detail-page .audio-content .sub-props {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap; }
      .audio-detail-page .audio-content .sub-props .text {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #999999;
        margin-right: 4px; }
      .audio-detail-page .audio-content .sub-props .qr-code {
        width: 18px; }
    .audio-detail-page .audio-content .header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      margin-top: 16px;
      padding-bottom: 3vw;
      border-bottom: 1px solid #f4f4f4; }
      @media (max-width: 466.66667px) {
        .audio-detail-page .audio-content .header {
          padding-bottom: 14px; } }
      @media (min-width: 800px) {
        .audio-detail-page .audio-content .header {
          padding-bottom: 24px; } }
      .audio-detail-page .audio-content .header .podcast-icon {
        width: 22px;
        height: 24px;
        margin-right: 15px;
        margin-top: 20px; }
      .audio-detail-page .audio-content .header .play-title {
        font-family: "Noto Serif SC", serif;
        font-weight: 700;
        font-size: 5vw;
        color: #3b3b3b;
        margin-right: 15px;
        flex: 1 1; }
        @media (max-width: 440px) {
          .audio-detail-page .audio-content .header .play-title {
            font-size: 22px; } }
        @media (min-width: 800px) {
          .audio-detail-page .audio-content .header .play-title {
            font-size: 40px; } }
      .audio-detail-page .audio-content .header .play-duration {
        font-family: "Noto Serif SC", serif;
        font-weight: 700;
        font-size: 26px;
        color: #3b3b3b;
        text-align: center; }
      .audio-detail-page .audio-content .header .play-btn {
        width: 36px;
        height: 36px;
        margin-left: 16px;
        cursor: pointer;
        color: #3945f9;
        font-size: 44px; }
    .audio-detail-page .audio-content .podcast-mini {
      display: flex;
      align-items: center;
      color: #666666;
      cursor: pointer;
      margin-top: 18px; }
      .audio-detail-page .audio-content .podcast-mini .icon {
        font-size: 18px;
        margin-right: 6px; }
      .audio-detail-page .audio-content .podcast-mini .podcast-mini-text {
        margin-top: 2px;
        color: #888888; }
    .audio-detail-page .audio-content .audio-rich-text {
      margin: 20px 0;
      margin-top: 2vw;
      padding-right: 1vw;
      text-align: justify; }
      @media (max-width: 500px) {
        .audio-detail-page .audio-content .audio-rich-text {
          margin-top: 10px; } }
      @media (min-width: 700px) {
        .audio-detail-page .audio-content .audio-rich-text {
          margin-top: 14px; } }
      @media (max-width: 400px) {
        .audio-detail-page .audio-content .audio-rich-text {
          padding-right: 4px; } }
      @media (min-width: 7000px) {
        .audio-detail-page .audio-content .audio-rich-text {
          padding-right: 70px; } }
      .audio-detail-page .audio-content .audio-rich-text p {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #313233;
        line-height: 28px;
        margin-top: 10px; }
      .audio-detail-page .audio-content .audio-rich-text li {
        list-style: decimal; }
      .audio-detail-page .audio-content .audio-rich-text h1,
      .audio-detail-page .audio-content .audio-rich-text h2 {
        margin-top: 36px; }
      .audio-detail-page .audio-content .audio-rich-text hr {
        border-top: #f4f4f4; }
  .audio-detail-page .comment-list {
    background: #ffffff; }

.page-health-sign .name {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin-top: 33px;
  margin-bottom: 10px;
  padding: 10px 22px; }

.page-health-sign .form-item {
  display: flex;
  flex-direction: column;
  padding: 10px 22px; }

.page-health-sign .form-title {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-top: 10px;
  margin-bottom: 10px; }

.page-health-sign .radio-group {
  display: flex;
  flex-direction: column; }

.page-health-sign .radio {
  margin: 5px 0; }

.page-health-sign .submit-btn {
  width: calc(100% - 44px);
  margin-left: 22px;
  margin-top: 30px; }

.promise-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%;
  position: relative; }
  .promise-page .pro {
    display: flex;
    flex-direction: column;
    margin: 0 15px; }
    .promise-page .pro .title {
      text-align: center;
      margin: 26px;
      font-size: 30px;
      font-weight: bold;
      color: #333333; }
  .promise-page .form {
    margin: 0 15px; }
    .promise-page .form .form-item {
      margin-top: 15px; }
      .promise-page .form .form-item .radio-group {
        display: flex;
        flex-direction: column; }
      .promise-page .form .form-item .radio {
        margin: 10px 0; }
      .promise-page .form .form-item .checkbox-group {
        display: flex;
        flex-direction: column; }
      .promise-page .form .form-item .checkbox {
        margin: 10px 0; }
      .promise-page .form .form-item .form-title {
        font-size: 16px;
        font-weight: 700;
        color: #000;
        margin-top: 10px; }
      .promise-page .form .form-item .input {
        margin-top: 10px; }
      .promise-page .form .form-item .image-box {
        border-radius: 3px;
        border: 1px solid #d9d9d9;
        height: 300px;
        margin-top: 10px;
        overflow: hidden; }
        .promise-page .form .form-item .image-box img {
          width: 100%; }
    .promise-page .form .submit-button {
      margin: 30px 0;
      width: 100%; }
  .promise-page .modal-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .promise-page .modal-center .form-title {
      font-size: 16px;
      font-weight: 700;
      color: #000;
      margin-top: 10px; }
  .promise-page .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: white; }
    .promise-page .modal .canvas {
      background: white; }
    .promise-page .modal .bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; }
      .promise-page .modal .bottom .bottom-bar {
        display: flex;
        flex-direction: row;
        height: 50px;
        margin-top: 15px; }
        .promise-page .modal .bottom .bottom-bar .item {
          width: 50%;
          height: 50px;
          color: white;
          background: #d9d9d9;
          font-size: 16px;
          text-align: center;
          line-height: 50px; }
        .promise-page .modal .bottom .bottom-bar .primary {
          background: #3945F9; }

