.comp-course-list-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 4px 8px 0 rgba(79, 125, 183, 0.08);
  transition: 0.3s all; }
  .comp-course-list-card:hover {
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
    .comp-course-list-card:hover .bks-shot-flex2 {
      opacity: 0.9; }
  .comp-course-list-card .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: white;
    cursor: pointer; }
    .comp-course-list-card .card .bks-shot-flex0 {
      position: relative;
      overflow: hidden; }
      .comp-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 {
        width: 100%;
        height: 0;
        padding-bottom: 55.7%; }
        .comp-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 {
          height: 100%;
          width: 100%;
          position: absolute;
          transform: scale(1);
          transition: 0.3s all; }
          .comp-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .comp-course-list-card .card .control-bar {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      cursor: pointer; }
      .comp-course-list-card .card .control-bar .course-card-top {
        padding: 18px;
        padding: 16px;
        padding: 2vw; }
        @media (max-width: 500px) {
          .comp-course-list-card .card .control-bar .course-card-top {
            padding: 10px; } }
        @media (min-width: 900px) {
          .comp-course-list-card .card .control-bar .course-card-top {
            padding: 18px; } }
        .comp-course-list-card .card .control-bar .course-card-top .title {
          display: block;
          font-weight: 500;
          font-size: 16px;
          font-size: 3vw;
          color: #3c3c3c;
          letter-spacing: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
          @media (max-width: 400px) {
            .comp-course-list-card .card .control-bar .course-card-top .title {
              font-size: 12px; } }
          @media (min-width: 533.33333px) {
            .comp-course-list-card .card .control-bar .course-card-top .title {
              font-size: 16px; } }
        .comp-course-list-card .card .control-bar .course-card-top .flex-m {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;
          margin-top: 1vw;
          height: 20px;
          height: 3vw; }
          @media (max-width: 600px) {
            .comp-course-list-card .card .control-bar .course-card-top .flex-m {
              margin-top: 6px; } }
          @media (min-width: 1200px) {
            .comp-course-list-card .card .control-bar .course-card-top .flex-m {
              margin-top: 12px; } }
          @media (max-width: 533.33333px) {
            .comp-course-list-card .card .control-bar .course-card-top .flex-m {
              height: 16px; } }
          @media (min-width: 666.66667px) {
            .comp-course-list-card .card .control-bar .course-card-top .flex-m {
              height: 20px; } }
          .comp-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
            font-size: 12px;
            font-size: 2vw; }
            @media (max-width: 500px) {
              .comp-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
                font-size: 10px; } }
            @media (min-width: 600px) {
              .comp-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
                font-size: 12px; } }
          .comp-course-list-card .card .control-bar .course-card-top .flex-m .live-status {
            font-size: 12px;
            color: #ff5252; }
          .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end; }
            .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
              width: 20px;
              width: 3vw;
              height: 20px;
              height: 3vw;
              margin-right: 7px;
              overflow: hidden; }
              @media (max-width: 533.33333px) {
                .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  width: 16px; } }
              @media (min-width: 666.66667px) {
                .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  width: 20px; } }
              @media (max-width: 533.33333px) {
                .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  height: 16px; } }
              @media (min-width: 666.66667px) {
                .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  height: 20px; } }
              .comp-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel .app-icon {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block; }
      .comp-course-list-card .card .control-bar .flex-b {
        height: 45px;
        height: 8vw;
        padding-left: 16px;
        padding-left: 2vw;
        padding-right: 16px;
        padding-right: 2vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #f4f4f4; }
        @media (max-width: 375px) {
          .comp-course-list-card .card .control-bar .flex-b {
            height: 30px; } }
        @media (min-width: 750px) {
          .comp-course-list-card .card .control-bar .flex-b {
            height: 60px; } }
        @media (max-width: 500px) {
          .comp-course-list-card .card .control-bar .flex-b {
            padding-left: 10px; } }
        @media (min-width: 900px) {
          .comp-course-list-card .card .control-bar .flex-b {
            padding-left: 18px; } }
        @media (max-width: 500px) {
          .comp-course-list-card .card .control-bar .flex-b {
            padding-right: 10px; } }
        @media (min-width: 900px) {
          .comp-course-list-card .card .control-bar .flex-b {
            padding-right: 18px; } }
        .comp-course-list-card .card .control-bar .flex-b .leaner-number {
          font-size: 12px;
          color: #939cae;
          letter-spacing: 0;
          flex: none; }
        .comp-course-list-card .card .control-bar .flex-b .isfree {
          color: #5ece6d;
          background-color: rgba(94, 206, 109, 0.1);
          font-size: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 2px 4px;
          letter-spacing: 0; }

.authors {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
  .authors .avatar {
    width: 20px;
    width: 3vw;
    height: 20px;
    height: 3vw;
    border-radius: 11px; }
    @media (max-width: 533.33333px) {
      .authors .avatar {
        width: 16px; } }
    @media (min-width: 733.33333px) {
      .authors .avatar {
        width: 22px; } }
    @media (max-width: 533.33333px) {
      .authors .avatar {
        height: 16px; } }
    @media (min-width: 733.33333px) {
      .authors .avatar {
        height: 22px; } }
  .authors label {
    font-weight: 500;
    font-size: 12px;
    color: #7d7d7d;
    letter-spacing: 0;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80px;
    max-width: 20vw; }
    @media (max-width: 400px) {
      .authors label {
        max-width: 80px; } }
    @media (min-width: 1000px) {
      .authors label {
        max-width: 200px; } }

.dropdown .ant-dropdown-menu-item {
  border-bottom: 1px solid #f5f5f5; }
  .dropdown .ant-dropdown-menu-item:last-child {
    border: none; }
