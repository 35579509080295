.list-filter {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 16px;
  padding: 20px;
  padding: 1vw; }
  @media (max-width: 400px) {
    .list-filter {
      padding: 4px; } }
  @media (min-width: 2000px) {
    .list-filter {
      padding: 20px; } }
  .list-filter .filter-row {
    margin-bottom: 16px; }
    .list-filter .filter-row .filter-label {
      color: #666666;
      padding-bottom: 10px; }
    .list-filter .filter-row .filter-option {
      display: flex;
      flex-wrap: wrap; }
      .list-filter .filter-row .filter-option .filter-item {
        margin-right: 10px;
        margin-right: 1vw;
        margin-bottom: 10px;
        margin-bottom: 1vw;
        background: transparent;
        font-size: 12px;
        border-radius: 2px;
        border: 1px solid #eeeeee;
        outline: none;
        color: #898989;
        height: auto;
        line-height: initial;
        padding: 4px 8px;
        display: flex;
        justify-content: center;
        align-items: center; }
        @media (max-width: 400px) {
          .list-filter .filter-row .filter-option .filter-item {
            margin-right: 4px; } }
        @media (min-width: 1000px) {
          .list-filter .filter-row .filter-option .filter-item {
            margin-right: 10px; } }
        @media (max-width: 400px) {
          .list-filter .filter-row .filter-option .filter-item {
            margin-bottom: 4px; } }
        @media (min-width: 1000px) {
          .list-filter .filter-row .filter-option .filter-item {
            margin-bottom: 10px; } }
        .list-filter .filter-row .filter-option .filter-item:not(:first-child)::before {
          display: none; }
      .list-filter .filter-row .filter-option .ant-radio-button-wrapper-checked {
        background: #f3f4ff;
        color: #3945f9;
        border: 1px solid #b5baff;
        box-shadow: none; }
    .list-filter .filter-row .software .filter-item {
      padding: 0;
      width: 40px;
      height: 40px; }
      .list-filter .filter-row .software .filter-item img {
        width: 20px;
        height: 20px; }

.all-course .list-body {
  width: 100%; }
  .all-course .list-body .free-option {
    align-self: center;
    height: 40px;
    display: flex;
    align-items: center; }
    .all-course .list-body .free-option .filter-option {
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      .all-course .list-body .free-option .filter-option .filter-item {
        background: transparent;
        font-size: 12px;
        border-radius: 2px;
        border: 1px solid #eeeeee;
        outline: none;
        color: #898989;
        height: auto;
        line-height: initial;
        padding: 4px 8px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .all-course .list-body .free-option .filter-option .filter-item:not(:first-child)::before {
          display: none; }
      .all-course .list-body .free-option .filter-option .ant-radio-button-wrapper-checked {
        background: #f3f4ff;
        color: #3945f9;
        border: 1px solid #b5baff;
        box-shadow: none; }
  .all-course .list-body .search-col {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .all-course .list-body .category-search {
    width: 100%;
    margin-bottom: 10px; }
    .all-course .list-body .category-search .sort-panel {
      display: flex;
      align-items: center; }
      .all-course .list-body .category-search .sort-panel .filter-btn {
        display: flex;
        align-items: center;
        margin-right: 20px;
        margin-left: 6px;
        cursor: pointer; }
        .all-course .list-body .category-search .sort-panel .filter-btn .filter-icon {
          margin-left: 6px;
          color: #bbbbbb; }
      .all-course .list-body .category-search .sort-panel .sort-btn-active {
        color: #3945F9; }
      .all-course .list-body .category-search .sort-panel .sort-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(255, 255, 255, 0);
        border: none;
        margin-right: 12px;
        border-radius: 2px; }
        .all-course .list-body .category-search .sort-panel .sort-btn:active {
          color: initial; }
        .all-course .list-body .category-search .sort-panel .sort-btn .sort-group {
          margin-left: 4px;
          display: flex;
          flex-flow: column;
          justify-content: center; }
          .all-course .list-body .category-search .sort-panel .sort-btn .sort-group .anticon-caret-up {
            margin-bottom: -4px; }
          .all-course .list-body .category-search .sort-panel .sort-btn .sort-group .icon-active {
            color: #3945F9; }
          .all-course .list-body .category-search .sort-panel .sort-btn .sort-group .arrow-icon {
            color: #bbbbbb; }
    .all-course .list-body .category-search .middle-blank {
      height: 10px; }
    .all-course .list-body .category-search .ant-menu {
      background: none;
      border: none;
      font-size: 14px;
      line-height: 26px; }
      .all-course .list-body .category-search .ant-menu .ant-menu-item {
        padding: 0;
        margin-right: 20px;
        border-bottom: none; }
      .all-course .list-body .category-search .ant-menu .ant-menu-submenu {
        margin: 0 20px;
        border-bottom: none; }
        .all-course .list-body .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
          padding: 0; }
          .all-course .list-body .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper {
            display: flex;
            align-items: center; }
            .all-course .list-body .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper .selector-arrow {
              margin-left: 10px;
              margin-right: 0;
              font-size: 8px;
              color: rgba(0, 0, 0, 0.2);
              transition: 0.5s all; }
      .all-course .list-body .category-search .ant-menu .ant-menu-submenu-active .selector-arrow {
        color: #3945F9 !important; }
    .all-course .list-body .category-search .search-bar {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 30px;
      height: 8vw;
      border-radius: 2px;
      background: white; }
      @media (max-width: 375px) {
        .all-course .list-body .category-search .search-bar {
          height: 30px; } }
      @media (min-width: 400px) {
        .all-course .list-body .category-search .search-bar {
          height: 32px; } }
      .all-course .list-body .category-search .search-bar input {
        height: 22px;
        font-size: 14px;
        font-size: 3vw;
        flex: 1;
        background: none;
        margin-left: 10px;
        outline: none;
        border: none; }
        @media (max-width: 400px) {
          .all-course .list-body .category-search .search-bar input {
            font-size: 12px; } }
        @media (min-width: 466.66667px) {
          .all-course .list-body .category-search .search-bar input {
            font-size: 14px; } }
        .all-course .list-body .category-search .search-bar input::-webkit-input-placeholder {
          font-size: 14px;
          font-size: 3vw;
          color: #939cae;
          letter-spacing: 0; }
          @media (max-width: 400px) {
            .all-course .list-body .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 12px; } }
          @media (min-width: 466.66667px) {
            .all-course .list-body .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 14px; } }
        .all-course .list-body .category-search .search-bar input:focus {
          outline: none;
          border: none; }
      .all-course .list-body .category-search .search-bar .search-icon {
        font-size: 14px;
        font-size: 4vw;
        color: #959eb0;
        margin: 0 10px;
        cursor: pointer; }
        @media (max-width: 350px) {
          .all-course .list-body .category-search .search-bar .search-icon {
            font-size: 14px; } }
        @media (min-width: 400px) {
          .all-course .list-body .category-search .search-bar .search-icon {
            font-size: 16px; } }
        .all-course .list-body .category-search .search-bar .search-icon:hover {
          color: #3945F9; }
      .all-course .list-body .category-search .search-bar .searching {
        color: #3945F9; }
