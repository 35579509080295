.podcast-panel .podcast-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding-bottom: 23px;
  padding-top: 36px; }
  .podcast-panel .podcast-info .content-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 280px; }
    .podcast-panel .podcast-info .content-body .cover {
      width: 280px;
      height: 280px;
      object-fit: cover;
      box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.12);
      border-radius: 8px; }
    .podcast-panel .podcast-info .content-body .cover-title {
      font-family: "Noto Serif SC", serif;
      font-size: 36px;
      font-weight: 700;
      color: #313233;
      letter-spacing: 6px;
      padding-top: 30px; }
    .podcast-panel .podcast-info .content-body .desc {
      padding: 16px 0;
      font-family: "Noto Serif SC", serif;
      font-size: 14px;
      font-weight: 400;
      color: #414242;
      text-align: center;
      line-height: 28px; }
    .podcast-panel .podcast-info .content-body .inside-other-sources {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      border-top: 1px solid #f4f4f4;
      border-bottom: 1px solid #f4f4f4;
      padding: 16px 0; }
      .podcast-panel .podcast-info .content-body .inside-other-sources img {
        width: 40px;
        height: 40px; }
    .podcast-panel .podcast-info .content-body .outside-other-sources {
      border-bottom: 1px solid #f4f4f4;
      width: 100%;
      padding: 10px 0; }
      .podcast-panel .podcast-info .content-body .outside-other-sources .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 0; }
        .podcast-panel .podcast-info .content-body .outside-other-sources .item .icon {
          width: 28px;
          height: 28px; }
        .podcast-panel .podcast-info .content-body .outside-other-sources .item div {
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #333333;
          line-height: 18px;
          flex: 1;
          margin-left: 18px;
          margin-right: 18px; }
        .podcast-panel .podcast-info .content-body .outside-other-sources .item .arrow {
          width: 8px;
          height: 14px; }
  .podcast-panel .podcast-info .authors {
    display: flex;
    flex-direction: column;
    width: 320px; }
    .podcast-panel .podcast-info .authors .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #f6f6f6;
      border-radius: 4px;
      width: 100%;
      padding: 19px 20px;
      margin-top: 16px; }
      .podcast-panel .podcast-info .authors .item .avatar {
        width: 44px;
        height: 44px;
        border-radius: 50%; }
      .podcast-panel .podcast-info .authors .item .flex-r {
        display: flex;
        flex-direction: column;
        padding-left: 16px; }
        .podcast-panel .podcast-info .authors .item .flex-r .name {
          font-weight: bold;
          font-size: 18px;
          color: #2d2d2d;
          line-height: 18px; }
        .podcast-panel .podcast-info .authors .item .flex-r .wechat {
          margin-top: 8px;
          font-size: 14px;
          color: #999999;
          line-height: 14px; }

.podcast-panel .flex-r .podcast-audio-list-container {
  background-color: white;
  padding-top: 7vw;
  padding-bottom: 7vw;
  padding-left: 8vw;
  padding-right: 8vw; }
  @media (max-width: 428.57143px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-top: 30px; } }
  @media (min-width: 600px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-top: 42px; } }
  @media (max-width: 428.57143px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-bottom: 30px; } }
  @media (min-width: 600px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-bottom: 42px; } }
  @media (max-width: 400px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-left: 32px; } }
  @media (min-width: 775px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-left: 62px; } }
  @media (max-width: 400px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-right: 32px; } }
  @media (min-width: 775px) {
    .podcast-panel .flex-r .podcast-audio-list-container {
      padding-right: 62px; } }
  .podcast-panel .flex-r .podcast-audio-list-container .tag-title {
    font-size: 26px;
    color: #3b3b3b;
    line-height: 26px;
    font-family: "Noto Serif SC", serif;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px; }
  .podcast-panel .flex-r .podcast-audio-list-container .highlight-tag {
    color: #3945F9; }
  .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: white;
    flex: 1; }
    .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 16px;
      margin-bottom: 20px;
      border-bottom: 1px solid #f4f4f4;
      width: 100%; }
      .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .sub-props {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%; }
        .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .sub-props .sub-props-left {
          display: flex;
          flex-wrap: wrap; }
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .sub-props .sub-props-left .text {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #999999;
            margin-right: 4px;
            display: flex;
            align-items: center; }
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .sub-props .sub-props-left .highlight-tag {
            color: #3945F9; }
      .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .podcast-mini {
        display: flex;
        align-items: center;
        margin-top: 12px;
        color: #666666;
        cursor: pointer; }
        .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .podcast-mini .icon {
          font-size: 18px;
          margin-right: 5px; }
        .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .podcast-mini .podcast-mini-text {
          margin-top: 2px;
          color: #888888; }
      .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .desc {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #848484;
        line-height: 28px; }
      .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: "Noto Serif SC", serif;
        font-weight: 700;
        font-size: 26px;
        font-size: 5vw;
        color: #3b3b3b;
        width: 100%;
        padding: 18px 0 16px; }
        @media (max-width: 440px) {
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item {
            font-size: 22px; } }
        @media (min-width: 520px) {
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item {
            font-size: 26px; } }
        .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item .title-props {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          width: 100%; }
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item .title-props .podcast-icon {
            width: 22px;
            height: 22px;
            margin-right: 15px;
            margin-top: 10px; }
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item .title-props .play-title {
            color: #3b3b3b;
            margin-right: 15px;
            flex: 1; }
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item .title-props .play-duration {
            text-align: center; }
          .podcast-panel .flex-r .podcast-audio-list-container .podcast-audio-list .play-item-panel .play-item .title-props .play-btn {
            width: 36px;
            height: 36px;
            margin-left: 16px;
            margin-top: 5px;
            cursor: pointer;
            color: #3945f9;
            font-size: 36px;
            display: flex; }

.podcast-panel .play-panel {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .podcast-panel .play-panel .play-wrapper {
    background-color: #313233;
    box-shadow: 0 10px 22px rgba(0, 0, 0, 0.42);
    border-radius: 10px;
    max-width: 1360px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px 32px;
    padding-left: 5vw;
    padding-right: 5vw;
    width: calc(100% - 20px);
    position: relative; }
    @media (max-width: 400px) {
      .podcast-panel .play-panel .play-wrapper {
        padding-left: 20px; } }
    @media (min-width: 640px) {
      .podcast-panel .play-panel .play-wrapper {
        padding-left: 32px; } }
    @media (max-width: 400px) {
      .podcast-panel .play-panel .play-wrapper {
        padding-right: 20px; } }
    @media (min-width: 640px) {
      .podcast-panel .play-panel .play-wrapper {
        padding-right: 32px; } }
    .podcast-panel .play-panel .play-wrapper .flex-t {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 16px; }
      .podcast-panel .play-panel .play-wrapper .flex-t .play-icon {
        display: flex;
        align-items: center;
        margin-right: 16px; }
        .podcast-panel .play-panel .play-wrapper .flex-t .play-icon .icon {
          font-size: 22px;
          padding: 0 10px; }
          .podcast-panel .play-panel .play-wrapper .flex-t .play-icon .icon:first-child {
            padding-left: 0; }
        .podcast-panel .play-panel .play-wrapper .flex-t .play-icon .anticon {
          color: #ffffff; }
      .podcast-panel .play-panel .play-wrapper .flex-t .play-title {
        font-weight: 500;
        font-family: "Noto Serif SC", serif;
        color: #ffffff;
        font-weight: 700;
        font-size: 4vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1 1; }
        @media (max-width: 400px) {
          .podcast-panel .play-panel .play-wrapper .flex-t .play-title {
            font-size: 16px; } }
        @media (min-width: 450px) {
          .podcast-panel .play-panel .play-wrapper .flex-t .play-title {
            font-size: 18px; } }
      @media screen and (max-width: 576px) {
        .podcast-panel .play-panel .play-wrapper .flex-t .play-title {
          order: -1;
          margin-bottom: 4px;
          flex-basis: 100%; } }
      .podcast-panel .play-panel .play-wrapper .flex-t .play-time {
        font-family: "DINPro-Bold";
        color: #ffffff;
        font-size: 18px;
        margin-left: 16px; }
    .podcast-panel .play-panel .play-wrapper .play-close {
      position: absolute;
      top: 8px;
      right: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
    .podcast-panel .play-panel .play-wrapper .play-progress {
      flex: 1 1 auto; }
      .podcast-panel .play-panel .play-wrapper .play-progress .play-bar {
        display: flex;
        align-items: center;
        width: 100%; }
        .podcast-panel .play-panel .play-wrapper .play-progress .play-bar .ant-slider-handle:focus {
          box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2); }
        .podcast-panel .play-panel .play-wrapper .play-progress .play-bar .ant-slider {
          flex: auto;
          margin: 0; }
          .podcast-panel .play-panel .play-wrapper .play-progress .play-bar .ant-slider .ant-slider-rail {
            background-color: #afafaf; }
          .podcast-panel .play-panel .play-wrapper .play-progress .play-bar .ant-slider .ant-slider-track {
            background-color: #ffffff; }
          .podcast-panel .play-panel .play-wrapper .play-progress .play-bar .ant-slider .ant-slider-handle {
            border: 4px solid #ffffff;
            background-color: #313233; }
  @media screen and (max-width: 576px) {
    .podcast-panel .play-panel .play-wrapper {
      margin-bottom: 45px; } }
