.course-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%;
  position: relative;
  scroll-behavior: smooth; }
  .course-detail #lesson-edit-player-container video {
    background: black; }
  .course-detail #lesson-edit-player-container .prism-progress-played {
    background: #3945F9; }
  .course-detail .course-nav {
    width: 100%;
    background: #ffffff;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08);
    flex: none; }
    .course-detail .course-nav .course-nav-inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      height: 54px;
      justify-content: space-between; }
      .course-detail .course-nav .course-nav-inner .flex-l {
        display: flex;
        flex-direction: row;
        justify-content: flex-start; }
      .course-detail .course-nav .course-nav-inner .course-purchase {
        display: flex;
        align-items: center; }
        .course-detail .course-nav .course-nav-inner .course-purchase .course-price {
          color: #fc4d4d;
          margin-right: 20px;
          font-size: 18px; }
        .course-detail .course-nav .course-nav-inner .course-purchase .course-purchase-btn {
          padding: 8px 50px;
          background: #fc4d4d;
          border-radius: 100px;
          color: #ffffff;
          cursor: pointer; }
  .course-detail .card-marginbottom {
    margin-bottom: 16px;
    margin-bottom: 2vw; }
    @media (max-width: 400px) {
      .course-detail .card-marginbottom {
        margin-bottom: 8px; } }
    @media (min-width: 800px) {
      .course-detail .card-marginbottom {
        margin-bottom: 16px; } }
    .course-detail .card-marginbottom .flex-base {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .course-detail .card-marginbottom .flex-base .collection-icon {
        font-size: 18px;
        cursor: pointer; }
  .course-detail .course-panel {
    background: #ffffff;
    border: 1px solid #f2f2f2;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08);
    margin-bottom: 16px;
    margin-bottom: 2vw; }
    @media (max-width: 400px) {
      .course-detail .course-panel {
        margin-bottom: 8px; } }
    @media (min-width: 800px) {
      .course-detail .course-panel {
        margin-bottom: 16px; } }
    .course-detail .course-panel .course-player-container {
      position: relative; }
      .course-detail .course-panel .course-player-container .course-video {
        max-width: 100%;
        padding-bottom: 56.24%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative; }
        .course-detail .course-panel .course-player-container .course-video .video-play {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center; }
          .course-detail .course-panel .course-player-container .course-video .video-play img {
            width: 70px;
            cursor: pointer;
            opacity: 0.5;
            transition: 0.2s all; }
            .course-detail .course-panel .course-player-container .course-video .video-play img:hover {
              opacity: 0.8; }
      .course-detail .course-panel .course-player-container .continue-alert {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1001; }
        .course-detail .course-panel .course-player-container .continue-alert .title {
          font-size: 15px;
          color: white; }
        .course-detail .course-panel .course-player-container .continue-alert .flex-b {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 20px; }
          .course-detail .course-panel .course-player-container .continue-alert .flex-b button {
            margin: 0 10px;
            box-sizing: content-box; }
          .course-detail .course-panel .course-player-container .continue-alert .flex-b .replay-btn {
            border: 1px solid #ffffff; }
    .course-detail .course-panel .course-lesson-title {
      background: #ffffff;
      padding: 16px;
      padding: 4vw;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media (max-width: 400px) {
        .course-detail .course-panel .course-lesson-title {
          padding: 16px; } }
      @media (min-width: 500px) {
        .course-detail .course-panel .course-lesson-title {
          padding: 20px; } }
      .course-detail .course-panel .course-lesson-title .lesson-title {
        font-size: 16px;
        font-size: 3vw;
        color: #232323;
        text-align: justify; }
        @media (max-width: 466.66667px) {
          .course-detail .course-panel .course-lesson-title .lesson-title {
            font-size: 14px; } }
        @media (min-width: 533.33333px) {
          .course-detail .course-panel .course-lesson-title .lesson-title {
            font-size: 16px; } }
      .course-detail .course-panel .course-lesson-title .course-lesson-file {
        border: #3e4af9 1px solid;
        color: #3e4af9;
        background: #f3f4ff;
        padding: 9px 20px;
        border-radius: 100px;
        cursor: pointer;
        display: flex;
        align-items: center; }
        .course-detail .course-panel .course-lesson-title .course-lesson-file img {
          margin-right: 6px; }
    .course-detail .course-panel .ant-tabs-nav {
      padding-left: 40px; }
      .course-detail .course-panel .ant-tabs-nav .ant-tabs-tab {
        font-size: 16px;
        padding: 16px 0; }
      .course-detail .course-panel .ant-tabs-nav .ant-tabs-ink-bar {
        height: 3px; }
    .course-detail .course-panel .ant-tabs-tabpane {
      padding: 30px;
      padding: 3vw;
      padding-top: 6px;
      padding-top: 1vw; }
      @media (max-width: 333.33333px) {
        .course-detail .course-panel .ant-tabs-tabpane {
          padding: 10px; } }
      @media (min-width: 1000px) {
        .course-detail .course-panel .ant-tabs-tabpane {
          padding: 30px; } }
      @media (max-width: 0px) {
        .course-detail .course-panel .ant-tabs-tabpane {
          padding-top: 0px; } }
      @media (min-width: 600px) {
        .course-detail .course-panel .ant-tabs-tabpane {
          padding-top: 6px; } }
  .course-detail .course-brief {
    display: flex;
    align-items: center;
    font-size: 12px; }
    .course-detail .course-brief .course-status {
      color: #519a31;
      margin-right: 8px; }
    .course-detail .course-brief .course-process {
      color: #232323; }
      .course-detail .course-brief .course-process span {
        color: #3e4af9;
        font-size: 14px;
        font-weight: bold; }
  .course-detail .course-tag {
    margin-top: 10px;
    font-size: 12px;
    color: #a6aebc;
    display: flex;
    flex-wrap: wrap; }
    .course-detail .course-tag span {
      margin-right: 8px; }
  .course-detail .course-teacher-panel {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .course-detail .course-teacher-panel .course-teacher {
      display: flex;
      align-items: center; }
      .course-detail .course-teacher-panel .course-teacher .course-teacher-avatar {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: #f3f4ff;
        margin-right: 12px; }
      .course-detail .course-teacher-panel .course-teacher .course-teacher-name {
        color: #232323;
        font-size: 16px;
        font-weight: bold; }
    .course-detail .course-teacher-panel .teacher-subscribe {
      padding: 2px 7px;
      display: flex;
      align-items: center;
      background: #404040;
      color: #ffffff;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer; }
  .course-detail .lesson-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px; }
    .course-detail .lesson-list li .lesson-title {
      color: #404040;
      font-size: 14px;
      cursor: pointer;
      display: flex; }
    .course-detail .lesson-list li .lesson-selected {
      color: #3945F9; }
    .course-detail .lesson-list li .lesson-status {
      flex: none;
      margin-left: 10px; }
      .course-detail .lesson-list li .lesson-status .lesson-free {
        padding: 0px 4px;
        font-size: 12px;
        color: #4dc24c;
        border: 1px #4dc24c solid;
        background: #e0f6e0;
        border-radius: 4px; }
      .course-detail .lesson-list li .lesson-status .lesson-charge {
        color: #b6bcc8; }
  .course-detail .related-list li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 14px;
    cursor: pointer; }
    .course-detail .related-list li .related-cover {
      max-width: 120px;
      width: 100%;
      margin-right: 14px; }
      .course-detail .related-list li .related-cover .related-cover-inner {
        width: 100%;
        padding-bottom: 56.25%;
        background: #aaaaaa; }
    .course-detail .related-list li .related-title {
      color: #404040; }

.course-group-panel {
  background: #ffffff;
  padding: 10px 12px 12px 12px;
  margin-bottom: 12px; }
  .course-group-panel .course-group-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px; }
  .course-group-panel .course-group {
    display: flex;
    align-items: center; }
    .course-group-panel .course-group .course-group-card {
      width: 200px;
      transition: 0.3s all;
      margin-right: 10px; }
      .course-group-panel .course-group .course-group-card .course-group-card-inner {
        position: relative;
        padding-bottom: 40%;
        border-radius: 2px;
        overflow: hidden; }
        .course-group-panel .course-group .course-group-card .course-group-card-inner img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .course-group-panel .course-group .course-group-card .course-group-card-title {
        font-size: 12px;
        padding-top: 4px;
        color: #666666; }

.live-course-panel {
  margin-bottom: 16px; }
  .live-course-panel .live-course-cover {
    position: relative;
    max-width: 100%;
    padding-bottom: 40%; }
    .live-course-panel .live-course-cover img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute; }
  .live-course-panel .live-course-info {
    background: #ffffff;
    height: 100%;
    border: 1px solid #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .live-course-panel .live-course-info .top-padding {
      padding: 20px;
      flex: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .live-course-panel .live-course-info .top-padding .course-info-detail .title {
        font-size: 20px;
        font-weight: bold;
        color: #3c3c3c; }
      .live-course-panel .live-course-info .top-padding .course-info-detail .date {
        font-size: 14px;
        font-weight: 500;
        margin-top: 4px; }
      .live-course-panel .live-course-info .top-padding .course-info-detail .description {
        color: #b2b2b2;
        font-size: 14px;
        margin-top: 10px; }
      .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 12px;
        margin-top: 1vw;
        height: 20px;
        height: 3vw; }
        @media (max-width: 600px) {
          .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m {
            margin-top: 6px; } }
        @media (min-width: 1200px) {
          .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m {
            margin-top: 12px; } }
        @media (max-width: 533.33333px) {
          .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m {
            height: 16px; } }
        @media (min-width: 666.66667px) {
          .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m {
            height: 20px; } }
        .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .level-tag {
          font-size: 12px;
          font-size: 2vw; }
          @media (max-width: 500px) {
            .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .level-tag {
              font-size: 10px; } }
          @media (min-width: 600px) {
            .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .level-tag {
              font-size: 12px; } }
        .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .live-status {
          font-size: 12px;
          color: #ff5252; }
        .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end; }
          .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps .app-icon-panel {
            width: 20px;
            width: 3vw;
            height: 20px;
            height: 3vw;
            margin-right: 7px;
            overflow: hidden; }
            @media (max-width: 533.33333px) {
              .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps .app-icon-panel {
                width: 16px; } }
            @media (min-width: 666.66667px) {
              .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps .app-icon-panel {
                width: 20px; } }
            @media (max-width: 533.33333px) {
              .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps .app-icon-panel {
                height: 16px; } }
            @media (min-width: 666.66667px) {
              .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps .app-icon-panel {
                height: 20px; } }
            .live-course-panel .live-course-info .top-padding .course-info-detail .flex-m .apps .app-icon-panel .app-icon {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block; }
    .live-course-panel .live-course-info .price-detail {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      background: #3945f9;
      cursor: pointer; }
      .live-course-panel .live-course-info .price-detail .price {
        color: #ffffff;
        font-size: 30px;
        font-weight: bold;
        display: flex;
        align-items: flex-end; }
        .live-course-panel .live-course-info .price-detail .price .price-symbol {
          font-size: 20px;
          padding-bottom: 3px; }
        .live-course-panel .live-course-info .price-detail .price .original-price {
          color: rgba(255, 255, 255, 0.4);
          font-size: 18px;
          text-decoration: line-through;
          padding-bottom: 3px; }
      .live-course-panel .live-course-info .price-detail .apply-btn {
        color: #ffffff;
        font-size: 18px;
        font-weight: bold; }

@media screen and (max-width: 576px) {
  .course-purchase {
    background: #ffffff;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    padding: 10px;
    justify-content: flex-end;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); } }
