.comp-dot-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  .comp-dot-loading .box {
    display: inline-block;
    float: left;
    position: relative;
    transition: all .2s ease; }
  .comp-dot-loading .loader10 {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    -webkit-animation: loader10m 2s ease-in-out infinite;
    animation: loader10m 2s ease-in-out infinite; }
    .comp-dot-loading .loader10:before {
      content: "";
      position: absolute;
      top: 0px;
      left: -15px;
      height: 8px;
      width: 8px;
      border-radius: 8px;
      -webkit-animation: loader10g 2s ease-in-out infinite;
      animation: loader10g 2s ease-in-out infinite; }
    .comp-dot-loading .loader10:after {
      content: "";
      position: absolute;
      top: 0px;
      left: 15px;
      height: 8px;
      width: 8px;
      border-radius: 10px;
      -webkit-animation: loader10d 2s ease-in-out infinite;
      animation: loader10d 2s ease-in-out infinite; }

@-webkit-keyframes loader10g {
  0% {
    background-color: rgba(47, 84, 235, 0.2); }
  25% {
    background-color: #2f54eb; }
  50% {
    background-color: rgba(47, 84, 235, 0.2); }
  75% {
    background-color: rgba(47, 84, 235, 0.2); }
  100% {
    background-color: rgba(47, 84, 235, 0.2); } }

@keyframes loader10g {
  0% {
    background-color: rgba(47, 84, 235, 0.2); }
  25% {
    background-color: #2f54eb; }
  50% {
    background-color: rgba(47, 84, 235, 0.2); }
  75% {
    background-color: rgba(47, 84, 235, 0.2); }
  100% {
    background-color: rgba(47, 84, 235, 0.2); } }

@-webkit-keyframes loader10m {
  0% {
    background-color: rgba(47, 84, 235, 0.2); }
  25% {
    background-color: rgba(47, 84, 235, 0.2); }
  50% {
    background-color: #2f54eb; }
  75% {
    background-color: rgba(47, 84, 235, 0.2); }
  100% {
    background-color: rgba(47, 84, 235, 0.2); } }

@keyframes loader10m {
  0% {
    background-color: rgba(47, 84, 235, 0.2); }
  25% {
    background-color: rgba(47, 84, 235, 0.2); }
  50% {
    background-color: #2f54eb; }
  75% {
    background-color: rgba(47, 84, 235, 0.2); }
  100% {
    background-color: rgba(47, 84, 235, 0.2); } }

@-webkit-keyframes loader10d {
  0% {
    background-color: rgba(47, 84, 235, 0.2); }
  25% {
    background-color: rgba(47, 84, 235, 0.2); }
  50% {
    background-color: rgba(47, 84, 235, 0.2); }
  75% {
    background-color: #2f54eb; }
  100% {
    background-color: rgba(47, 84, 235, 0.2); } }

@keyframes loader10d {
  0% {
    background-color: rgba(47, 84, 235, 0.2); }
  25% {
    background-color: rgba(47, 84, 235, 0.2); }
  50% {
    background-color: rgba(47, 84, 235, 0.2); }
  75% {
    background-color: #2f54eb; }
  100% {
    background-color: rgba(47, 84, 235, 0.2); } }
