.comp-gantt-chart {
  margin-top: 50px;
  margin-bottom: 20px; }
  .comp-gantt-chart .table-row .row-titles {
    display: flex;
    flex-direction: column; }
    .comp-gantt-chart .table-row .row-titles .table-title {
      font-size: 14px;
      font-weight: bold;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      background: #f5f5f5; }
    .comp-gantt-chart .table-row .row-titles .title {
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      padding-right: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      border-left: 1px solid rgba(0, 0, 0, 0.05);
      background: #f5f5f5; }
      .comp-gantt-chart .table-row .row-titles .title span {
        text-align: right; }
  .comp-gantt-chart .table-row .table-grids {
    display: flex;
    flex-direction: column;
    overflow-x: scroll; }
    .comp-gantt-chart .table-row .table-grids .table-item-grid {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      position: relative;
      height: 50px; }
      .comp-gantt-chart .table-row .table-grids .table-item-grid .grid-box {
        background: white;
        height: 100%;
        flex: 1;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative; }
        .comp-gantt-chart .table-row .table-grids .table-item-grid .grid-box .grid-box-line {
          position: absolute;
          right: 0;
          height: 100%;
          width: 1px;
          background: rgba(0, 0, 0, 0.05); }
      .comp-gantt-chart .table-row .table-grids .table-item-grid .full-bar {
        position: absolute;
        height: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 2px;
        transition: 1s all ease;
        cursor: pointer; }
        .comp-gantt-chart .table-row .table-grids .table-item-grid .full-bar .start-date {
          color: white;
          position: absolute;
          left: 6px;
          font-size: 12px;
          width: 100px;
          text-align: start;
          opacity: 0;
          transition: 1s all ease 0.5s; }
        .comp-gantt-chart .table-row .table-grids .table-item-grid .full-bar .end-date {
          right: 6px;
          left: auto;
          text-align: end; }
      .comp-gantt-chart .table-row .table-grids .table-item-grid .full-bar-animate .start-date {
        opacity: 1; }
      .comp-gantt-chart .table-row .table-grids .table-item-grid .table-header-item {
        font-size: 14px;
        background: none;
        padding-left: 6px;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        border-top: 1px solid rgba(0, 0, 0, 0.05); }
