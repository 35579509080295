.comp-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
  flex: none; }
  .comp-footer .footer-bg-item {
    position: absolute; }
    .comp-footer .footer-bg-item:nth-child(1) {
      right: 20px;
      top: -100px; }
    .comp-footer .footer-bg-item:nth-child(2) {
      left: 50%;
      bottom: -100px;
      margin-left: 300px; }
    .comp-footer .footer-bg-item:nth-child(3) {
      right: 50%;
      bottom: -60px;
      margin-right: 630px; }
  .comp-footer .container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    max-width: 1360px;
    margin: 0 20px; }
    .comp-footer .container .items {
      display: flex;
      margin-bottom: 30px;
      flex-wrap: wrap; }
      .comp-footer .container .items .nav-group {
        display: flex;
        flex-direction: column;
        margin-right: 40px; }
        .comp-footer .container .items .nav-group .group-title {
          font-size: 16px;
          color: #666666;
          font-weight: 500;
          margin-top: 30px;
          margin-bottom: 10px; }
        .comp-footer .container .items .nav-group .links {
          display: flex;
          flex-direction: column;
          margin-top: 10px; }
          .comp-footer .container .items .nav-group .links a {
            margin-bottom: 8px;
            color: #999999; }
      .comp-footer .container .items .link {
        cursor: pointer;
        font-size: 16px;
        color: #494949;
        margin-right: 22px; }
        .comp-footer .container .items .link:hover {
          color: #3945F9; }
    .comp-footer .container .cooperation-links {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      font-size: 14px;
      color: #A6AEBC;
      margin-right: 10px; }
      .comp-footer .container .cooperation-links a {
        color: #A6AEBC;
        margin-right: 10px; }
        .comp-footer .container .cooperation-links a:hover {
          color: #3945F9; }
    .comp-footer .container .copyright {
      font-size: 14px;
      color: #A6AEBC;
      margin-top: 8px; }
      .comp-footer .container .copyright a {
        color: #A6AEBC; }
