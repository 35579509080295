.page-health-sign .name {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin-top: 33px;
  margin-bottom: 10px;
  padding: 10px 22px; }

.page-health-sign .form-item {
  display: flex;
  flex-direction: column;
  padding: 10px 22px; }

.page-health-sign .form-title {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-top: 10px;
  margin-bottom: 10px; }

.page-health-sign .radio-group {
  display: flex;
  flex-direction: column; }

.page-health-sign .radio {
  margin: 5px 0; }

.page-health-sign .submit-btn {
  width: calc(100% - 44px);
  margin-left: 22px;
  margin-top: 30px; }
