.skeleton-course-list-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: 0.3s all; }
  .skeleton-course-list-card .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    cursor: pointer; }
    .skeleton-course-list-card .card .bks-shot-flex0 {
      position: relative;
      overflow: hidden;
      margin: 16px 16px 0 16px;
      border-radius: 2px; }
      .skeleton-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 {
        width: 100%;
        height: 0;
        padding-bottom: 40%; }
        .skeleton-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 {
          height: 100%;
          width: 100%;
          position: absolute;
          transform: scale(1);
          transition: 0.3s all;
          animation: skeletonAni 1.4s ease infinite; }
          .skeleton-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .skeleton-course-list-card .card .control-bar {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      cursor: pointer; }
      .skeleton-course-list-card .card .control-bar .course-card-top {
        padding: 18px;
        padding: 16px;
        padding: 2vw; }
        @media (max-width: 500px) {
          .skeleton-course-list-card .card .control-bar .course-card-top {
            padding: 10px; } }
        @media (min-width: 900px) {
          .skeleton-course-list-card .card .control-bar .course-card-top {
            padding: 18px; } }
        .skeleton-course-list-card .card .control-bar .course-card-top .title {
          display: block;
          font-weight: 500;
          font-size: 16px;
          font-size: 3vw;
          color: #3c3c3c;
          letter-spacing: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 20px;
          width: 100%;
          animation: skeletonAni 1.4s ease infinite; }
          @media (max-width: 400px) {
            .skeleton-course-list-card .card .control-bar .course-card-top .title {
              font-size: 12px; } }
          @media (min-width: 533.33333px) {
            .skeleton-course-list-card .card .control-bar .course-card-top .title {
              font-size: 16px; } }
        .skeleton-course-list-card .card .control-bar .course-card-top .flex-m {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-top: 12px;
          margin-top: 1vw;
          height: 20px;
          height: 3vw; }
          @media (max-width: 600px) {
            .skeleton-course-list-card .card .control-bar .course-card-top .flex-m {
              margin-top: 6px; } }
          @media (min-width: 1200px) {
            .skeleton-course-list-card .card .control-bar .course-card-top .flex-m {
              margin-top: 12px; } }
          @media (max-width: 533.33333px) {
            .skeleton-course-list-card .card .control-bar .course-card-top .flex-m {
              height: 16px; } }
          @media (min-width: 666.66667px) {
            .skeleton-course-list-card .card .control-bar .course-card-top .flex-m {
              height: 20px; } }
          .skeleton-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
            font-size: 12px;
            font-size: 2vw; }
            @media (max-width: 500px) {
              .skeleton-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
                font-size: 10px; } }
            @media (min-width: 600px) {
              .skeleton-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
                font-size: 12px; } }
          .skeleton-course-list-card .card .control-bar .course-card-top .flex-m .live-status {
            font-size: 12px;
            color: #ff5252; }
          .skeleton-course-list-card .card .control-bar .course-card-top .flex-m .apps {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            height: 20px;
            background: rgba(195, 195, 195, 0.3);
            animation: skeletonAni 1.4s ease infinite;
            width: 35%; }
        .skeleton-course-list-card .card .control-bar .course-card-top .flex-m-b {
          margin-top: 32px;
          display: flex;
          align-items: center; }
          .skeleton-course-list-card .card .control-bar .course-card-top .flex-m-b .course-duration {
            font-size: 12px;
            color: #7d7d7d;
            flex: 1;
            height: 20px;
            background: rgba(195, 195, 195, 0.3);
            animation: skeletonAni 1.4s ease infinite; }
      .skeleton-course-list-card .card .control-bar .flex-b {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #f4f4f4;
        font-size: 12px;
        flex: 1;
        height: 20px;
        background: rgba(195, 195, 195, 0.3); }

.authors {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
  .authors .avatar {
    width: 20px;
    width: 3vw;
    height: 20px;
    height: 3vw;
    border-radius: 11px;
    background: rgba(195, 195, 195, 0.3);
    animation: skeletonAni 1.4s ease infinite; }
    @media (max-width: 533.33333px) {
      .authors .avatar {
        width: 16px; } }
    @media (min-width: 733.33333px) {
      .authors .avatar {
        width: 22px; } }
    @media (max-width: 533.33333px) {
      .authors .avatar {
        height: 16px; } }
    @media (min-width: 733.33333px) {
      .authors .avatar {
        height: 22px; } }
  .authors label {
    font-weight: 500;
    font-size: 12px;
    color: #7d7d7d;
    letter-spacing: 0;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.dropdown .ant-dropdown-menu-item {
  border-bottom: 1px solid #f5f5f5; }
  .dropdown .ant-dropdown-menu-item:last-child {
    border: none; }
