.comp-message-list-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 0 0 0; }
  .comp-message-list-item .avatar {
    width: 48px;
    height: 48px; }
  .comp-message-list-item .comment-textarea {
    margin-top: 20px; }
  .comp-message-list-item .message-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 30px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(216, 216, 216, 0.51); }
    .comp-message-list-item .message-body .message-title {
      font-size: 14px;
      color: #232323;
      text-align: justify; }
      .comp-message-list-item .message-body .message-title .nickname, .comp-message-list-item .message-body .message-title .title {
        font-weight: bold;
        color: #232323; }
    .comp-message-list-item .message-body .message-content {
      font-size: 14px;
      color: #232323;
      text-align: justify;
      line-height: 14px;
      padding-top: 14px; }
    .comp-message-list-item .message-body .flex-b {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-top: 14px; }
      .comp-message-list-item .message-body .flex-b .date {
        font-size: 12px;
        color: #939CAE;
        text-align: justify;
        line-height: 12px; }
      .comp-message-list-item .message-body .flex-b .control-options {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-left: 77px; }
        .comp-message-list-item .message-body .flex-b .control-options .comment-likes {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start; }
          .comp-message-list-item .message-body .flex-b .control-options .comment-likes .like-icon {
            font-size: 14px;
            color: #939CAE;
            cursor: pointer; }
          .comp-message-list-item .message-body .flex-b .control-options .comment-likes .count {
            margin-left: 4px;
            font-size: 12px;
            color: #939CAE;
            text-align: justify;
            line-height: 12px; }
        .comp-message-list-item .message-body .flex-b .control-options .reply-btn {
          margin-left: 24px;
          font-size: 12px;
          color: #939CAE;
          text-align: justify;
          line-height: 12px;
          cursor: pointer; }
