.tool-panel {
  max-width: 1360px;
  width: calc(100% - 20px);
  margin: 30px auto; }
  .tool-panel .tool-main-title {
    font-size: 14px;
    color: #666666;
    margin: 50px 0 16px; }
  .tool-panel .tool-card {
    background: #ffffff;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0);
    cursor: pointer;
    transition: 0.3s all; }
    .tool-panel .tool-card:hover {
      box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
      .tool-panel .tool-card:hover .tool-card-bg {
        transform: scale(13); }
    .tool-panel .tool-card .tool-cover {
      width: 100%;
      height: 0;
      padding-bottom: 30%;
      position: relative;
      border-bottom: 1px solid #f2f2f2;
      overflow: hidden; }
      .tool-panel .tool-card .tool-cover .tool-card-bg {
        position: absolute;
        left: -100px;
        top: -100px;
        width: 50px;
        height: 50px;
        border-radius: 30px;
        background: #ffffff;
        transition: 0.8s all cubic-bezier(0.01, 0.54, 0.07, 1.01); }
      .tool-panel .tool-card .tool-cover .tool-cover-inner {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 14px;
        padding: 2vw; }
        @media (max-width: 500px) {
          .tool-panel .tool-card .tool-cover .tool-cover-inner {
            padding: 10px; } }
        @media (min-width: 800px) {
          .tool-panel .tool-card .tool-cover .tool-cover-inner {
            padding: 16px; } }
        .tool-panel .tool-card .tool-cover .tool-cover-inner img {
          height: 100%;
          object-fit: cover; }
    .tool-panel .tool-card .tool-text-panel {
      padding-left: 14px;
      padding-left: 2vw;
      padding-right: 14px;
      padding-right: 2vw;
      padding-top: 10px;
      padding-top: 2vw;
      padding-bottom: 10px;
      padding-bottom: 2vw; }
      @media (max-width: 500px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-left: 10px; } }
      @media (min-width: 800px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-left: 16px; } }
      @media (max-width: 500px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-right: 10px; } }
      @media (min-width: 800px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-right: 16px; } }
      @media (max-width: 400px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-top: 8px; } }
      @media (min-width: 500px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-top: 10px; } }
      @media (max-width: 400px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-bottom: 8px; } }
      @media (min-width: 500px) {
        .tool-panel .tool-card .tool-text-panel {
          padding-bottom: 10px; } }
      .tool-panel .tool-card .tool-text-panel .tool-title {
        font-weight: 500;
        font-size: 18px;
        font-size: 3vw;
        color: #999999; }
        @media (max-width: 400px) {
          .tool-panel .tool-card .tool-text-panel .tool-title {
            font-size: 12px; } }
        @media (min-width: 466.66667px) {
          .tool-panel .tool-card .tool-text-panel .tool-title {
            font-size: 14px; } }
      .tool-panel .tool-card .tool-text-panel .tool-des {
        margin-top: 6px;
        font-size: 12px;
        font-size: 2vw; }
        @media (max-width: 500px) {
          .tool-panel .tool-card .tool-text-panel .tool-des {
            font-size: 10px; } }
        @media (min-width: 600px) {
          .tool-panel .tool-card .tool-text-panel .tool-des {
            font-size: 12px; } }
