.comp-card-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 12px; }
  .comp-card-header .comp-dotted {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 9px;
    margin-top: 9px;
    margin-top: 2vw; }
    @media (max-width: 400px) {
      .comp-card-header .comp-dotted {
        margin-top: 8px; } }
    @media (min-width: 450px) {
      .comp-card-header .comp-dotted {
        margin-top: 9px; } }
    .comp-card-header .comp-dotted .dotted1 {
      width: 10px;
      height: 6px;
      background: #3945F9; }
    .comp-card-header .comp-dotted .dotted2 {
      width: 10px;
      height: 6px;
      background: #01d5f0; }
  .comp-card-header .title {
    font-size: 16px;
    font-size: 16px;
    font-size: 3vw;
    color: #3c3c3c;
    font-weight: bold;
    letter-spacing: 0;
    margin-left: 12px;
    margin-bottom: 0;
    flex: 1;
    display: -webkit-box; }
    @media (max-width: 466.66667px) {
      .comp-card-header .title {
        font-size: 14px; } }
    @media (min-width: 533.33333px) {
      .comp-card-header .title {
        font-size: 16px; } }
    .comp-card-header .title h1 {
      margin-left: 0;
      font-size: 16px;
      font-size: 16px;
      font-size: 3vw;
      color: #3c3c3c;
      font-weight: bold;
      letter-spacing: 0;
      margin-bottom: 0; }
      @media (max-width: 466.66667px) {
        .comp-card-header .title h1 {
          font-size: 14px; } }
      @media (min-width: 533.33333px) {
        .comp-card-header .title h1 {
          font-size: 16px; } }
