.shot-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f9fc;
  width: 100%;
  position: relative; }
  .shot-page .content {
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    padding: 3vw;
    position: relative; }
    @media (max-width: 0px) {
      .shot-page .content {
        padding: 0px; } }
    @media (min-width: 666.66667px) {
      .shot-page .content {
        padding: 20px; } }
    .shot-page .content .side-affix {
      position: absolute;
      right: -50px;
      top: 100px; }
      .shot-page .content .side-affix .side-control-panel .side-control-btn-outter {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-bottom: 25px; }
        .shot-page .content .side-affix .side-control-panel .side-control-btn-outter .side-control-btn {
          width: 50px;
          height: 50px;
          margin-bottom: 8px;
          border-radius: 50%;
          border: 1px solid #e8e8e8;
          background-color: #ffffff;
          color: #666666;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer; }
          .shot-page .content .side-affix .side-control-panel .side-control-btn-outter .side-control-btn .icon {
            font-size: 18px; }
        .shot-page .content .side-affix .side-control-panel .side-control-btn-outter .side-control-text {
          font-size: 12px;
          color: #a4a4a4; }
    .shot-page .content .shot-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border-radius: 4px;
      overflow: hidden; }
      .shot-page .content .shot-container .shot {
        width: 100%; }
      .shot-page .content .shot-container .control-bar {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 17px;
        padding: 3vw;
        background: white; }
        @media (max-width: 400px) {
          .shot-page .content .shot-container .control-bar {
            padding: 12px; } }
        @media (min-width: 566.66667px) {
          .shot-page .content .shot-container .control-bar {
            padding: 17px; } }
        .shot-page .content .shot-container .control-bar .avatar {
          width: 50px;
          height: 50px;
          flex: none; }
        .shot-page .content .shot-container .control-bar .flex {
          display: flex;
          flex-flow: wrap;
          align-items: center;
          justify-content: space-between;
          flex: auto; }
          .shot-page .content .shot-container .control-bar .flex .flex-m {
            margin: 0 15px; }
            .shot-page .content .shot-container .control-bar .flex .flex-m .title {
              font-size: 24px;
              font-size: 24px;
              font-size: 3vw;
              font-weight: 500;
              color: #222b45; }
              @media (max-width: 466.66667px) {
                .shot-page .content .shot-container .control-bar .flex .flex-m .title {
                  font-size: 14px; } }
              @media (min-width: 800px) {
                .shot-page .content .shot-container .control-bar .flex .flex-m .title {
                  font-size: 24px; } }
            .shot-page .content .shot-container .control-bar .flex .flex-m .flex-m-b {
              font-size: 14px;
              font-size: 3vw;
              color: #8f9bb3; }
              @media (max-width: 400px) {
                .shot-page .content .shot-container .control-bar .flex .flex-m .flex-m-b {
                  font-size: 12px; } }
              @media (min-width: 466.66667px) {
                .shot-page .content .shot-container .control-bar .flex .flex-m .flex-m-b {
                  font-size: 14px; } }
              .shot-page .content .shot-container .control-bar .flex .flex-m .flex-m-b span {
                color: #222b45; }
          .shot-page .content .shot-container .control-bar .flex .flex-r {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end; }
            .shot-page .content .shot-container .control-bar .flex .flex-r .ant-anchor-ink {
              display: none; }
            .shot-page .content .shot-container .control-bar .flex .flex-r .ant-anchor-link {
              padding-left: 12px; }
              .shot-page .content .shot-container .control-bar .flex .flex-r .ant-anchor-link .control-btn {
                margin-left: 0; }
            .shot-page .content .shot-container .control-bar .flex .flex-r .control-btn {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              border: 1px solid #e4e9f2;
              font-size: 14px;
              color: #9395b0;
              text-align: center;
              line-height: 14px;
              height: 40px;
              border-radius: 4px;
              padding: 14px 14px;
              cursor: pointer;
              margin-left: 10px; }
              .shot-page .content .shot-container .control-bar .flex .flex-r .control-btn .collection-icon {
                font-size: 20px; }
    .shot-page .content .comment-list {
      background: #ffffff;
      padding: 30px;
      padding: 5vw; }
      @media (max-width: 400px) {
        .shot-page .content .comment-list {
          padding: 20px; } }
      @media (min-width: 600px) {
        .shot-page .content .comment-list {
          padding: 30px; } }

.course-list-modal {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start; }
  .course-list-modal .modal-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start; }
    .course-list-modal .modal-content .result-count {
      font-size: 15px;
      color: #c8d1df;
      margin-top: 20px;
      text-align: center; }
    .course-list-modal .modal-content .confirm-btn {
      width: 200px;
      margin-top: 20px;
      align-self: center; }
    .course-list-modal .modal-content .list {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      height: 500px;
      overflow-y: scroll;
      margin-top: 20px; }
      .course-list-modal .modal-content .list .list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid #dfe8f6;
        cursor: pointer; }
        .course-list-modal .modal-content .list .list-item .checkbox {
          font-size: 14px;
          margin: 0 15px 0 5px; }
        .course-list-modal .modal-content .list .list-item .preview {
          width: 80px;
          height: 60px; }
        .course-list-modal .modal-content .list .list-item .flex-r {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex: 1;
          margin-left: 15px; }
          .course-list-modal .modal-content .list .list-item .flex-r .title {
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1; }
          .course-list-modal .modal-content .list .list-item .flex-r .teacher {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end; }
            .course-list-modal .modal-content .list .list-item .flex-r .teacher .avatar {
              width: 40px;
              height: 40px;
              margin-left: 10px; }
