.ant-drawer-content-wrapper .ant-drawer-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0; }

.ant-drawer-content-wrapper .nav-items {
  display: flex;
  flex-direction: column; }
  .ant-drawer-content-wrapper .nav-items a {
    font-size: 14px;
    margin-bottom: 12px;
    color: #ffffff; }
    .ant-drawer-content-wrapper .nav-items a:last-child {
      margin-bottom: 0; }

.bks-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 77px;
  background: #3945F9;
  width: 100%;
  flex: none;
  overflow: hidden;
  position: relative; }
  .bks-nav .index-header {
    background: #3945F9;
    width: 100%;
    height: 312px;
    height: 300px;
    height: 50vw;
    position: absolute;
    top: 0;
    z-index: 0;
    overflow: hidden; }
    @media (max-width: 380px) {
      .bks-nav .index-header {
        height: 190px; } }
    @media (min-width: 624px) {
      .bks-nav .index-header {
        height: 312px; } }
    .bks-nav .index-header img {
      position: absolute; }
      .bks-nav .index-header img:nth-child(1) {
        right: -70px;
        top: -150px;
        opacity: 0.3; }
      .bks-nav .index-header img:nth-child(2) {
        left: 50%;
        bottom: 0px;
        margin-left: -790px; }
      .bks-nav .index-header img:nth-child(3) {
        left: 50%;
        top: -100px;
        margin-left: 300px; }
      .bks-nav .index-header img:nth-child(4) {
        left: 50%;
        top: 0;
        margin-left: -100px;
        animation: bgani 15s linear infinite;
        opacity: 0.2; }
      .bks-nav .index-header img:nth-child(5) {
        left: -20px;
        top: -80px;
        opacity: 0.3; }

@keyframes bgani {
  0% {
    transform: translate(-100px, -200px); }
  100% {
    transform: translate(200px, 200px) rotate(360deg); } }
  .bks-nav .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: 1360px;
    margin: 0 20px;
    z-index: 1; }
    .bks-nav .container .logo {
      width: 57px; }
    .bks-nav .container .nav-items a {
      color: rgba(255, 255, 255, 0.7);
      font-size: 30px;
      font-size: 3vw;
      margin-left: 30px;
      margin-left: 5vw; }
      @media (max-width: 466.66667px) {
        .bks-nav .container .nav-items a {
          font-size: 14px; } }
      @media (min-width: 533.33333px) {
        .bks-nav .container .nav-items a {
          font-size: 16px; } }
      @media (max-width: 400px) {
        .bks-nav .container .nav-items a {
          margin-left: 20px; } }
      @media (min-width: 1000px) {
        .bks-nav .container .nav-items a {
          margin-left: 50px; } }
      .bks-nav .container .nav-items a:hover {
        color: white; }
    .bks-nav .container .flex-r {
      display: flex;
      align-items: center; }
      .bks-nav .container .flex-r .notice-box {
        cursor: pointer;
        margin-right: 20px; }
        .bks-nav .container .flex-r .notice-box .notice-icon {
          font-size: 25px;
          color: rgba(255, 255, 255, 0.71); }
      .bks-nav .container .flex-r .action-btn {
        background: rgba(255, 255, 255, 0.1);
        border: none;
        font-size: 12px;
        color: #ffffff;
        letter-spacing: 0;
        margin-left: 20px;
        margin-left: 2vw;
        padding: 4px 8px;
        height: auto;
        border-radius: 0; }
        @media (max-width: 500px) {
          .bks-nav .container .flex-r .action-btn {
            margin-left: 10px; } }
        @media (min-width: 1300px) {
          .bks-nav .container .flex-r .action-btn {
            margin-left: 26px; } }
        .bks-nav .container .flex-r .action-btn:hover {
          background: rgba(255, 255, 255, 0.2); }

.message-overlay-box {
  width: 90%;
  max-width: 280px;
  min-height: 200px; }
  .message-overlay-box .message-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: scroll;
    cursor: pointer;
    max-height: 250px; }
    .message-overlay-box .message-list .list-empty {
      min-height: 200px; }
      .message-overlay-box .message-list .list-empty .tip {
        font-size: 14px; }
    .message-overlay-box .message-list .message-item {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      border-bottom: 1px solid rgba(216, 216, 216, 0.5);
      flex: none; }
      .message-overlay-box .message-list .message-item .message-title {
        flex-wrap: wrap;
        padding: 8px;
        font-size: 12px;
        color: #232323;
        text-align: justify; }
        .message-overlay-box .message-list .message-item .message-title .nickname, .message-overlay-box .message-list .message-item .message-title .title {
          font-weight: bold;
          color: #232323;
          display: inline; }
