.comp-all-course-list-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 4px 8px 0 rgba(79, 125, 183, 0.08);
  transition: 0.3s all; }
  .comp-all-course-list-card:hover {
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
    .comp-all-course-list-card:hover .bks-shot-flex2 {
      opacity: 0.9; }
  .comp-all-course-list-card .course-time {
    font-weight: bold;
    color: #ffffff;
    border-radius: 2px;
    position: absolute;
    right: 4px;
    top: 4px;
    background: rgba(0, 4, 59, 0.7);
    padding: 4px 8px;
    z-index: 4;
    font-size: 12px; }
  .comp-all-course-list-card .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: white;
    cursor: pointer; }
    .comp-all-course-list-card .card .bks-shot-flex0 {
      position: relative;
      overflow: hidden;
      margin: 16px 16px 0 16px;
      border-radius: 2px; }
      .comp-all-course-list-card .card .bks-shot-flex0 .course-status {
        position: absolute;
        left: 4px;
        top: 4px;
        background: rgba(255, 147, 0, 0.9);
        color: #ffffff;
        border-radius: 2px;
        padding: 4px 8px;
        z-index: 3;
        font-size: 12px; }
      .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 {
        width: 100%;
        height: 0;
        padding-bottom: 40%; }
        .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 {
          height: 100%;
          width: 100%;
          position: absolute;
          transform: scale(1);
          transition: 0.3s all; }
          .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 .cover-shadow {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(57, 69, 249, 0.1);
            z-index: 1; }
            .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 .cover-shadow .title {
              display: block;
              font-weight: 500;
              font-size: 16px;
              font-size: 3vw;
              color: #ffffff;
              display: flex;
              align-items: center;
              letter-spacing: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              z-index: 2; }
              @media (max-width: 400px) {
                .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 .cover-shadow .title {
                  font-size: 12px; } }
              @media (min-width: 533.33333px) {
                .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 .cover-shadow .title {
                  font-size: 16px; } }
          .comp-all-course-list-card .card .bks-shot-flex0 .bks-shot-flex1 .bks-shot-flex2 img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .comp-all-course-list-card .card .control-bar {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      cursor: pointer; }
      .comp-all-course-list-card .card .control-bar .course-card-top {
        padding: 18px;
        padding: 16px;
        padding: 2vw; }
        @media (max-width: 500px) {
          .comp-all-course-list-card .card .control-bar .course-card-top {
            padding: 10px; } }
        @media (min-width: 900px) {
          .comp-all-course-list-card .card .control-bar .course-card-top {
            padding: 18px; } }
        .comp-all-course-list-card .card .control-bar .course-card-top .title {
          display: block;
          font-weight: 500;
          font-size: 16px;
          font-size: 3vw;
          color: #3c3c3c;
          letter-spacing: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
          @media (max-width: 400px) {
            .comp-all-course-list-card .card .control-bar .course-card-top .title {
              font-size: 12px; } }
          @media (min-width: 533.33333px) {
            .comp-all-course-list-card .card .control-bar .course-card-top .title {
              font-size: 16px; } }
        .comp-all-course-list-card .card .control-bar .course-card-top .flex-m {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-top: 12px;
          margin-top: 1vw;
          height: 20px;
          height: 3vw; }
          @media (max-width: 600px) {
            .comp-all-course-list-card .card .control-bar .course-card-top .flex-m {
              margin-top: 6px; } }
          @media (min-width: 1200px) {
            .comp-all-course-list-card .card .control-bar .course-card-top .flex-m {
              margin-top: 12px; } }
          @media (max-width: 533.33333px) {
            .comp-all-course-list-card .card .control-bar .course-card-top .flex-m {
              height: 16px; } }
          @media (min-width: 666.66667px) {
            .comp-all-course-list-card .card .control-bar .course-card-top .flex-m {
              height: 20px; } }
          .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
            font-size: 12px;
            font-size: 2vw; }
            @media (max-width: 500px) {
              .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
                font-size: 10px; } }
            @media (min-width: 600px) {
              .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .level-tag {
                font-size: 12px; } }
          .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .live-status {
            font-size: 12px;
            color: #ff5252; }
          .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end; }
            .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
              width: 20px;
              width: 3vw;
              height: 20px;
              height: 3vw;
              margin-right: 7px;
              overflow: hidden; }
              @media (max-width: 533.33333px) {
                .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  width: 16px; } }
              @media (min-width: 666.66667px) {
                .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  width: 20px; } }
              @media (max-width: 533.33333px) {
                .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  height: 16px; } }
              @media (min-width: 666.66667px) {
                .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel {
                  height: 20px; } }
              .comp-all-course-list-card .card .control-bar .course-card-top .flex-m .apps .app-icon-panel .app-icon {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block; }
        .comp-all-course-list-card .card .control-bar .course-card-top .flex-m-b {
          margin-top: 32px;
          display: flex;
          align-items: center; }
          .comp-all-course-list-card .card .control-bar .course-card-top .flex-m-b .course-duration {
            font-size: 12px;
            color: #7d7d7d; }
      .comp-all-course-list-card .card .control-bar .flex-b {
        height: 45px;
        height: 8vw;
        padding-left: 16px;
        padding-left: 2vw;
        padding-right: 16px;
        padding-right: 2vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #f4f4f4;
        font-size: 12px; }
        @media (max-width: 375px) {
          .comp-all-course-list-card .card .control-bar .flex-b {
            height: 30px; } }
        @media (min-width: 750px) {
          .comp-all-course-list-card .card .control-bar .flex-b {
            height: 60px; } }
        @media (max-width: 500px) {
          .comp-all-course-list-card .card .control-bar .flex-b {
            padding-left: 10px; } }
        @media (min-width: 900px) {
          .comp-all-course-list-card .card .control-bar .flex-b {
            padding-left: 18px; } }
        @media (max-width: 500px) {
          .comp-all-course-list-card .card .control-bar .flex-b {
            padding-right: 10px; } }
        @media (min-width: 900px) {
          .comp-all-course-list-card .card .control-bar .flex-b {
            padding-right: 18px; } }
        .comp-all-course-list-card .card .control-bar .flex-b .price {
          color: #3c3c3c;
          font-size: 16px;
          font-weight: bold;
          font-size: 16px;
          font-size: 3vw; }
          @media (max-width: 466.66667px) {
            .comp-all-course-list-card .card .control-bar .flex-b .price {
              font-size: 14px; } }
          @media (min-width: 533.33333px) {
            .comp-all-course-list-card .card .control-bar .flex-b .price {
              font-size: 16px; } }
        .comp-all-course-list-card .card .control-bar .flex-b .count {
          color: #7d7d7d;
          font-size: 14px;
          font-size: 3vw; }
          @media (max-width: 400px) {
            .comp-all-course-list-card .card .control-bar .flex-b .count {
              font-size: 12px; } }
          @media (min-width: 466.66667px) {
            .comp-all-course-list-card .card .control-bar .flex-b .count {
              font-size: 14px; } }
        .comp-all-course-list-card .card .control-bar .flex-b .learner-number {
          font-size: 12px;
          color: #939cae;
          letter-spacing: 0;
          flex: none; }
        .comp-all-course-list-card .card .control-bar .flex-b .isfree {
          color: #5ece6d;
          background-color: rgba(94, 206, 109, 0.1);
          font-size: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 2px 4px;
          letter-spacing: 0; }
        .comp-all-course-list-card .card .control-bar .flex-b .course-status-count {
          font-size: 12px; }

.authors {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }
  .authors .avatar {
    width: 20px;
    width: 3vw;
    height: 20px;
    height: 3vw;
    border-radius: 11px; }
    @media (max-width: 533.33333px) {
      .authors .avatar {
        width: 16px; } }
    @media (min-width: 733.33333px) {
      .authors .avatar {
        width: 22px; } }
    @media (max-width: 533.33333px) {
      .authors .avatar {
        height: 16px; } }
    @media (min-width: 733.33333px) {
      .authors .avatar {
        height: 22px; } }
  .authors label {
    font-weight: 500;
    font-size: 12px;
    color: #7d7d7d;
    letter-spacing: 0;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80px;
    max-width: 20vw; }
    @media (max-width: 400px) {
      .authors label {
        max-width: 80px; } }
    @media (min-width: 1000px) {
      .authors label {
        max-width: 200px; } }

.dropdown .ant-dropdown-menu-item {
  border-bottom: 1px solid #f5f5f5; }
  .dropdown .ant-dropdown-menu-item:last-child {
    border: none; }
