.account-course {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 28px; }
  .account-course .account-course-cover {
    width: 100px;
    width: 20vw; }
    @media (max-width: 500px) {
      .account-course .account-course-cover {
        width: 100px; } }
    @media (min-width: 800px) {
      .account-course .account-course-cover {
        width: 160px; } }
    .account-course .account-course-cover .account-course-cover-inner {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      background: #aaaaaa; }
      .account-course .account-course-cover .account-course-cover-inner img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .account-course .account-course-txt {
    width: 620px;
    width: 50vw;
    padding-left: 14px;
    padding-left: 3vw;
    font-size: 14px;
    font-size: 3vw; }
    @media (max-width: 360px) {
      .account-course .account-course-txt {
        width: 180px; } }
    @media (min-width: 1240px) {
      .account-course .account-course-txt {
        width: 620px; } }
    @media (max-width: 400px) {
      .account-course .account-course-txt {
        padding-left: 12px; } }
    @media (min-width: 666.66667px) {
      .account-course .account-course-txt {
        padding-left: 20px; } }
    @media (max-width: 400px) {
      .account-course .account-course-txt {
        font-size: 12px; } }
    @media (min-width: 466.66667px) {
      .account-course .account-course-txt {
        font-size: 14px; } }
    .account-course .account-course-txt .account-course-title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-size: 3vw;
      margin-bottom: 14px;
      margin-bottom: 1vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      @media (max-width: 466.66667px) {
        .account-course .account-course-txt .account-course-title {
          font-size: 14px; } }
      @media (min-width: 533.33333px) {
        .account-course .account-course-txt .account-course-title {
          font-size: 16px; } }
      @media (max-width: 400px) {
        .account-course .account-course-txt .account-course-title {
          margin-bottom: 4px; } }
      @media (min-width: 2000px) {
        .account-course .account-course-txt .account-course-title {
          margin-bottom: 20px; } }
    .account-course .account-course-txt .account-course-info {
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 4px; }
      .account-course .account-course-txt .account-course-info span {
        color: #3945f9; }
    .account-course .account-course-txt .account-course-time {
      color: #939CAE; }
  .account-course .account-course-btn-panel {
    display: flex;
    justify-content: flex-end;
    flex: auto; }
    .account-course .account-course-btn-panel .account-course-btn {
      background: #3E4AF9;
      border: none;
      padding: 9px 20px;
      border-radius: 50px;
      color: #ffffff;
      cursor: pointer;
      display: flex;
      align-items: center; }
