.bks-work-textarea {
  display: flex; }
  .bks-work-textarea .avatar {
    width: 32px;
    width: 8vw;
    height: 32px;
    height: 8vw;
    margin-right: 30px;
    margin-right: 3vw;
    flex: none; }
    @media (max-width: 400px) {
      .bks-work-textarea .avatar {
        width: 32px; } }
    @media (min-width: 600px) {
      .bks-work-textarea .avatar {
        width: 48px; } }
    @media (max-width: 400px) {
      .bks-work-textarea .avatar {
        height: 32px; } }
    @media (min-width: 600px) {
      .bks-work-textarea .avatar {
        height: 48px; } }
    @media (max-width: 333.33333px) {
      .bks-work-textarea .avatar {
        margin-right: 10px; } }
    @media (min-width: 1000px) {
      .bks-work-textarea .avatar {
        margin-right: 30px; } }
  .bks-work-textarea .comment-textarea {
    width: 100%; }
    .bks-work-textarea .comment-textarea textarea {
      width: 100%;
      background: #f3f5f9;
      border-radius: 4px;
      border: none;
      padding: 12px 18px;
      font-size: 14px;
      font-size: 3vw; }
      @media (max-width: 433.33333px) {
        .bks-work-textarea .comment-textarea textarea {
          font-size: 13px; } }
      @media (min-width: 466.66667px) {
        .bks-work-textarea .comment-textarea textarea {
          font-size: 14px; } }
      .bks-work-textarea .comment-textarea textarea::placeholder {
        color: #b6bcc8; }
    .bks-work-textarea .comment-textarea .textarea-tool {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #939cae;
      margin-top: 10px;
      font-size: 12px; }
      .bks-work-textarea .comment-textarea .textarea-tool .iconxiaolian {
        margin-right: 4px;
        font-size: 14px; }
      .bks-work-textarea .comment-textarea .textarea-tool button {
        background: #3e4af9;
        border-radius: 100px;
        border: none;
        padding: 9px 36px;
        font-size: 14px;
        color: #ffffff;
        margin-left: 20px;
        outline: none; }

.bks-work-comment {
  display: flex;
  flex-direction: row;
  padding-top: 30px; }
  .bks-work-comment:first-of-type {
    border-top: 1px solid #ebebeb !important; }
  .bks-work-comment:last-child .flex-r {
    border-bottom: none; }
  .bks-work-comment .avatar {
    width: 32px;
    width: 8vw;
    height: 32px;
    height: 8vw;
    margin-right: 30px;
    margin-right: 3vw;
    flex: none; }
    @media (max-width: 400px) {
      .bks-work-comment .avatar {
        width: 32px; } }
    @media (min-width: 600px) {
      .bks-work-comment .avatar {
        width: 48px; } }
    @media (max-width: 400px) {
      .bks-work-comment .avatar {
        height: 32px; } }
    @media (min-width: 600px) {
      .bks-work-comment .avatar {
        height: 48px; } }
    @media (max-width: 333.33333px) {
      .bks-work-comment .avatar {
        margin-right: 10px; } }
    @media (min-width: 1000px) {
      .bks-work-comment .avatar {
        margin-right: 30px; } }
  .bks-work-comment .flex-r {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-bottom: 1px solid #ebebeb; }
    .bks-work-comment .flex-r .nickname {
      font-weight: 500;
      font-size: 18px;
      font-size: 3vw;
      color: #222b45; }
      @media (max-width: 466.66667px) {
        .bks-work-comment .flex-r .nickname {
          font-size: 14px; } }
      @media (min-width: 600px) {
        .bks-work-comment .flex-r .nickname {
          font-size: 18px; } }
    .bks-work-comment .flex-r .com-cnt {
      font-size: 15px;
      font-size: 3vw;
      color: #222b45;
      text-align: justify;
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      @media (max-width: 433.33333px) {
        .bks-work-comment .flex-r .com-cnt {
          font-size: 13px; } }
      @media (min-width: 500px) {
        .bks-work-comment .flex-r .com-cnt {
          font-size: 15px; } }
      .bks-work-comment .flex-r .com-cnt .top {
        border: 1px solid #3e4af9;
        color: #3e4af9;
        padding: 1px 3px;
        border-radius: 2px;
        font-size: 10px;
        margin-right: 6px; }
    .bks-work-comment .flex-r .bottom-panel {
      font-size: 12px;
      color: #8f9bb3;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-top: 15px;
      padding-bottom: 15px; }
      .bks-work-comment .flex-r .bottom-panel .like {
        margin-right: 22px; }
        .bks-work-comment .flex-r .bottom-panel .like .iconlike2 {
          font-size: 16px;
          margin-right: 2px; }
      .bks-work-comment .flex-r .bottom-panel .publish-time {
        margin-right: 22px; }
      .bks-work-comment .flex-r .bottom-panel .reply {
        cursor: pointer; }
