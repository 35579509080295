.page-shot-post {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%;
  position: relative; }
  .page-shot-post .post-content {
    max-width: 1360px;
    margin: 0 10px; }
  .page-shot-post .control-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    background: white; }
    .page-shot-post .control-bar .control-bar-content {
      flex: 1;
      max-width: 1360px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
      .page-shot-post .control-bar .control-bar-content .flex-l {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start; }
      .page-shot-post .control-bar .control-bar-content .flex-r {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        flex: 1; }
  .page-shot-post .no-found {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
    align-items: center; }
    .page-shot-post .no-found p {
      font-size: 20px; }
    .page-shot-post .no-found button {
      margin-top: 30px; }
  .page-shot-post h3 {
    color: #8F9BB3; }
  .page-shot-post .input {
    height: 50px;
    font-size: 18px;
    color: #404040; }
  .page-shot-post .textarea {
    font-size: 14px;
    padding: 10px;
    min-height: 120px; }
  .page-shot-post .form-item {
    display: flex;
    flex-direction: column;
    padding: 10px; }
    .page-shot-post .form-item .uploader {
      width: 100%; }
      .page-shot-post .form-item .uploader .ant-upload-btn {
        padding-bottom: 0;
        padding-top: 0; }
      .page-shot-post .form-item .uploader .uploader-container {
        width: 100%;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative; }
        .page-shot-post .form-item .uploader .uploader-container .progress {
          position: absolute; }
        .page-shot-post .form-item .uploader .uploader-container .tip {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%; }
        .page-shot-post .form-item .uploader .uploader-container .shot-preview {
          width: 100%;
          min-height: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .page-shot-post .form-item .uploader .uploader-container .shot-preview img {
            width: 100%;
            height: 100%; }
    .page-shot-post .form-item .category-selector {
      height: 50px;
      font-size: 18px; }
      .page-shot-post .form-item .category-selector input {
        height: 100%; }
  .page-shot-post .editor {
    background: white;
    padding: 15px;
    height: 800px; }
  .page-shot-post .ban-comment {
    margin-top: 15px; }
