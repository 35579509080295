@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+SC:200,300,400,500,600,700,900&display=swap&subset=chinese-simplified");
@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700&display=swap");
@font-face {
  font-family: DINPro-Bold;
  src: url("/font/DINPro-Bold.otf"); }

#root {
  width: 100%;
  height: 100%; }

#root > div:nth-child(2) {
  min-height: 100%;
  margin-bottom: -174px;
  padding-bottom: 200px; }

html,
body {
  height: 100%; }

body {
  background-color: #f7f9fc !important;
  font-family: -apple-system, SF UI Text, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;
  font-family: "Noto Sans SC", sans-serif; }

ul, li, ol {
  margin: 0;
  padding: 0;
  list-style: none; }

.podcast-modal .qr-code {
  width: 100%; }

.podcast-modal .qr-text {
  color: #888888;
  text-align: center;
  margin-top: 10px; }

/* 富文本全局样式 */
.rich-text {
  font-size: 15px;
  color: #222b45;
  line-height: 25px;
  background: #ffffff;
  display: flex;
  flex-flow: column;
  padding: 30px; }
  .rich-text p {
    margin: 0; }
  .rich-text img {
    max-width: calc(100% + 80px);
    margin: 0 -40px; }

.ant-menu-item {
  margin-bottom: 0px !important; }
  .ant-menu-item:last-child {
    margin-bottom: 8px !important; }

.wrapper {
  max-width: 1380px;
  margin: 0px auto;
  padding: 0px 10px;
  width: 100%;
  height: 100%; }

.flex-group {
  display: flex;
  align-items: center; }

.xs-show,
.md-show,
.lg-show {
  display: none !important; }

@media screen and (max-width: 992px) {
  .lg-hidden,
  .xs-show,
  .md-hidden {
    display: none !important; }
  .lg-show {
    display: block !important; } }

@media screen and (max-width: 768px) {
  .md-hidden,
  .xs-show {
    display: none !important; }
  .md-show {
    display: block !important; } }

@media screen and (max-width: 576px) {
  .xs-hidden {
    display: none !important; }
  .xs-show {
    display: block !important; } }

@keyframes skeletonAni {
  0% {
    background: linear-gradient(100deg, #e5e6e9 25%, #eeeeee 37%, #e5e6e9 63%);
    background-size: 400% 100%;
    background-position: 100% 50%; }
  100% {
    background: linear-gradient(100deg, #e5e6e9 25%, #eeeeee 37%, #e5e6e9 63%);
    background-size: 400% 100%;
    background-position: 0 50%; } }
