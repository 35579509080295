.app-course-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%;
  position: relative; }
  .app-course-list .course-nav {
    width: 100%;
    background: #ffffff;
    box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
    .app-course-list .course-nav .course-nav-inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      justify-content: flex-start;
      flex-direction: row;
      padding: 10px 0;
      min-height: 50px; }
      .app-course-list .course-nav .course-nav-inner .flex-l {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap; }
        .app-course-list .course-nav .course-nav-inner .flex-l .app-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          background: rgba(243, 243, 243, 0.08);
          border-radius: 4px;
          font-weight: 500;
          font-size: 14px;
          color: #a2a2a2;
          padding: 5px 11px;
          margin: 6px 22px 6px 0px;
          cursor: pointer; }
          .app-course-list .course-nav .course-nav-inner .flex-l .app-item img {
            width: 18px;
            height: 18px;
            margin-right: 8px; }
        .app-course-list .course-nav .course-nav-inner .flex-l .selected {
          background: rgba(0, 16, 255, 0.08);
          border-radius: 4px; }
  .app-course-list .index-content {
    max-width: 1360px;
    margin: 0 10px;
    min-height: 300px;
    margin-top: 18px;
    margin-top: 2vw;
    width: calc(100% - 20px); }
    @media (max-width: 500px) {
      .app-course-list .index-content {
        margin-top: 10px; } }
    @media (min-width: 900px) {
      .app-course-list .index-content {
        margin-top: 18px; } }
    .app-course-list .index-content .card-marginbottom {
      margin-bottom: 16px;
      margin-bottom: 2vw; }
      @media (max-width: 400px) {
        .app-course-list .index-content .card-marginbottom {
          margin-bottom: 8px; } }
      @media (min-width: 800px) {
        .app-course-list .index-content .card-marginbottom {
          margin-bottom: 16px; } }
  .app-course-list .list-footer {
    width: 100%;
    margin-top: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 56px; }
    .app-course-list .list-footer .bottom-loading {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 30px;
      padding: 0 15px;
      border-radius: 15px;
      background: #3945F9;
      min-width: 100px;
      color: #ffffff; }
      .app-course-list .list-footer .bottom-loading .title {
        font-size: 12px; }
    .app-course-list .list-footer .loaded-all {
      background: none;
      color: #a6aebc; }
