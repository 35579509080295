.comp-list-empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px; }
  .comp-list-empty .icon {
    font-size: 80px; }
  .comp-list-empty .tip {
    color: #a6aebc;
    font-size: 16px; }
