.comp-card {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
  .comp-card .card-header {
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #edeff0; }
  .comp-card .card-body {
    padding: 20px;
    padding: 16px;
    padding: 4vw; }
    @media (max-width: 400px) {
      .comp-card .card-body {
        padding: 16px; } }
    @media (min-width: 500px) {
      .comp-card .card-body {
        padding: 20px; } }
