.audio-detail-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: white;
  padding-top: 7vw;
  padding-bottom: 7vw;
  padding-left: 8vw;
  padding-right: 8vw; }
  @media (max-width: 428.57143px) {
    .audio-detail-page {
      padding-top: 30px; } }
  @media (min-width: 600px) {
    .audio-detail-page {
      padding-top: 42px; } }
  @media (max-width: 428.57143px) {
    .audio-detail-page {
      padding-bottom: 30px; } }
  @media (min-width: 600px) {
    .audio-detail-page {
      padding-bottom: 42px; } }
  @media (max-width: 400px) {
    .audio-detail-page {
      padding-left: 32px; } }
  @media (min-width: 775px) {
    .audio-detail-page {
      padding-left: 62px; } }
  @media (max-width: 400px) {
    .audio-detail-page {
      padding-right: 32px; } }
  @media (min-width: 775px) {
    .audio-detail-page {
      padding-right: 62px; } }
  .audio-detail-page .audio-content {
    display: flex;
    flex-direction: column;
    align-items: stretch; }
    .audio-detail-page .audio-content .sub-props {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap; }
      .audio-detail-page .audio-content .sub-props .text {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #999999;
        margin-right: 4px; }
      .audio-detail-page .audio-content .sub-props .qr-code {
        width: 18px; }
    .audio-detail-page .audio-content .header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      margin-top: 16px;
      padding-bottom: 3vw;
      border-bottom: 1px solid #f4f4f4; }
      @media (max-width: 466.66667px) {
        .audio-detail-page .audio-content .header {
          padding-bottom: 14px; } }
      @media (min-width: 800px) {
        .audio-detail-page .audio-content .header {
          padding-bottom: 24px; } }
      .audio-detail-page .audio-content .header .podcast-icon {
        width: 22px;
        height: 24px;
        margin-right: 15px;
        margin-top: 20px; }
      .audio-detail-page .audio-content .header .play-title {
        font-family: "Noto Serif SC", serif;
        font-weight: 700;
        font-size: 5vw;
        color: #3b3b3b;
        margin-right: 15px;
        flex: 1; }
        @media (max-width: 440px) {
          .audio-detail-page .audio-content .header .play-title {
            font-size: 22px; } }
        @media (min-width: 800px) {
          .audio-detail-page .audio-content .header .play-title {
            font-size: 40px; } }
      .audio-detail-page .audio-content .header .play-duration {
        font-family: "Noto Serif SC", serif;
        font-weight: 700;
        font-size: 26px;
        color: #3b3b3b;
        text-align: center; }
      .audio-detail-page .audio-content .header .play-btn {
        width: 36px;
        height: 36px;
        margin-left: 16px;
        cursor: pointer;
        color: #3945f9;
        font-size: 44px; }
    .audio-detail-page .audio-content .podcast-mini {
      display: flex;
      align-items: center;
      color: #666666;
      cursor: pointer;
      margin-top: 18px; }
      .audio-detail-page .audio-content .podcast-mini .icon {
        font-size: 18px;
        margin-right: 6px; }
      .audio-detail-page .audio-content .podcast-mini .podcast-mini-text {
        margin-top: 2px;
        color: #888888; }
    .audio-detail-page .audio-content .audio-rich-text {
      margin: 20px 0;
      margin-top: 2vw;
      padding-right: 1vw;
      text-align: justify; }
      @media (max-width: 500px) {
        .audio-detail-page .audio-content .audio-rich-text {
          margin-top: 10px; } }
      @media (min-width: 700px) {
        .audio-detail-page .audio-content .audio-rich-text {
          margin-top: 14px; } }
      @media (max-width: 400px) {
        .audio-detail-page .audio-content .audio-rich-text {
          padding-right: 4px; } }
      @media (min-width: 7000px) {
        .audio-detail-page .audio-content .audio-rich-text {
          padding-right: 70px; } }
      .audio-detail-page .audio-content .audio-rich-text p {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #313233;
        line-height: 28px;
        margin-top: 10px; }
      .audio-detail-page .audio-content .audio-rich-text li {
        list-style: decimal; }
      .audio-detail-page .audio-content .audio-rich-text h1,
      .audio-detail-page .audio-content .audio-rich-text h2 {
        margin-top: 36px; }
      .audio-detail-page .audio-content .audio-rich-text hr {
        border-top: #f4f4f4; }
  .audio-detail-page .comment-list {
    background: #ffffff; }
