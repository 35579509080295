.comment-textarea {
  width: 100%;
  padding-bottom: 15px; }
  .comment-textarea textarea {
    width: 100%;
    background: #f3f5f9;
    border-radius: 4px;
    border: none;
    padding: 12px 18px;
    font-size: 14px;
    font-size: 3vw; }
    @media (max-width: 433.33333px) {
      .comment-textarea textarea {
        font-size: 13px; } }
    @media (min-width: 466.66667px) {
      .comment-textarea textarea {
        font-size: 14px; } }
    .comment-textarea textarea::placeholder {
      color: #b6bcc8; }
  .comment-textarea .textarea-tool {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #939cae;
    margin-top: 10px;
    font-size: 12px; }
    .comment-textarea .textarea-tool .expression {
      cursor: pointer; }
      .comment-textarea .textarea-tool .expression .iconxiaolian {
        margin-right: 4px;
        font-size: 14px; }
    .comment-textarea .textarea-tool button {
      background: #3e4af9;
      border-radius: 100px;
      border: none;
      padding: 9px 36px;
      font-size: 14px;
      color: #ffffff;
      margin-left: 20px;
      cursor: pointer; }
  .comment-textarea .emoji-mart-scroll section:nth-child(2) {
    display: none; }
  .comment-textarea .emoji-mart-preview {
    display: none; }
  .comment-textarea .emoji-mart-search {
    display: none; }
  .comment-textarea .emoji-mart-bar {
    display: none; }
