.live-course .banner-box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08); }
  .live-course .banner-box .ant-carousel .carousel {
    background: none; }
    .live-course .banner-box .ant-carousel .carousel a {
      display: block !important; }
      .live-course .banner-box .ant-carousel .carousel a .background-box {
        position: relative; }
        .live-course .banner-box .ant-carousel .carousel a .background-box .banner-inner-panel {
          width: 100%;
          height: 0;
          padding-bottom: 31.24%; }
          .live-course .banner-box .ant-carousel .carousel a .background-box .banner-inner-panel img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer; }
    .live-course .banner-box .ant-carousel .carousel .slick-dots button:before {
      display: none; }
  .live-course .banner-box .banner-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    position: relative;
    padding: 10px 0; }
    .live-course .banner-box .banner-bottom .carousel-indicator {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      margin-right: 20px; }
      .live-course .banner-box .banner-bottom .carousel-indicator .dotted {
        width: 6px;
        height: 6px;
        background: #3945F9;
        opacity: 0.2;
        margin: 0 4px;
        cursor: pointer; }
      .live-course .banner-box .banner-bottom .carousel-indicator .select {
        opacity: 1; }

.live-course .list-header {
  margin-top: 30px;
  margin-top: 5vw;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%; }
  @media (max-width: 400px) {
    .live-course .list-header {
      margin-top: 20px; } }
  @media (min-width: 1000px) {
    .live-course .list-header {
      margin-top: 50px; } }
  .live-course .list-header .category-search {
    width: 100%; }
    .live-course .list-header .category-search .ant-menu {
      background: none;
      border: none;
      font-size: 14px;
      line-height: 26px; }
      .live-course .list-header .category-search .ant-menu .ant-menu-item {
        padding: 0;
        margin-right: 20px;
        border-bottom: none; }
      .live-course .list-header .category-search .ant-menu .ant-menu-submenu {
        margin: 0 20px;
        border-bottom: none; }
        .live-course .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
          padding: 0; }
          .live-course .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper {
            display: flex;
            align-items: center; }
            .live-course .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper .selector-arrow {
              margin-left: 10px;
              margin-right: 0;
              font-size: 8px;
              color: rgba(0, 0, 0, 0.2);
              transition: 0.5s all; }
      .live-course .list-header .category-search .ant-menu .ant-menu-submenu-active .selector-arrow {
        color: #3945F9 !important; }
    .live-course .list-header .category-search .search-bar {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      height: 30px;
      height: 8vw;
      border-radius: 20px;
      background: white; }
      @media (max-width: 375px) {
        .live-course .list-header .category-search .search-bar {
          height: 30px; } }
      @media (min-width: 500px) {
        .live-course .list-header .category-search .search-bar {
          height: 40px; } }
      .live-course .list-header .category-search .search-bar input {
        height: 22px;
        font-size: 14px;
        font-size: 3vw;
        flex: 1;
        background: none;
        margin-left: 20px;
        outline: none;
        border: none; }
        @media (max-width: 466.66667px) {
          .live-course .list-header .category-search .search-bar input {
            font-size: 14px; } }
        @media (min-width: 533.33333px) {
          .live-course .list-header .category-search .search-bar input {
            font-size: 16px; } }
        .live-course .list-header .category-search .search-bar input::-webkit-input-placeholder {
          font-size: 14px;
          font-size: 3vw;
          color: #939cae;
          letter-spacing: 0; }
          @media (max-width: 466.66667px) {
            .live-course .list-header .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 14px; } }
          @media (min-width: 533.33333px) {
            .live-course .list-header .category-search .search-bar input::-webkit-input-placeholder {
              font-size: 16px; } }
        .live-course .list-header .category-search .search-bar input:focus {
          outline: none;
          border: none; }
      .live-course .list-header .category-search .search-bar .search-icon {
        font-size: 20px;
        font-size: 14px;
        font-size: 4vw;
        color: #959eb0;
        margin: 0 20px;
        cursor: pointer; }
        @media (max-width: 400px) {
          .live-course .list-header .category-search .search-bar .search-icon {
            font-size: 16px; } }
        @media (min-width: 500px) {
          .live-course .list-header .category-search .search-bar .search-icon {
            font-size: 20px; } }
        .live-course .list-header .category-search .search-bar .search-icon:hover {
          color: #3945F9; }
      .live-course .list-header .category-search .search-bar .searching {
        color: #3945F9; }

.live-course .list-body {
  width: 100%;
  margin-top: 24px; }
