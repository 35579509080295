.promise-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%;
  position: relative; }
  .promise-page .pro {
    display: flex;
    flex-direction: column;
    margin: 0 15px; }
    .promise-page .pro .title {
      text-align: center;
      margin: 26px;
      font-size: 30px;
      font-weight: bold;
      color: #333333; }
  .promise-page .form {
    margin: 0 15px; }
    .promise-page .form .form-item {
      margin-top: 15px; }
      .promise-page .form .form-item .radio-group {
        display: flex;
        flex-direction: column; }
      .promise-page .form .form-item .radio {
        margin: 10px 0; }
      .promise-page .form .form-item .checkbox-group {
        display: flex;
        flex-direction: column; }
      .promise-page .form .form-item .checkbox {
        margin: 10px 0; }
      .promise-page .form .form-item .form-title {
        font-size: 16px;
        font-weight: 700;
        color: #000;
        margin-top: 10px; }
      .promise-page .form .form-item .input {
        margin-top: 10px; }
      .promise-page .form .form-item .image-box {
        border-radius: 3px;
        border: 1px solid #d9d9d9;
        height: 300px;
        margin-top: 10px;
        overflow: hidden; }
        .promise-page .form .form-item .image-box img {
          width: 100%; }
    .promise-page .form .submit-button {
      margin: 30px 0;
      width: 100%; }
  .promise-page .modal-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .promise-page .modal-center .form-title {
      font-size: 16px;
      font-weight: 700;
      color: #000;
      margin-top: 10px; }
  .promise-page .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: white; }
    .promise-page .modal .canvas {
      background: white; }
    .promise-page .modal .bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; }
      .promise-page .modal .bottom .bottom-bar {
        display: flex;
        flex-direction: row;
        height: 50px;
        margin-top: 15px; }
        .promise-page .modal .bottom .bottom-bar .item {
          width: 50%;
          height: 50px;
          color: white;
          background: #d9d9d9;
          font-size: 16px;
          text-align: center;
          line-height: 50px; }
        .promise-page .modal .bottom .bottom-bar .primary {
          background: #3945F9; }
