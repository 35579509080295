.shots .list-header {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%; }
  .shots .list-header .category-search {
    width: 100%; }
    .shots .list-header .category-search .ant-select-selection {
      background: #f7f9fc; }
    .shots .list-header .category-search .shots-category {
      display: flex;
      justify-content: center;
      align-items: center; }
      .shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
        margin: 0 10px;
        margin-left: 10px;
        margin-left: 1vw;
        margin-right: 10px;
        margin-right: 1vw;
        background: transparent;
        border-radius: 4px;
        border: none;
        outline: none;
        color: #898989; }
        @media (max-width: 400px) {
          .shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-left: 4px; } }
        @media (min-width: 1000px) {
          .shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-left: 10px; } }
        @media (max-width: 400px) {
          .shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-right: 4px; } }
        @media (min-width: 1000px) {
          .shots .list-header .category-search .shots-category .ant-radio-button-wrapper {
            margin-right: 10px; } }
        .shots .list-header .category-search .shots-category .ant-radio-button-wrapper:not(:first-child)::before {
          display: none; }
      .shots .list-header .category-search .shots-category .ant-radio-button-wrapper-checked {
        background: #dcdfff;
        color: #3945f9;
        box-shadow: none; }
    .shots .list-header .category-search .ant-menu {
      background: none;
      border: none;
      font-size: 14px;
      line-height: 26px; }
      .shots .list-header .category-search .ant-menu .ant-menu-item {
        padding: 0;
        margin-right: 20px;
        border-bottom: none; }
      .shots .list-header .category-search .ant-menu .ant-menu-submenu {
        margin: 0 20px;
        border-bottom: none; }
        .shots .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
          padding: 0; }
          .shots .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper {
            display: flex;
            align-items: center; }
            .shots .list-header .category-search .ant-menu .ant-menu-submenu .ant-menu-submenu-title .submenu-title-wrapper .selector-arrow {
              margin-left: 10px;
              margin-right: 0;
              font-size: 8px;
              color: rgba(0, 0, 0, 0.2);
              transition: 0.5s all; }
      .shots .list-header .category-search .ant-menu .ant-menu-submenu-active .selector-arrow {
        color: #3945F9 !important; }

.shots .list-body {
  width: 100%;
  margin-top: 24px; }
