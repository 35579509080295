.account {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%; }
  .account .account-panel {
    margin-top: 80px;
    max-width: 1000px;
    width: 100%;
    background: #ffffff;
    position: relative; }
    .account .account-panel .account-top {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      position: absolute;
      top: -25px;
      z-index: 10;
      width: 100%;
      padding-left: 20px;
      padding-left: 3vw;
      padding-right: 20px;
      padding-right: 3vw; }
      @media (max-width: 400px) {
        .account .account-panel .account-top {
          padding-left: 12px; } }
      @media (min-width: 1133.33333px) {
        .account .account-panel .account-top {
          padding-left: 34px; } }
      @media (max-width: 400px) {
        .account .account-panel .account-top {
          padding-right: 12px; } }
      @media (min-width: 1133.33333px) {
        .account .account-panel .account-top {
          padding-right: 34px; } }
      .account .account-panel .account-top .account-info {
        display: flex;
        align-items: flex-end; }
        .account .account-panel .account-top .account-info .avatar {
          width: 100px;
          height: 100px;
          background: #ffffff;
          border: 5px solid #ffffff;
          box-shadow: 0 14px 20px 0 rgba(234, 63, 255, 0), 0 15px 40px 0 rgba(79, 125, 183, 0.08);
          position: relative;
          cursor: pointer; }
          .account .account-panel .account-top .account-info .avatar .hover-box {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: absolute; }
          .account .account-panel .account-top .account-info .avatar img {
            height: 100%;
            width: 100%;
            object-fit: cover; }
        .account .account-panel .account-top .account-info .name-panel {
          margin-left: 14px;
          color: #232323; }
          .account .account-panel .account-top .account-info .name-panel .nickname {
            font-size: 20px;
            display: flex;
            flex-direction: row;
            align-items: center; }
            .account .account-panel .account-top .account-info .name-panel .nickname .edit-icon {
              font-size: 14px;
              color: #90939c;
              margin-left: 15px;
              cursor: pointer; }
          .account .account-panel .account-top .account-info .name-panel .label {
            font-size: 12px;
            margin-top: 4px;
            margin-bottom: 10px; }
      .account .account-panel .account-top .account-safe {
        display: flex;
        align-items: center;
        background: #f3f4ff;
        padding: 9px 18px;
        border-radius: 50px;
        margin-bottom: 16px; }
        .account .account-panel .account-top .account-safe .account-txt {
          color: #3e4af9;
          font-size: 14px;
          margin-left: 6px; }
    .account .account-panel .account-content .tab-list-pagination {
      margin: 20px;
      align-self: end; }
    .account .account-panel .account-content .tab-pane {
      min-height: 300px;
      position: relative; }
      .account .account-panel .account-content .tab-pane .message-list {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%; }
    .account .account-panel .account-content .list {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: 20px 0; }
    .account .account-panel .account-content .ant-tabs-bar {
      margin-bottom: 28px;
      height: 145px;
      background: url(/bg/email-pattern.png);
      display: flex;
      align-items: flex-end; }
      .account .account-panel .account-content .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-nav-scroll {
        padding: 0 34px;
        font-size: 16px;
        color: #232323; }
    .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane {
      padding-left: 20px;
      padding-left: 3vw;
      padding-right: 20px;
      padding-right: 3vw; }
      @media (max-width: 400px) {
        .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane {
          padding-left: 12px; } }
      @media (min-width: 1133.33333px) {
        .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane {
          padding-left: 34px; } }
      @media (max-width: 400px) {
        .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane {
          padding-right: 12px; } }
      @media (min-width: 1133.33333px) {
        .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane {
          padding-right: 34px; } }
      .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper {
        padding: 0;
        margin-left: 10px;
        margin-left: 1vw;
        margin-right: 10px;
        margin-right: 1vw;
        background: transparent;
        border-radius: 4px;
        border: none;
        outline: none;
        color: #898989; }
        @media (max-width: 400px) {
          .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper {
            margin-left: 4px; } }
        @media (min-width: 1000px) {
          .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper {
            margin-left: 10px; } }
        @media (max-width: 400px) {
          .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper {
            margin-right: 4px; } }
        @media (min-width: 1000px) {
          .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper {
            margin-right: 10px; } }
        .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper:not(:first-child)::before {
          display: none; }
      .account .account-panel .account-content .ant-tabs-content .ant-tabs-tabpane .ant-radio-button-wrapper-checked {
        color: #3945f9;
        box-shadow: none; }

.edit-nickname-modal .edit-nickname {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  font-size: 20px; }
  .edit-nickname-modal .edit-nickname .input-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }
    .edit-nickname-modal .edit-nickname .input-box input {
      height: 40px;
      flex: 1; }
  .edit-nickname-modal .edit-nickname .input-status {
    font-size: 15px; }
  .edit-nickname-modal .edit-nickname .valid-tip {
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px; }
    .edit-nickname-modal .edit-nickname .valid-tip .tip-text {
      margin-left: 10px; }
  .edit-nickname-modal .edit-nickname .footer-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px; }
    .edit-nickname-modal .edit-nickname .footer-btns .edit-btn {
      margin-left: 15px; }
