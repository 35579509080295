.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F3F5F9;
  width: 100%;
  position: relative; }
  .layout .ant-alert {
    width: 100%; }
  .layout .loading {
    height: 200px; }
  .layout .nav {
    overflow: hidden; }
  .layout .high {
    overflow: initial; }
  .layout .layout-content {
    max-width: 1360px;
    margin: 0 10px;
    margin-top: 20px;
    z-index: 2;
    width: calc(100% - 20px); }
